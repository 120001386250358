/* -------------------------------------------------
 * -------------------------------------------------
 * CSS triangle
 *
 * @param  {String} $triangle-size      - Border size
 * @param  {String} $triangle-color     - Border color
 * @param  {String} $triangle-direction - Arrow direction
 *
 * Expected Usage:
 * 	a:after {
 *		@include css-triangle(10, $yellow, top)
 * 	}
 * Note:
 * This should always be applied to the <a> tag if used in nav and not the <li>
 *
 * --------------------------------------------------
 * @author Fisheye
 */

@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border: inset $triangle-size;
	position: absolute; // positions arrow top, horizontal centre
	@if ($triangle-direction==top) {
		border-color: $triangle-color transparent transparent;
		border-top-style: solid;
		top: 0;
		left: calc(50% - #{$triangle-size});
	} // positions arrow bottom, horizontal centre
	@if ($triangle-direction==bottom) {
		border-bottom-style: solid;
		border-color: transparent transparent $triangle-color;
		bottom: 0;
		left: calc(50% - #{$triangle-size});
	} // positions arrow left, vertical centre
	@if ($triangle-direction==left) {
		border-color: transparent transparent transparent $triangle-color;
		border-left-style: solid;
		left: 0;
		top: calc(50% - #{$triangle-size});
	} // positions arrow right, vertical centre
	@if ($triangle-direction==right) {
		border-color: transparent $triangle-color transparent transparent;
		border-right-style: solid;
		right: 0;
		top: calc(50% - #{$triangle-size});
	}
}