// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
//  All code in this file should contain fixes for IE11 ONLY! Any other fixes for specific browsers
//  should go in their respecitve file. Fixes for browsers all browsers should go in _defend.scss
//  @author Fisheye
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

.ie11 {
	.page-layout-checkout .header.content .checkout-header-security svg {
		width: em(16); // Ensures the checkout header secure text doesn't spill onto two lines
	}

	form .fieldset .field {
		display: block; // Stops the inputs and labels from collapsing into one another

		.control {
			margin-top: $form-label-spacing; // Maintains space between inputs and labels 
		}
	}

	.opc-wrapper .checkout-shipping-method .table-checkout-shipping-method tbody td {
		&:nth-child(3) {
			padding-left: 50%; // Ensures there is a space between delivery price & method on checkout
		}
	}

	//Login/Create an Account
	.login-container {
		.block {
			@include min-screen($screen__ml) { 
				max-width: calc(50% - #{$account-login-create-spacing / 2});
				// even though the basis is set correctly it wraps on ie as it gets confused by the padding/margin
				// setting the max-width to the actual with of the block (calculated by flex: basis) fixes this 
			}
		}
	}

	//Account Dashboard top-links
	@include min-screen($screen__m) { 
		.account-top-links {

			.block {
				flex-basis: calc(25% - #{$twenty-gutter * 2});
				//same basis issue as above, gutter is set to * 2 on calc to compensate for left and right padding
				padding: $twenty-gutter;
			}
		}
	}

	.account.wishlist-index-index {
		.actions-toolbar {
			.primary {
				.action {
					max-width: calc(#{percentage(1/3)} - #{$twenty-gutter});
					//same basis issue as above
				}
			}
		}
	}

	.login-container {
		.block-content {
			.form-login {
				.field {
					display: block;
					flex-wrap: nowrap;
					// stop fields wrapping on ie
				}
			}
		}
	}

	// added to basket
	// ----------------------
	aside.modal-popup {
		&.block-basket-preview {
			.modal-inner-wrap {
				.block-cross-sell-products {
					width: 100%;
					flex: auto;
				}
			}

			[class].modal-inner-wrap {
				.modal-content {
					overflow-y: auto;
				}
			}
		}
	}

	.sections.nav-sections {
		left: 0;
		transform: none;
	}

	.page-multiple-wishlist {
		.products-grid {
			.product-item {
				padding: 0;

				.product-item-inner {
					padding: 0 $item-gutter;
				}
			}
		}
	}

	.account {
		&.customer-address-index {
			.block-addresses-default,
			.block-addresses-list {
				display: inline-block;
				width: 45%;

				.box-title {
					margin-top: $twenty-gutter;
				}

				.block-content {
					flex-wrap: nowrap;
				}
			}

			.block-addresses-list {
				.block-content {
					display: block;
				}
			}
		}
	}

	.product-info-stock-sku {
		.lead-time-message {
			&:before {
				background-size: calc(#{$product-info-lead-time-svg-size} - 1px);
				// really dirty hack, cropped on IE and the only way I can make it sit right is taking a px off the size
				background-position: center;
			}
		}
	}

	body.company-account-index .page-main {
		.columns {
			.column.main {
				.block-company-profile-account,
				.block-company-profile-address,
				.block-company-profile-contacts {
					@include min-screen($screen__m) {
						flex-basis: auto;
						width: calc(50% - #{$fifteen-gutter});
					}
	
					@include min-screen($screen__l) {
						flex-basis: auto;
						width: calc(33.333333% - #{$fifteen-gutter});
					}
				}

				.block-company-profile-contacts {
					@include min-screen($screen__m) {
						width: 100%;
					}
	
					@include min-screen($screen__l) {
						width: calc(33.333333% - #{$fifteen-gutter});
					}
				}
			}
		}
	}

	/* Global Header */
	.customer-welcome,
	.customer-help-center,
	.customer-trade-links {
		&.active {
			.account-open,
			.help-center-open,
			.header-link-open {
				// To position the white arrow on top of white box underneath (Trade & Account)
				@include min-screen($screen__m) {
					margin-top: 15px;
				}

				@include min-screen($screen__xxl) {
					margin: 22px 0; 
				}
			}

			.currency-name,
			.minicart-title,
			.customer-title,
			.quickorder-title,
			.header-link-title {
				@include min-screen($screen__m) {
					margin-bottom: 15px;	
				}

				@include min-screen($screen__xxl) {
					margin-bottom: 0;
				}
			}
		}
	}

	.header.content {
		.icons-block {
			.customer-menu,
			.header-link-menu {
				@include min-screen($screen__m) {
					top: 69px;
				}

				@include min-screen($screen__xxl) {
					top: 64px;
				}
			}
		}
	}

	// ----------------------
	// Blog
	// ----------------------
	.blog-category-index,
	.blog-search-result {
		.columns .blog__post-list {
			.blog__post-container {
				display: block;
			}

			.blog__post-item {
				@include min-screen($screen__m) {
					flex: none;
					width: percentage(1/2);
				}
	
				@include min-screen($screen__l) {
					flex: none;
					width: percentage(1/3);
				}
	
				@include min-screen($screen__xl) {
					flex: none;
					width: percentage(1/4);
				}

				.blog__post-title {
					min-height: rem(80);
				}
			}
		}
	}

	.blog-category-index,
	.blog-post-view {
		.main {
			width: 100%;
		}
	}

	.navigation-main-ul .js-search-results, .mobile-menu .js-search-results {
		@include min-screen($screen__m) {
			left: calc(50% - #{rem(235)})
		}
	}

	.navigation {
		.pagebuilder-column {
			flex-direction: row!important;// override inline styles
			flex-wrap: wrap;

			.display-children {
				max-width: 50px;
				flex: 0 1 50px;
				margin-right: 0;
				padding-right: $twenty-gutter;

				@include min-screen($screen__l) {
					margin-right: 0;
					padding-right: $twenty-gutter;
				}
			}

			div[data-element="main"] {
				flex: 0 1 100%;
				max-width: calc(100% - 90px);
			}
		}
	}

	// ----------------------
	// Category
	// ----------------------
	.catalog-category-view .columns {
		display: flex;
		flex-direction: column;

		.subcategories-cms-column {
			order: 0;
		}

		.sidebar.sidebar-main {
			order: 1;
		}

		.column.main {
			order: 2;
		}
	}

	.sidebar-main .block.filter .filter-content .filter-options .filter-options-item {
		@include min-screen($screen__m) {
			flex: auto;
			width: calc(33.333333% + #{rem(14)});
		}

		@include min-screen($screen__l) {
			flex: auto;
			width: calc(20% + #{rem(14)});
		}
	}

	.category-list-wrapper .category-list-item {
		@include min-screen($screen__m) {
			flex: none;
			width: percentage(1/3)
		}

		@include min-screen($screen__l) {
			flex: none;
			width: percentage(1/4);
		}

		@include min-screen($screen__ll) {
			flex: none;
			width: percentage(1/5);
		}

		@include min-screen($screen__xl) {
			flex: none;
			width: percentage(1/6);
		}

		@include min-screen($screen__xxl) {
			flex: none;
			width: percentage(1/8);
		}
	}

	.product-listing {
		@include min-screen($screen__m) {
			flex: none;
			width: percentage(1/3)
		}
	
		@include min-screen($screen__ml) {
			flex: none;
			width: percentage(1/5)
		}
		
		@include min-screen($screen__ll) {
			flex: none;
			width: percentage(1/6)
		}
	}

	// ----------------------
	// Checkout
	// ----------------------
	.checkout-index-index,
	.page-layout-checkout {
		.header.content {
			height: 69px;

			.logo {
				display: inline-block;

				a {
					margin: auto;
				}
			}
		}

		.checkout-container {
			.opc-wrapper {
				.payment-group {
					.field.choice {
						.label:before {
							background-size: 28px 28px;
						}
					}
				}

				.methods-shipping {
					.row {
						.col-method {
							label:before {
								background-size: 27px 27px;
							}
						}
					}
				}

				.discount-code,
				.userewardpoints {
					.actions-toolbar {
						button:after {
							margin-top: 9px;
						}
					}
				}

				.payment-method[id="payment-method-braintree-paypal-vault"] {
					.choice.field {
						.label {
							display: inline-block;
						}
					}
				}

				.checkout-shipping-address {
					.action-show-popup {
						border: 0;
					}

					.shipping-address-item {
						width: calc(50% - 16px);
						margin-right: $gutter;
						flex: 0 1 auto;

						&:nth-child(even) {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	// ----------------------
	// Static / Account Layout
	// ----------------------
	.contact-index-index,
	.cms-page-view,
	.cms-no-route,
	body[class*="customer"],
	body[class*="company"],
	.quickorder-index-index,
	.account,
	.sales-guest-form,
	.sales-guest-view,
	.sales-guest-invoice {
		.columns {
			@include min-screen($screen__m) {
				display: flex;
			}
		}

		.main {
			order: 2;
			flex: 1;
		}

		.sidebar-main {
			flex: 1;
			max-width: $sidebar-max-width;
		}
	}

	// ----------------------
	// PayPal Review Page
	// ----------------------
	.braintree-paypal-review {
		.paypal-review-items,
		.block-order-details-view {
			flex: auto;
			width: 50%;
		}
	}

	// ----------------------
	// Product Pages
	// ----------------------
	.catalog-product-view,
	.bundle-options-container {
		.product-main-container {
			display: flex;

			.product-info-main,
			.product.media {
				flex: 1;
			}

			.product-info-price {
				.prices-tier.items {
					flex: 1 1 90%;
				}
			}

			.product-info-addto,
			.bundle-info {
				.box-tocart {
					.fieldset {
						display: block;

						&.fieldset-bundle-options {
							display: none;
						}

						.field.qty {
							display: flex;

							.label {
								height: auto;
							}
						}

						.control {
							margin-top: 0;
							
							.input-text {
								margin-left: -1px; // fill in 1px white gap
							}
						}
					}
				}

				.product-options-wrapper {
					.engraving {
						display: flex;
					}

					.custom-engraving {
						height: em(73);
					}
				}
			}
		}

		.strapline-container {
			display: inline-block;
			width: 100%;
		}

		.block-viewed-products {
			li.product-item {
				.product-item-info {
					flex: 0 1 auto;
					width: auto;
					margin-right: $gutter;
				}
			}
		}
	}
}
