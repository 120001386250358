/* -------------------------------------------------
 * -------------------------------------------------
 * PDF Download variables
 *
 * -------------------------------------------------
 * @author Fisheye
 */

$download-container-colour: $keppel-light;
$download-container-border: 1px solid $keppel;
$download-container-border-radius: 2px;
$download-container-spacing: $small-gutter;
$download-container-link-color: $keppel;
$download-container-icon-colour: $keppel;
$download-container-text-color: $mine-shaft;

$download-attachment-table-border: 1px solid $gallery;
$download-attachment-table-border-radius: 0;
$download-attachment-table-color: $wild-sand;
$download-attachment-icon-color: $keppel;
$download-attachment-cell-spacing: $five-gutter 0 $five-gutter $small-gutter;
$download-attachment-container-spacing: 0 0 $gutter 0;
$download-attachment-type-size: rem(18);
$download-attachment-icon-size: rem(18);
