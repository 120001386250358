//default form
form,
.form {
	fieldset,
	.fieldset {
		.field {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			@media (min-width: $screen__ml) {
				flex-direction: $account-form-flex-direction;
			}

			.label {
				flex: 0 1 100%;
				display: flex;

				&:after {
					display: flex;
					align-self: center;
					padding-left: em(5);
				}

				@include min-screen($screen__ml) {
					flex: 1 0 $account-form-label-width-tablet;
				}

				@include min-screen($screen__ll) {
					flex: 0 1 $account-form-label-width-desktop;
				}

				span {
					align-self: center;
					@include type__14;
				}
			}

			.control {
				flex: 1 0 auto; //grow to fill available space

				@include min-screen($screen__ml) {
					flex: 0 1 $account-form-control-width-desktop; // needs to have an explicit width rather than auto on desktop as otherwise it will default
					margin-right: 0;
				}

				.input-text {
					margin-top: 0;
				}
			}

			&.newsletter {
				margin-top: $twenty-gutter;
				margin-bottom: 0;

				@include min-screen($screen__s) {
					margin-top: $gutter;
				}

				label {
					margin-bottom: 0;

					@include min-screen($screen__s) {
						margin-bottom: $ten-gutter;
					}

					span {
						@include type__13;
					}
				}
			}

			&.newsletter {
				margin-top: $twenty-gutter;
				margin-bottom: 0;

				@include min-screen($screen__s) {
					margin-top: $gutter;
				}

				label {
					margin-bottom: 0;

					@include min-screen($screen__s) {
						margin-bottom: $ten-gutter;
					}

					span {
						@include type__13;
					}
				}
			}
		}
	}
}

// account details
.form-address-edit,
.customer-account-edit {
	.fieldset,
	.actions-toolbar {
		max-width: $account-form-max-width;
		margin-bottom: $account-form-spacing;

		br {
			display: none; // random </br> coming into markup causing unnecessary additional spacing
		}

		.primary {
			display: block;
			width: 100%;
		}

		.secondary {
			margin-top: $account-form-spacing;
		}

		.field.choice .label {
			flex: 1 1 100%;
		}

		/* when we have nested fields if any of the parents are required every child label will have the required asterisk
		   The below code removed this if the field does not have the reqired label */
		.field:not(.required) {
			label {
				&:after {
					display: none;
				}
			}
		}

		.field {
			.search-bar {
				display: flex;
				flex-wrap: wrap;

				.action {
					@include button__default($padding: $ten-gutter);
					order: 1;
					flex: 0 1 38%;
					margin-left: $ten-gutter;
					border-radius: 0;

					@include min-screen($screen__m) {
						flex: 0 1 32%;
						margin-left: $twenty-gutter;
					}
				}

				.message {
					order: 3;
				}

				input {
					flex: 1;
					width: 75% !important; /* override an important from module styles */
				}
			}
		}
	}
}

//add new address
.form-address-edit {
	fieldset,
	.fieldset {
		.field {
			.label {
				flex: 0 1 100%;
			}

			.control {
				flex: 0 1 100%;

				.nested {
					margin-top: $gutter;
				}
			}

			&.choice.set.shipping {
				margin-bottom: $ten-gutter;
			}

			&.set { // all checkbox labels have this set class
				label {
					margin-bottom: 0;

					span {
						@include type__13;
					}
				}
			}

			div[id=cp_result_display] {
				margin-top: $fifteen-gutter;
				max-width: none;
			}

			&.zip.search-container {
				@include min-screen($screen__ml) {
					flex-direction: row;
				}
			}
		}
	}
}

.form-newsletter-manage {
	.field.choice .label {
		flex: 1 1 100%;
	}
}

// forgotten password
.customer-account-forgotpassword,
.customer-account-createpassword {
	.columns {
		.main {
			.password.form {
				background-color: $account-forgotten-password-background-color;

				.fieldset,
				.actions-toolbar {
					background-color: $account-forgotten-password-background-color;
					padding-right: 0;
					padding-left: 0;

					max-width: $account-form-max-width;
					margin-left: auto;
					margin-right: auto;

					.primary {
						margin-top: 0;
						width: 100%;
						max-width: none;
					}

					.secondary {
						margin-bottom: 0;
						margin-left: auto;
						margin-right: auto;
						display: table;
						@extend %cancel-underline;

						a {
							@include type__13;
							@include link__underline
						}
					}
				}

				.label {
					@include min-screen($screen__s) {
						flex: 0 1 20%;
					}
				}

				.fieldset {
					padding-bottom: 0;
					// seamlessly stack the fieldset and account toolbar, not used variable's here as would assume wouldn't change

					.field.note {
						@include type__14($font-weight: $font-weight-normal);
					}
				}

				.actions-toolbar {
					padding-top: 0;
					// seamlessly stack the fieldset and account toolbar, not used variable's here as would assume wouldn't change
				}
			}
		}
	}
}

.form-newsletter-manage {
	.fieldset {
		.field {
			margin-bottom: 0;

			&.choice {
				label {
					span {
						@include type__13;
					}
				}
			}
		}
	}
}

.form-edit-account {
	.fieldset {
		.field.choice {
			label {
				span {
					@include type__13;
				}
			}
		}

		.field {
			&:last-child {
				margin-bottom: 0;
				background-color: #fff;
			}
		}

		&.password {
			margin-bottom: $gutter;
		}
	}
}
