/* -------------------------------------------------
 * -------------------------------------------------
 * Pagination
 *
 * Appears in toolbars in category and account pages
 *
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.toolbar {
// Pagination
	.pager {
		display: flex;
	}

	.pages {
		order: $toolbar-pagination-flex-order;
		display: flex;
		justify-content: flex-start;
		margin: 0;

		@include min-screen($screen__m) {
			margin: 0 0 0 $gutter; // mobile top spacing only required when toolbar items wrap
			justify-content: flex-end;
			flex: 0 1 auto;
		}

		.pages-items {
			display: flex;

			.item.current {
				.page {
					@include button__pagination(
						$background-color: $button-pagination-current-background
					);

					span {
						color: $button-pagination-current-color;
					}
				}
			}

			.item {
				margin: 0;

				.page {
					@include button__pagination($color: $white);
					font-weight: $font-weight-bold;

					span {
						color: $button-pagination-color;
					}
				}

				.action {
					@include button__pagination;

					> span {
						@extend %visuallyhidden; // hide 'Next' and 'Previous' text to replace with arrows
					}
				}
			}
		}

		.label {
			@extend %visuallyhidden; // none of the labels within the pagination need to be shown
		}
	}
}

/*
 * category page specific
 */
.page-products {
	.toolbar {
		.pages { // in most design pagination isnt used
			display: $toolbar-pagination-display-setting;
			margin-top: $toolbar-pagination-spacing;

			@include min-screen($screen__m) {
				margin-top: 0;
				margin-left: auto; //aligns pagination to the right
			}
		}
	}
}
