/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to Category page
 *
 * Most styles should be in relevant stylesheet i.e. product-listing.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// title container for top, bottom and search results
// ------------------------------------------------

.catalog-category-view,
.catalogsearch-result-index {
	.columns,
	.main,
	.notice {
		padding: 0; // Not required here on Category Pages, only on .column.main
	}

	.page-title-wrapper {
		flex-wrap: nowrap;
	}

	.sidebar-main {
		background-color: $layered-nav-container-background;
	}

	.flex__row {
		justify-content: flex-start;
	}

	.cms-brands {
		padding-top: rem(42);
		padding-bottom: rem(42);

		@include min-screen($screen__m) {
			padding-top: rem(74);
			padding-bottom: rem(73);
		}

		.pagebuilder-buttons {
			display: flex;
		}

		li {
			margin-right: rem(35);

			.pagebuilder-image-wrapper {
				width: rem(80);

				@include min-screen($screen__m) {
					width: rem(130);
				}
			}
		}

		span {
			white-space: normal;
			max-width: rem(150);
			margin-top: rem(12);
		}

		.scroll-navigation-wrapper .previous-arrow {
			background-position: left;
		}

		.scroll-navigation-wrapper .next-arrow {
			background-position: right;
		}
	}

	.category-content-wrapper,
	.category-view {
		.category-image {
			display: none;
		}
	}

	.product-listing-container {
		padding: 0 $page-padding-mobile;
		margin: 0;

		@include min-screen($screen__m) {
			padding: 0 $page-padding;
		}
	}

	.page-title-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.page-title {
			flex: 1 1 100%;
		}

		.js-search-wrapper {
			background: none;
			flex: 1 1 100%;
			padding-top: 0;
			padding-bottom: 0;
			margin-top: rem(18);
			margin-bottom: rem(4);

			@include min-screen($screen__m) {
				margin-top: rem(8);
				margin-bottom: $fifteen-gutter;
			}

			.js-search-container {
				width: auto;
			}
		}

		.category-content-info {
			display: none;
		}
	}
	// category description container
	.category-content-info {
		.category-subtitle {
			padding-top: $category-subtitle-spacing-mobile;

			@include min-screen($screen__m) {
				padding-top: $category-subtitle-spacing;
			}
		}

		.category-short-description {
			padding-top: $category-description-spacing-mobile;

			@include min-screen($screen__m) {
				padding-top: $category-description-spacing;
			}
		}
	}

	.toolbar-amount {
		display: none;
	}

	.toolbar-top {
		.pages {
			display: none;

			@include min-screen($screen__ml) {
				display: flex;
			}
		}

		.limiter {
			display: none;
		}
	}

	.toolbar-bottom {
		.next-day-delivery,
		.field.limiter {
			display: none;
		}

		.toolbar-sorter,
		.limiter {
			display: none;

			@include min-screen($screen__m) {
				display: flex;
			}

			.limiter-options {
				text-align: left;
			}
		}

		.toolbar-products {
			border-top: 1px solid $gallery;
			border-bottom: 0;

			.pages {
				margin: auto;

				@include min-screen($screen__m) {
					margin-right: 0;
				}
			}
		}
	}

	.category-cms-bottom,
	.category-cms-additional {
		padding: $ten-gutter;
		background-color: $white;
		overflow: hidden; // CLS improvement during slider init

		@include min-screen($screen__m) {
			padding: $ten-gutter $gutter;
		}

		.scroll-navigation-wrapper {
			position: relative;
			background-color: $white;

			.previous-arrow,
			.next-arrow {
				width: rem(100);
				height: 100%;
				background-color: $alabaster;
				position: absolute;
				z-index: 1;
				top: 0;
				cursor: pointer;

				&:before {
					content: '';
					position: absolute;
					background-size: 17px 17px;
					width: rem(30);
					height: rem(30);
					background-color: $charcoal-grey;
					border-radius: 100px;
					padding: 25px;
					top: calc(50% - #{rem(30)});
				}

				&.disabled {
					display: none; /* Hide once an arrow becomes 'disabled' */
				}
			}

			.previous-arrow {
				@include horizontal-gradient(
					rgba(255, 255, 255, 0),
					rgba(247, 247, 247, 1),
					$direction: left
				);
				left: 0;

				&:before {
					@include svg-background('arrow-left-white');
					left: $fifteen-gutter;

					@include min-screen($screen__m) {
						left: $gutter;
					}
				}
			}

			.next-arrow {
				@include horizontal-gradient(
					rgba(255, 255, 255, 0),
					rgba(247, 247, 247, 1)
				);
				right: 0;

				&:before {
					@include svg-background('arrow-right-white');
					right: $fifteen-gutter;

					@include min-screen($screen__m) {
						right: $gutter;
					}
				}
			}

			.pagebuilder-buttons {
				display: flex;
				flex-wrap: nowrap;
				margin: auto;

				// Prevents elements resizing, causes issues with slider calculations
				[data-content-type="button-item"] {
					flex: 0 0 auto;

					.pagebuilder-button-primary {
						display: inline-block;
						padding: 0 $ten-gutter;
					}
				}

				.pagebuilder-image-wrapper {
					line-height: 2;
					margin-bottom: $ten-gutter;

					.pagebuilder-image > img {
						width: rem(225);
						height: rem(225);
						border: solid 1px $mercury;
						border-radius: 2px;
					}
				}
			}
		}
	}

	.swatch-option-tooltip {
		z-index: 1;
		display: none; /* Hide tooltip on category page */
	}
}

// ----------------------------------------------------------------------------
// Top Level Category
// Contains Category Grid, Promotional Banners and Popular Products
// ----------------------------------------------------------------------------
.category-list-wrapper {
	display: flex;
	flex-wrap: wrap;
	background-color: $gallery;
	padding: $page-padding $page-padding-mobile;

	@include min-screen($screen__m) {
		padding: $page-padding;
	}

	.category-list-item {
		flex: 1 1 100%;
		background-color: $white;
		border-bottom: 1px solid $gallery;

		@include min-screen($screen__m) {
			flex: 0 1 percentage(1/3);
			border-right: 1px solid $gallery;
			text-align: center;

			&:last-child {
				border-bottom: 0;
				border-right: 0;
			}
		}

		@include min-screen($screen__l) {
			flex: 0 1 percentage(1/4);
		}

		@include min-screen($screen__ll) {
			flex: 0 1 percentage(1/5);
		}

		@include min-screen($screen__xl) {
			flex: 0 1 percentage(1/6);
		}

		@include min-screen($screen__xxl) {
			flex: 0 1 percentage(1/8);
		}

		a { // no class available & flex needed here to make image & text inline on mobile
			display: flex;
			align-items: center;
			padding: $ten-gutter $fifteen-gutter;

			@include min-screen($screen__m) {
				flex-wrap: wrap;
				justify-content: center;
				padding: rem(25);
			}

			.category-image {
				margin-right: $fifteen-gutter;
				flex: 0 1 31px;

				@include min-screen($screen__m) {
					margin-bottom: $fifteen-gutter;
					margin-right: 0;
					flex: 1 1 100%;
				}

				.category-list-item-image {
					opacity: 0.5;
				}

				img {
					width: auto;
					height: em(45);
					max-width: unset;

					@include min-screen($screen__m) {
						height: em(130);
					}
				}
			}

			.category-list-item-title {
				@include type__13(
				    $font-weight: $font-weight-normal,
				    $line-height: 0
				 );
				flex: 1 1 100%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:after {
					content: '';
					height: em(19);
					width: em(19);
					@include svg-background('arrow-right');
				}

				@include min-screen($screen__m) {
					@include type__15($font-weight: $font-weight-semibold);
					text-transform: uppercase;
					display: inline-block;

					&:after {
						display: none;
					}
				}
			}
		}
	}
}
