/* -------------------------------------------------
 * -------------------------------------------------
 * search results auto complete
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.search-autocomplete {
	z-index: 10000;
	background-color: $body-background-color;
	position: absolute;
	width: auto !important; // this overwrites a js fixed width
	top: $header-search-dropdown-top;
	right: 0;
	left: 0;
	min-width: auto;
	overflow: auto;
	max-height: 95vh; // the max height is needed in order for an overflow to be registered so it is scrollable

	@include min-screen($screen__m) {
		z-index: 40;
		top: 53px; // we do not want this to scale as will lose positioning
		width: rem(400) !important; // the suggest box gets too small at this breakpoint & is using inline width
	}

	@include min-screen($screen__ll) {
		width: auto !important // Override JS fixed width
	}

	.smile-elasticsuite-autocomplete-result {
		cursor: default; // override pointer cursor on entire suggest box
		position: relative;
		padding: $fifteen-gutter;
		display: flex;
		flex-direction: column-reverse; // reversed to show category first

		&:empty {
			display: none; // Hide the results element when there are no results
		}

		.autocomplete-list {
			margin-bottom: $gutter;
			flex: 0 1 auto;

			&:first-child {
				margin-bottom: 0;
			}

			.autocomplete-list-title {
				@include type__18(
					$color: $dusty-gray,
					$font-weight: $font-weight-semibold
				);
				text-transform: capitalize;
				margin-bottom: $fifteen-gutter;
				padding-bottom: $fifteen-gutter;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $gallery;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.product-name {
				@include type__13;
			}

			.minimal-price-link { // tier pricing duplicate price
				display: none;
			}

			.price-to {
				display: none;
			}

			dd {
				cursor: pointer; // href is applied to the dd
				margin-bottom: $twenty-gutter;
				@include type__13;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					display: flex;
				}

				.product-image-box {
					padding-right: $twenty-gutter;

					img {
						width: 100%;
						max-width: rem(50);
					}
				}
			}

			.category-mini-crumb {
				margin-right: $five-gutter;
				flex: 0 0 auto;
			}
		}
	}
}
