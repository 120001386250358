// Px to em conversion
// @param  {Number/String} $target            - Pixel target
// @param  {Number/String} $context: $base-em - Current context
// @return {String}                           - Em value
@function em($target, $context: $base-em) {
	$target: strip-unit($target) / strip-unit($context) * 1em;
	@if ($target==0) {
		$target: 0;
	} // Turn 0em into 0
	@return $target;
}