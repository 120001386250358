/* -------------------------------------------------
 * -------------------------------------------------
 * All styles for category description

 * Normally used for SEO purposes - hence name - As
 * standard this is set to appear at the bottom of
 * the page in Magento_Catalog/layout/catalog_category_view.xml
 * but can be overrriden in child theme
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.category-footer-seo {
	background-color: $seo-description-bg;
	border-bottom: $seo-description-border;
	padding: $gutter;
	clear: both;

	@include min-screen($screen__m) {
		padding: $fifty-gutter;
	}

	@include min-screen($screen__l) {
		padding: $sixty-gutter rem(120);
	}

	.block-title-wrapper {
		text-align: center;

		.page-title {
			text-transform: uppercase;
			margin-bottom: $twenty-gutter;

			@include type__22(
				$color: $dusty-gray, 
				$font-weight: $font-weight-bold
			);

			@include min-screen($screen__m) {
				@include type__36(
					$color: $dusty-gray, 
					$font-weight: $font-weight-bold
				);
			}
		}
	}

	.category-description {
		margin: 0 auto;
		text-align: center;

		@include min-screen($screen__l) {
			max-width: $seo-description-container-width;
		}

		p {
			@include type__13;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}
