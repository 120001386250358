/* -------------------------------------------------
 * -------------------------------------------------
 * Modal Overlays
 *
 * 1. modal-overlay used for nav Overlay
 * 2. modals-overlay used for basket preview overlay. FFS!!!
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.modal-overlay,
.modals-overlay {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $overlay-color-alt;
	z-index: 10;

	&.header-links-overlay {
		z-index: 100;
	}

	&.nav-visible,
	&.active,
	&.search-visible {
		display: block;
	}
}
