// --------------------------------------------------------
//  Base styling for all buttons.
// --------------------------------------------------------
//  Mixin should only really be used in _buttons.scss
//  Any other styles should be specific to only that
//  instance of a button and should be nested correctly
// --------------------------------------------------------
@mixin button (
	$background-color: $button-default-background,
	$padding: $item-gutter $twenty-gutter,
	$font-family: $font-family-sans-serif,
	$weight: $font-weight-bold,
	$color: $body-font-color,
	$border-radius: 0,
	$border-width: 1px,
	$border-style: solid,
	$border-color: $border-color
) {
	background-color: $background-color;
	color: $color;
	padding: $padding;
	border-width: $border-width;
	border-style: $border-style;
	border-color: $border-color;
	border-radius: $border-radius;
	font-family: $font-family;
	font-weight: $weight;
	cursor: pointer;
	align-self: center; // in case in flex area
}

// font-size and text-transform should be handled in silent class
