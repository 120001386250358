/* -------------------------------------------------
 * -------------------------------------------------
 * Footer block styles
 * -------------------------------------------------
 * @author Fisheye
 */

 .page-footer {
	.mob-footer-info-tab {
		order: 2;
	}

	.social {
		order: 1;
	}

	.footer-copyright {
		order: 3;
	}

	.trustpilot-widget {
		height: calc(28px + #{$ten-gutter + rem(7)});
		background-color: $tuna;
		padding: $ten-gutter 0 rem(7);
		position: fixed !important; // Important to override inline style
		bottom: 0;
		width: 100%;
		z-index: 10;

		~ .footer-cards-container,
		~ .checkout-footer {
			margin-bottom: rem(44);
		}

		.catalog-product-view & {
			// Hiding the trustpilot widget on the mobile product page
			display: none;

			@include min-screen($screen__m) {
				display: block;
			}

			// Max width query used here as to not duplicate code
			@include max-screen($screen__m) {
				~ .footer-cards-container,
				~ .checkout-footer {
					margin-bottom: 0;
				}
			}
		}
	}

	// ----------------------------------------------------------------------------
	// Footer Company Logo Row
	// Contains Brand Logos
	// ----------------------------------------------------------------------------

	.company-logos-container {
		background-color: $footer-row-company-bg;
		padding: $fifteen-gutter;
		border-top: 1px solid $white;

		@include min-screen($screen__l) {
			padding: $gutter $forty-gutter;
		}

		.pagebuilder-column-group {
			flex-wrap: wrap;

			@include min-screen($screen__l) {
				flex-wrap: nowrap;
			}

			.pagebuilder-column {
				flex: 1 1 percentage(1/3);
				text-align: center;
				padding-right: $ten-gutter;

				&:last-child {
					display: none !important; // Override inline style from Pagebuilder
					padding-right: 0;
				}

				@include min-screen($screen__m) {
					padding-right: $gutter;
				}

				@include min-screen($screen__xl) {
					flex: 1 1 percentage(1/7);

					&:last-child {
						display: block !important; // Override inline style from Pagebuilder
					}
				}

				.pagebuilder-image-desktop,
				.pagebuilder-button-image-mobile {
					width: em(156);
				}
			}
		}
	}

	// ----------------------------------------------------------------------------
	// Footer Row One
	// Contains Newsletter & Reviews Content
	// ----------------------------------------------------------------------------

	.footer-row-one {
		display: flex;
		flex-direction: column;
		background-color: $footer-row-one-bg;
		padding: 0;

		@include min-screen($screen__m) {
			max-width: 100%;
			flex-direction: row;
		}

		.content,
		.pagebuilder-column { // Content elements for row one
			margin: auto;

			@include min-screen($screen__m) {
				max-width: em(460);
			}
		}
	}

	.block.newsletter,
	.footer-reviews {
		text-align: center;
		flex: $footer-col-newsletter-flex;
		padding: $gutter $twenty-gutter;

		@include min-screen($screen__m) {
			flex: $footer-col-newsletter-flex-desktop;
			padding: $fifty-gutter $twenty-gutter;
		}

		h4 {
			text-transform: uppercase;
			margin-bottom: $fifteen-gutter;

			@include type__16(
				$color: $body-background-color,
				$font-weight: $font-weight-extrabold
			);

			@include min-screen($screen__m) {
				@include type__21(
					$color: $body-background-color
				);
			}
		}

		p {
			margin-bottom: $twenty-gutter;

			@include type__13(
				$color: $body-background-color
			);

			@include min-screen($screen__m) {
				margin-bottom: $gutter;
			}
		}

		.newsletter-description {
			p {
				@include min-screen($screen__m) {
					margin: auto auto $gutter;
					max-width: rem(420);
				}
			}
		}
	}

	.block.newsletter {
		border-bottom: 1px solid $border-color-alt;

		@include min-screen($screen__m) {
			display: $footer-col-newsletter-desktop-display;
			border-bottom: 0;
			border-right: 1px solid $border-color-alt;
		}

		.field.newsletter {
			flex: $footer-col-newsletter-input-flex;
		}

		.label {
			@extend %visuallyhidden;
		}

		.control {
			margin: 0 auto;

			input {
				@include input-style-two(
					$background-color: $brand-base
				);

				&::placeholder {
					@include type__14(
						$color: $body-background-color
					);
				}
			}
		}

		.subscribe {
			display: flex;
			justify-content: center;
			padding: $footer-col-newsletter-form-padding;
			position: relative;
			background: none;
			border: none;
			max-width: em(350);
			margin: auto;

			.actions {
				position: absolute;
				right: em(20);
				display: flex;
				top: em(9);

				.primary {
					&:after {
						content: '';
						height: em(20);
						width: em(20);
						@include svg-background('sign-up-arrow');
					}

					span {
						@extend %visuallyhidden;
					}
				}
			}
		}
	}

	.footer-reviews {
		@if $footer-col-newsletter-enabled-on-mobile == true {
			margin-top: $footer-col-reviews-mobile-margin-top;
			padding-top: $footer-col-reviews-mobile-padding-top;
			border-top: $footer-col-divider-border;
		}

		figure {
			display: block;
			width: em(170);
			margin: 0 auto;
			margin-bottom: $ten-gutter;
		}

		.pagebuilder-buttons {
			@include button__default;
			max-width: em(210);
			margin: auto;
			cursor: pointer;

			span {
				@include type__14(
					$color: $footer-link-color
				);
			}
		}
	}

	// ----------------------------------------------------------------------------
	// Footer Row Two
	// Contains Newsletter & Reviews Content
	// ----------------------------------------------------------------------------

	.footer-row-two,
	.mob-footer-info-tab {
		display: flex;
		flex-direction: column;
		background-color: $footer-row-two-bg;
		padding: $gutter $twenty-gutter;

		@include min-screen($screen__m) {
			max-width: 100%;
			flex-direction: row;
		}

		@include min-screen($screen__l) {
			padding: $forty-gutter;
		}

		.footer-links {
			.pagebuilder-column {
				&:first-child {
					@include min-screen($screen__m) {
						margin-right: $sixty-gutter;
					}

					@include min-screen($screen__ll) {
						margin-right: $gutter;
					}
				}
			}

			h4 {
				text-transform: uppercase;
				margin-bottom: $ten-gutter;

				@include type__16(
					$color: $footer-title-color
				);
			}

			li {
				width: 100%;
				margin-bottom: $five-gutter;

				a {
					&:focus {
						opacity: 1;
					}
				}

				span {
					@include type__13(
						$color: $footer-link-color
					);
				}
			}
		}

		.footer-links {
			h4 {
				text-transform: uppercase;
				margin-bottom: $ten-gutter;

				@include type__16(
					$color: $footer-title-color
				);
			}

			li {
				width: 100%;
				margin-bottom: $five-gutter;

				span {
					@include type__13(
						$color: $footer-link-color
					);
				}
			}
		}
	}

	.useful-info {
		flex: $footer-col-useful-info-flex;
		order: 2;

		@include min-screen($screen__m) {
			flex: $footer-col-useful-info-flex-tablet;
		}

		@include min-screen($screen__l) {
			flex: $footer-col-useful-info-flex-desktop;
		}
	}

	.need-help {
		flex: $footer-col-help-flex;

		@include min-screen($screen__m) {
			flex: $footer-col-help-flex-tablet;
		}

		@include min-screen($screen__l) {
			flex: $footer-col-help-flex-desktop;
		}

		@include min-screen($screen__ll) {
			flex: 1 1 8%;
			margin-right: $forty-gutter;
		}

		.pagebuilder-button-primary {
			display: block;
			background-size: contain;
			height: em(25);
			width: em(98);
		}

		.footer-logo {
			a {
				@include svg-background('footer-logo');
				margin-bottom: $ten-gutter;

				span {
					@extend %visuallyhidden;
				}
			}
		}

		p {
			margin-bottom: $five-gutter;

			&:last-child {
				margin-bottom: 0;
				font-style: italic;
			}

			@include type__13(
				$color: $footer-link-color
			);

			a {
				color: $white; // needed for iPhone specific devices where phone number is 'hidden'
			}
		}

		.need-help-button {
			.pagebuilder-button-primary {
				display: flex;
				justify-content: center;
				@include button__journey($padding: rem(8));
				width: em(133);
				height: auto;
				text-align: center;
				margin-top: rem(25);

				span {
					@include type__14($color: $white);
					display: flex;

					&:before {
						content: '';
						display: inline-block;
						width: 19px;
						height: 19px;
						margin-right: $five-gutter;
						@include svg-background(
							$name: help-icon,
							$size: contain
						);
					}
				}
			}
		}
	}

	.additional-info {
		flex: $footer-col-additional-info-flex;
		order: 3;
		flex-wrap: wrap;
		flex-direction: row;
		text-align: right;
		display: flex;
		justify-content: space-between;

		@include min-screen($screen__m) {
			flex: $footer-col-additional-info-flex-tablet;
			flex-direction: column;
		}

		@include min-screen($screen__l) {
			flex: $footer-col-additional-info-flex-desktop;
		}

		.footer-social-links {
			.pagebuilder-button-primary {
				display: inline-block;
				background-size: contain;
				height: em(44);
				width: em(44);

				span {
					@extend %visuallyhidden;
				}
			}

			li {
				background: none; /* Remove old image from core*/
			}

			.twitter,
			.facebook {
				margin-right: $ten-gutter;
			}

			.instagram {
				a {
					@include svg-background('instagram-round');
				}
			}

			.twitter {
				a {
					@include svg-background('twitter-round');
				}
			}

			.facebook {
				a {
					@include svg-background('facebook-round');
				}
			}
		}

		.copyright-info {
			order: 2;
			display: flex;
			flex-wrap: wrap;
			flex: 1;

			@include min-screen($screen__m) {
				flex: none;
			}

			p {
				flex: 1 1 100%;

				@include type__12(
					$color: $footer-link-color
				);
			}

			a {
				color: $white;
			}
		}
	}

	.footer-cards-container {
		background-color: $footer-row-two-bg;
		width: 100%;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		padding: $ten-gutter;
		text-align: center;
	}

	.mob-footer-info-tab {
		padding-left: 0;
		padding-right: 0;
		cursor: pointer;

		@include min-screen($screen__m) {
			display: none;
		}

		.mobile-accordion-title {
			text-transform: uppercase;
			padding: $fifteen-gutter 0;
			margin-bottom: 0;
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;

			@include type__14(
				$color: $footer-title-color
			);

			&:after {
				@include svg-background('plus-green');
			}

			&.open {
				margin-bottom: $gutter;

				&:after {
					@include svg-background('minus-green');
				}
			}
		}

		.footer-links {
			padding-bottom: 0;

			.pagebuilder-column {
				&:first-child {
					margin-bottom: $gutter;
				}

				h4 {
					@include type__14(
						$color: $footer-title-color
					);
				}
			}
		}
	}
}
