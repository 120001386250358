/* -------------------------------------------------
 * -------------------------------------------------
 * Product Sort By & Pagination toolbars
 *
 * -------------------------------------------------
 * @author Fisheye
 */


/* Toolbar used on category bottom and recent orders in accounts section
 * -------------------------------------------------
 */

.toolbar {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	background-color: $toolbar-background-color;
	padding: $toolbar-container-spacing-mobile;

	@include min-screen($screen__m) {
		padding: $toolbar-container-spacing;
		border-bottom: solid 1px $gallery;
		flex-wrap: nowrap;
	}

	.modes {
		display: none;
	}

	/*
	 * Showing x of y items text
	 * -----------------
	 */

	.toolbar-amount {
		order: $toolbar-amount-flex-order;
		display: $toolbar-toolbar-amount-display-setting;
		line-height: 2.5;
		margin-bottom: 0;
		margin-left: auto;
		color: $toolbar-amount-color;
		padding: $toolbar-item-spacing;

		.toolbar-number {
			@include type__16;
		}
	}

	/*
	* Next Day Delivery Button
	* -------------------------
	*/

	.next-day-delivery {
		width: 100%;
		display: flex;
		justify-content: center;
		height: 2.375rem;

		@include min-screen($screen__m) {
			width: auto;
		}

		&.button {
			@include button__journey(
				$padding: $ten-gutter $twenty-gutter,
				$background-color: $radical-red
			);

			@include min-screen($screen__m) {
				margin-right: $fifteen-gutter;
			}

			span {
				display: flex;
				align-items: center;
				@include type__14($color: $white);

				&:before {
					content: '';
					display: inline-block;
					height: 20px;
					width: 27px;
					margin-right: rem(7);
					@include svg-background(
						$name: van-white,
						$size: contain
					);
				}
			}
		}
	}

	/*
	 * Sort By - select field
	 * -----------------
	 */

	.toolbar-sorter,
	.field.limiter {
		flex: 0 1 100%;
		display: inline;
		position: relative;
		margin-bottom: $toolbar-sorter-spacing;

		@include min-screen($screen__m) {
			flex: 0 1 auto;
			margin-bottom: 0;
		}

		.sorter-label,
		.label {
			display: none;
		}

		.sorter-options,
		.limiter-options {
			@include min-screen($screen__m) {
				width: $toolbar-sorter-width;
				@include select-style-three(
					$padding: $ten-gutter rem(46) $ten-gutter $twenty-gutter
				);
			}
		}

		.limiter-options {
			min-width: em(175);
			text-align: left;
		}

		.sorter-action {
			display: inline-block;
			width: $toolbar-sorter-action-width;
			height: $toolbar-sorter-action-height;

			span {
				@extend %visuallyhidden;
			}
		}
	}

	.limiter {
		@include min-screen($screen__m) {
			margin-right: $fifteen-gutter;
		}
	}

	.toolbar-sorter {
		order: $toolbar-sorter-flex-order;
		display: none; // prevent the sorter dropdown from appearing on page load

		@include min-screen($screen__m) {
			flex: 0 1 $toolbar-select-width;
			display: flex;
		}
	}
}
