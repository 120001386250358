// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
// This is the styling for the top level navigation on desktop for CORE
// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
.page-header {
	.nav-sections {
		@extend %fullwidth;
		margin-bottom: 0;
		z-index: 11; // so menus are not behind trustbuilder
		max-width: 100%;

		@include transition(display 0.3s ease);

		@include min-screen($screen__m) {
			height: $desktop-header-nav-height;
		}

		.nav-sections-item-title {
			// 1st occurance of this is the menu link for mobile menu
			// 2nd occurance is the account menu link for mobile menu
			display: none;
		}

		.nav-sections-item-content {
			// 1st occurance of this is the main desktop menu
			// 2nd occurance is the account menu list in mobile view
			margin: 0;
			padding: 0;
		}

		// nested so that magento ui is overwritten
		.navigation {
			margin-left: 0;
			margin-right: 0;
			background-color: $desktop-nav-background-color; // On desktop this should always be hidden as it doesn't appear inside the .nav-open class

			@include min-screen($screen__m) {
				height: $desktop-header-nav-height;
			}

			.fisheye-menu-back.hidden {
				visibility: hidden;
				opacity: 0;
				height: 0;
			}

			ul.navigation-main-ul {
				@include min-screen($screen__m) {
					text-align: center;
					display: flex;
					height: 100%;
					justify-content: center;
					flex-direction: row;
					align-items: normal;
				}
			}

			li {
				margin-bottom: 0; // cancels core typography for lists
			}

			li.level0 {
				// the main top level li. Chained for readability
				@include min-screen($screen__m) {
					display: flex;
					align-items: center;
					border-right: 1px solid lighten($keppel, 12%);

					&:nth-last-child(-n+2) {
						border-right: 0;
					}
				}

				@include min-screen($screen__ll) {
					border-right: 0;
				}

				// Top level a tag
				>a.level-top,
				> .pagebuilder-column-group a {
					position: relative;
					display: flex;
					align-items: center;
					height: 100%; // in order to trigger arrow when li area is hovered
					color: $desktop-nav-link-color;
					opacity: 1;

					&:hover {
						.menu-item-title-text {
							opacity: $link-opacity;
						}
					}

					@include min-screen($screen__m) {
						@include type__12(
							$color: $desktop-nav-link-color,
							$font-weight: $desktop-nav-link-weight,
							$line-height: 1.37
						);
						text-transform: uppercase;
						padding: $desktop-nav-items-spacing;

						&:after {
							// removes down arrow needed on menu
							display: none;
						}
					}

					@include min-screen($screen__l) {
						@include type__15(
							$color: $desktop-nav-link-color,
							$font-weight: $desktop-nav-link-weight
						);
						padding: 0 rem(13);
					}

					@include min-screen($screen__xxl) {
						@include type__16(
							$color: $desktop-nav-link-color,
							$font-weight: $desktop-nav-link-weight
						);
						padding: $desktop-nav-items-spacing;
					}

					&.ui-state-focus,
					&.ui-state-active {
						&:after {
							@include css-triangle(
								$triangle-size: 7px,
								$triangle-color: $dropdown-arrow-colour,
								$triangle-direction: bottom
							);
						}
					}

					.menu-image {
						display: none; // removes the mobile menu images
					}

					// -----------------------------------------
					// Optional down arrow needed on menu
					// &:hover {
					// 	&:after {
					// 		// triangle under menu items
					// 	    content: '';
					// 	    display: block;
					// 	    position: absolute;
					// 	    margin-top: 23px; // we don't want this to change!
					// 	    left: calc(50% - 3.5px);
					// 	    width: 7px;
					// 	    height: 7px;
					// 	    border-bottom: solid 7px $mega-menu-background-color;
					// 	    border-left: solid 7px transparent;
					// 	    border-right: solid 7px transparent;
					// 	}
					// }
					// -----------------------------------------
				}
			}

			ul {
				&.level0 {
					visibility: hidden;
					opacity: 0;
					height: 0;
				}

				&[aria-expanded="true"] {
					visibility: visible;
					opacity: 1;
					height: auto; // overflow-y: scroll;
				}

				&[aria-hidden="false"] {
					visibility: visible;
					opacity: 1;
					height: auto; // overflow-y: scroll;
				}

				&[aria-expanded="false"] {
					visibility: hidden;
					opacity: 0;
					height: 0;
				}

				&[aria-hidden="true"] {
					visibility: hidden;
					opacity: 0;
					height: 0;
				}

				ul {
					// overflow: hidden;
					padding: 0;
					margin: 0;
				}
			}
		}
	} // closes .nav-sections
	// -----------------------------------------------------------------------------------
} // closes .page-header
