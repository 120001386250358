/* -------------------------------------------------
 * Object Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Tooltip Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Tooltip Variables
// -------------------------------------------------

$tooltip-content-padding: $fifteen-gutter;
$tooltip-arrow-color: $charcoal-grey;
$tooltip-background: $charcoal-grey;
$tooltip-text-color: $white;
$tooltip-icon-right: 10px;
$tooltip-icon-top: 2px;

// -------------------------------------------------
// 2. Spinner Variables
// ------------------------------------------------

$spinner-border-color: $border-color; // The (usually) grey coloured border that the spinner moves around
$spinner-color: $brand-accent; // The moving part of the spinner

// -------------------------------------------------
// 3. Button Variables
// -------------------------------------------------

// Big primary button, used for the journey
$button-journey-background: $keppel;
$button-journey-background-hover: darken($mountain-meadow, 12%);
$button-journey-paypal-background: $casablanca;
$button-journey-padding: rem(13) $twenty-gutter rem(11);
$button-journey-radius: 100px;
$button-journey-color: $white;
$button-journey-weight: $font-weight-extrabold;

// Secondary button, usually for actions
$button-default-background: $keppel;
$button-default-background-hover: darken($button-default-background, 12%);
$button-default-padding: rem(9) $gutter;
$button-default-radius: 100px;
$button-default-color: $white;
$button-default-color-hover: auto;
$button-default-border: 0;
$button-default-border-hover: 0;
$button-default-weight: $font-weight-semibold;

// Another secondary button, using the accent colour
$button-accent-background: $white;
$button-accent-padding: rem(12) rem(20);
$button-accent-radius: 0;
$button-accent-background-alt: $brand-accent;
$button-accent-color: $brand-accent;
$button-accent-color-alt: $white;

// Pagination buttons on category pages
$button-pagination-background: $gallery;
$button-pagination-arrow-background: $charcoal-grey;
$button-pagination-current-background: $charcoal-grey;
$button-pagination-current-color: $white;
$button-pagination-padding: rem(8);
$button-pagination-radius: 0;
$button-pagination-width: rem(41);
$button-pagination-height: rem(37);
$button-pagination-color: $ebony-clay;

// A standard grey button
$button-grey-padding: rem(18) rem(20);
$button-grey-background: $alto;
$button-grey-radius: 30px;

// -------------------------------------------------
// 4. Input Variables
// -------------------------------------------------

// input-style-one
$field-color: $brand-base;
$field-placeholder-color: $dusty-gray;
$field-background-color: $white;
$field-border: 1px solid $silver;
$field-border-hover: 1px solid darken($silver, 12%);
$field-border-focus: 1px solid darken($silver, 12%);
$field-border-radius: 0;
$field-required-input-color: $brand-accent;
$field-color-focus: $brand-accent;
$field-padding: rem(8) rem(14); // 10px 16px / 16 for em value


// input-style-two (For search and some other inputs)
$field-two-color: $white;
$field-two-background-color: $charcoal-grey;
$field-two-border: 0;
$field-two-border-hover: 0;
$field-two-border-focus: 0;
$field-two-border-radius: 100px;
$field-two-required-input-color: $brand-accent;
$field-two-color-focus: $brand-accent;
$field-two-padding: rem(9) $twenty-gutter; // 10px 16px / 16 for em value
$field-two-placeholder-color: $white;
$field-two-placeholder-weight: $font-weight-semibold;
$field-two-placeholder-font-style: normal;

// input-style-three (QTY)
$field-three-color: $white;
$field-three-background-color: $dusty-gray;
$field-three-border: 0;
$field-three-border-hover: 0;
$field-three-border-focus: 0;
$field-three-border-radius: 100px;
$field-three-placeholder-color: $white;
$field-three-width: em(52);
$field-three-padding: rem(9) rem(14); // 10px 16px / 16 for em value

// select-style-one
$select-color: $ebony-clay;
$select-weight: $font-weight-normal;
$select-background-color: $white;
$select-border: 1px solid $silver;
$select-border-hover: 0;
$select-border-focus: 0;
$select-border-radius: 0;
$select-required-input-color: $brand-accent;
$select-color-focus: $brand-accent;
$select-padding: rem(8) rem(14); // to match values of general input fields
$select-arrow-background-position: calc(100% - #{$twenty-gutter}) 50%;
$select-arrow-background-size: 17px 10px;

// select-style-two
$select-two-color: $ebony-clay;
$select-two-weight: $font-weight-semibold;
$select-two-background-color: transparent;
$select-two-border-top: 1px solid $silver;
$select-two-border-bottom: 1px solid $silver;
$select-two-border-left: 0;
$select-two-border-right: 0;
$select-two-border-hover: 0;
$select-two-border-focus: 0;
$select-two-border-radius: 0;
$select-two-required-input-color: $brand-accent;
$select-two-color-focus: $brand-accent;
$select-two-padding: rem(8) 0; // 10px 16px / 16 for em value
$select-two-arrow-background-position: 100% 50%;
$select-two-arrow-background-size: 22px 11px;

// select-style-three
$select-three-color: $ebony-clay;
$select-three-weight: $font-weight-semibold;
$select-three-background-color: $gallery;
$select-three-border: 1px solid $gallery;
$select-three-border-color-hover: $silver;
$select-three-border-focus: 0;
$select-three-border-radius: 40px;
$select-three-padding: $ten-gutter $twenty-gutter;
$select-three-arrow-background-position: 90% 50%;
$select-three-arrow-background-size: 22px 11px;

// Misc
$form-hints-background: $grey-lightest;
$form-placeholder-color: $grey-lightest;
$checkbox-width: 29px;
$checkbox-height: 29px;
$radio-width: 29px;
$radio-height: 29px;
$radio-margin-right: 12px; // The spacing between a checkbox / radio button and its label.
$checkbox-margin-right: 12px; // The spacing between a checkbox / radio button and its label.

// -------------------------------------------------
// 5. Modal Variables
// -------------------------------------------------

$modal-gutter: $gutter; // Gutters for modals
$modal-header-border: 1px solid $border-color; // Border to divide header from content
$modal-footer-border: 1px solid $border-color; // Border to divide footer from content

// -------------------------------------------------
// 6. Price Variables
// -------------------------------------------------

$listing-price-color: $red-ribbon;
$listing-price-size: 19;
$listing-price-weight: $font-weight-bold;
$listing-price-letter-spacing: 0.06em;
$listing-price-background-color: transparent;
$listing-price-spacing: $ten-gutter;

$product-page-size: 29;
$product-page-weight: $font-weight-bold;
$product-page-letter-spacing: 0;
$product-page-transform: uppercase;
