/* -------------------------------------------------
 * -------------------------------------------------
 * Button Styles
 * -------------------------------------------------
 * @author Fisheye
 */

/* Default button
 * -------------------------------------------------
 * Brand Accent color background with white text / opposite on hover
 */

@mixin button__default(
	$padding: $button-default-padding,
	$background-color: $button-default-background,
	$border: $button-default-border,
	$border-radius: $button-default-radius,
	$color: $button-default-color,
	$font-weight: $button-default-weight
) {
	@include type__14(
		$color: $color,
		$font-weight: $font-weight
	);
	padding: $padding;
	background-color: $background-color;
	border: $border;
	border-radius: $border-radius;
	text-transform: uppercase;

	&:hover,
	&:focus {
		background-color: $button-default-background-hover;
		border: $button-default-border-hover;
		opacity: 1;
		color: $button-default-color-hover;

		span {
			color: $button-default-color-hover;
		}
	}

	span {
		@include type__14($color: $color);
	}
}

/* Journey Button
 * -------------------------------------------------
 * Green color background with white text / opposite on hover
 */

@mixin button__journey(
	$padding: $button-journey-padding,
	$background-color: $button-journey-background,
	$border-radius: $button-journey-radius,
	$font-weight: $button-journey-weight,
	$color: $button-journey-color
) {
	padding: $padding;
	background-color: $background-color;
	border-radius: $border-radius;
	border: 0;
	
	@include type__21(
		$color: $color,
		$font-weight: $font-weight-bold
	);

	&:hover,
	&:focus {
		background-color: darken($background-color, 12%);
	}

	span {
		@include type__21(
			$color: $color,
			$font-weight: $font-weight-bold
		);
		text-transform: uppercase;
	}
}

/* Grey Button
 * -------------------------------------------------
 * Grey color background with white text / opposite on hover
 */

@mixin button__grey(
	$padding: $button-grey-padding,
	$background-color: $button-grey-background,
	$border-radius: $button-grey-radius,
	$color: $ebony-clay
) {
	padding: $padding;
	background-color: $background-color;
	border-radius: $border-radius;
	display: flex; // Center the text
	justify-content: center; // Center the text

	&:hover,
	&:focus {
		background-color: darken($background-color, 12%);
	}

	span {
		@include type__14(
			$color: $color
		);
		text-transform: uppercase;
	}
}

/* Address button styles
 * --------------------------------------------------------------
 * Light grey button with blue text and no rounded edges 
 * Can be found on account & checkout pages
 */

@mixin button__address(
	$padding: $ten-gutter $twenty-gutter,
	$background-color: $gainsboro,
	$border-radius: 0
) {
	padding: $padding;
	background-color: $background-color;
	border-radius: $border-radius;

	&:hover,
	&:focus,
	&:visited {
		background-color: darken($background-color, 12%);
	}

	span {
		@include type__14($color: $ebony-clay);
	}
}

/* Pagination Button
 * -------------------------------------------------
 * Pagination buttons used on category pages
 */

@mixin button__pagination(
	$background-color: $button-pagination-background,
	$padding: $button-pagination-padding,
	$color: $button-pagination-color,
) {
	display: block;
	background-color: $background-color;
	padding: $padding;
	height: $button-pagination-height; // added to stop button height varying moving as they scale
	margin: 0 1px; // the spacing between pagination buttons which don't neeed to be scaled
	width: $button-pagination-width;
	text-align: center;
	border-radius: $button-pagination-radius;

	&.next,
	&.previous {
		background-color: $button-pagination-arrow-background;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
		@include icon__arrow-white(
			$width: $button-pagination-width,
			$height: $button-pagination-height
		);
	}

	&.previous {
		transform: rotate(180deg);
	}

	span {
		@include type__14(
			$color: $color
		)
	}
}

/* Cancel button styles
 * --------------------------------------------------------------
 * Completely removes button styles
 */

%cancel-button {
	background: none;
	border: 0;
}

/* Accent Button
 * -------------------------------------------------
 * white color background with accent text / opposite on hover
 */

 @mixin button__accent(
	$padding: $button-accent-padding,
	$background-color: $button-accent-background,
	$border-radius: $button-accent-radius,
	$background-color-alt: $button-accent-background-alt,
	$color: $button-accent-color,
	$color-alt: $button-accent-color-alt,
) {
	padding: $padding;
	background-color: $background-color;
	border-radius: $border-radius;
	color: $color;

	span {
		@include type__16;
		color: $color;
	}

	&:hover,
	&:focus,
	&:visited {
		background-color: $button-accent-background-alt;
		// Using an alt variable here as darken will not work as we need the colours for text and background to swap

		span {
			color: $color-alt;
		}
	}
}

/* Cancel button styles
 * --------------------------------------------------------------
 * Completely removes button styles 
 */

%cancel-button {
	background: none;
	border: 0;
}

// /* Close and remove icon styles
//  * --------------------------------------------------------------
//  * - called action as sometimes can be links, sometimes buttons
//  */

@mixin action-w-icon__cross-black(
	$color: $black
) {
	@include icon__cross-black;
	@include type__16($color: $color);

	&:before {
		display: none;
	}

	&:after {
		margin-left: em(7);
	}
}

@mixin action-w-icon__cross-white (
	$color: $white
) {
	@include icon__cross-white;
	@include type__13(
		$color: $color,
		$font-weight: $font-weight-normal
	);
	display: flex;
	align-items: center;

	&:before {
		display: none;
	}
}

/* QTY Buttons
 * --------------------------------------------------------------
 */

.qty-button {
	width: em(48, 18);
	height: em(48, 18);

	@include button($grey-medium, 0, $font-weight-bold, $black, em(18));

	&:hover,
	&:focus {
		background-color: darken($product-qty-btn, 12%);
	}
}
