/* -------------------------------------------------
 * -------------------------------------------------
 * search field within the mobile menu
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.nav-sections {
	.minisearch {
		padding: $minisearch-spacing;
		background-color: $minisearch-background-color;
		position: relative;

		.label {
			@extend %visuallyhidden;
		}

		.input-text {
			@include input-style-one;
		}

		.actions {
			position: absolute;
			top: 0;
			right: $minisearch-search-icon-right;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;

			.action.search {
				background-color: $minisearch-button-background;
				border: 0;
			}
		}

		.control {
			position: relative; // stops the search results list growing too large
		} // search-autocomplete results - moved to _search-results.scss

		.product-image-box {
			min-width: em(45);
			padding-right: $item-gutter;
		}
	}
}
