/* -------------------------------------------------
 * -------------------------------------------------
 * Options that appear as tabs at the bottom of mobile menu
 * - Usually account and currency or vat switcher
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-header {
	.navigation {
		// All styling for layout of this should be navigation.scss
		.mobile-nav-footer {
			display: none; /* Don't show navigation footer as page is being loaded */
			justify-content: center;
			background-color: $mobile-nav-footer-background-color;
			text-align: center;

			.account-area {
				display: flex;
				flex: 1;
				justify-content: center;
				padding: $mobile-nav-footer-padding;

				&:before {
					@include svg-background(
						$name: mobile-account-icon,
						$size: contain
					);
					content: '';
					display: inline-block;
					width: $mobile-nav-footer-account-icon-width;
					height: $mobile-nav-footer-account-icon-height;
					margin-right: $five-gutter;
				}

				a {
					@include type__13($font-weight: $font-weight-semibold);
				}
			}
		}

		&.nav-open {
			.mobile-nav-footer {
				display: flex;
				align-items: center;
			}

			.account-area,
			.switcher-currency {
				flex: 1;
			}

			.switcher-currency {
				cursor: pointer;

				.switcher-trigger:hover {
					opacity: $link-opacity;
				}

				.currency-symbol {
					border: $mobile-nav-footer-currency-border;
					color: $mine-shaft;
					border-radius: 200px;
					width: $mobile-nav-footer-currency-width;
					height: $mobile-nav-footer-currency-height;
					text-align: center;
					display: inline-block;
					padding-top: $mobile-nav-footer-currency-padding-top;
				}

				.switcher-dropdown {
					margin: $mobile-nav-footer-currency-dropdown-padding;
				}

				.switcher-option {
					margin: $mobile-nav-footer-currency-item-padding;

					&.current {
						.currency-symbol,
						.currency-name {
							color: $mobile-nav-footer-currency-selected-color;
							font-weight: $mobile-nav-footer-currency-selected-weight;
							border-color: $mobile-nav-footer-currency-selected-color;
						}
					}

					&:hover {
						opacity: $link-opacity;
					}
				}
			}
		}

		.mobile-menu {
			background-color: $mega-menu-background-color;
			padding: $twenty-gutter $fifteen-gutter $fifteen-gutter;
			display: flex;
			flex-wrap: wrap;

			.fisheye-mobile-menu-header {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				text-align: center;
				position: relative;
				margin-bottom: $fifteen-gutter;
				flex: 0 1 100%;
				max-width: 100%;
				order: 1;

				.mobile-menu-header-title {
					@include type__14($font-weight: $font-weight-semibold);
					text-transform: uppercase;
					flex: 1 1 100%;
				}

				.mobile-menu-view-all {
					@include type__13;
					@include link__underline;
					position: relative;
					top: em(-4); // in order to close gap between mobile menu title & view all link
				}
			}

			.mobile-navigation {
				.mobile-menu-item {
					@include type__13;
					margin-bottom: rem(7);
					cursor: pointer;

					&:last-child {
						margin-bottom: 0;
					}

					.mobile-menu-link-wrapper,
					.mobile-menu-wrapper {
						display: flex;
						align-items: center;
						margin-bottom: $ten-gutter;

						&:last-child {
							margin-bottom: 0;
						}

						&:not(.display-children):after {
							content: '';
							@include svg-background('arrow-right-black');
							display: inline-block;
							background-size: contain;
							height: 20px;
							width: 9px;
						}

						img {
							border-radius: em(60);
							height: 44px;
							width: 44px;
							margin-right: $ten-gutter;
						}

						> span {
							flex: 1;
						}

						.child-listing {
							flex: 1 0 68%;
						}
					}

					&.a-z-container {
						margin-bottom: $forty-gutter;

						&:last-child {
							margin-bottom: 0;
						}

						.mobile-menu-wrapper {
							align-items: flex-start;

							&.display-children {
								.a-z-listing {
									position: relative;
									top: rem(-3); // align list items with letters

									.mobile-menu-link-wrapper {
										&:after {
											display: none;
										}
									}
								}
							}

							> span {
								@include type__20($color: $dusty-gray);
								align-self: flex-start;
								text-align: right;
								margin-right: $twenty-gutter;
							}
						}
					}
				}
			}

			.mobile-menu-list-container {
				position: relative;
				order: 3;
				flex: 0 1 100%;
				max-width: 100%;

				.slide {
					position: absolute !important;
					top: 0;
					width: 100%;
					z-index: 100;
					background-color: $mercury;
				}
			}
		}
	}

	.nav-open {
		~ .nav-sections {
			.mobile-nav-footer,
			.vat-switcher {
				display: flex;
			}
		}
	}
}
