// ------------------------------------------------------------------------------------
// This is the back button at the top of the mobile menu
.navigation {
	&.nav-open {
		.fisheye-menu-back {
			// menu back button only used on mobile
			width: 100%;
			text-align: center;
			position: relative;
			padding: $mobile-nav-header-padding;
			border-bottom: $mobile-nav-header-border-bottom;

			.back-button-view-all {
				display: block;
				text-decoration: underline;
			}
		} 
	}

	.mobile-menu {
		.fisheye-back-button {
			position: absolute;
			left: 0;
			top: 9px;
			@include transition(all 0.3s ease-in-out);

			&:hover {
				color: $mobile-nav-link-hover;
			}

			&:before {
				cursor: pointer;
				@include svg-background(
					$name: arrow-left-black,
					$size: contain
				);
				content: '';
				display: inline-block;
				width: 9px;
				height: 17px;
				margin-right: $five-gutter;
			}
		}
	}
}