/* -------------------------------------------------
 * -------------------------------------------------
 * All form styles unless specific to an area
 *
 * Not included here are:
 * 1. Coupon fields on basket
 * 2. Newsletter form in footer
 *
 * -------------------------------------------------
 * @author Fisheye
 */

form,
.form {
	fieldset,
	.fieldset {
		.field {
			// wraps form element and label
			width: 100%; // we make this 100% and then widths should be controlled by outer containers
			box-sizing: border-box;
			margin: 0 0 $twenty-gutter 0;
			flex: 1 1 100%;
			display: flex; // add flex so we control row element widths using flex-basis
			flex-direction: row;

			@media (min-width: $screen__ml) {
				flex-direction: $form-flex-direction;
			}

			&.street {
				margin: 0;

				.control {
					margin-right: 0;
				}
			}

			.label,
			label {
				text-align: left;
				font-weight: $font-weight-semibold;
				flex: 0 1 100%;
				margin-bottom: $form-label-spacing;

				@include min-screen($screen__ml) {
					flex: $form-label-flex;
				}

				span {
					@include type__14;
				}
			}

			select {
				@include input-style-one(
					$padding: rem(8) rem(43) rem(8) rem(14)
				);
			}

			.control {
				flex: 1 1 60%;

				@include min-screen($screen__ll) {
					flex: $form-input-flex-desktop;
					margin-right: $checkout-delivery-address-button-spacing;
				}

				.note {
					display: block;
					padding-top: $item-gutter / 2;
				}
			}

			.search-bar {
				display: flex;

				.input-text {
					margin-right: $checkout-delivery-address-button-spacing;
					flex: 1 1 60%;
				}

				.primary {
					@include button__address;
					border: 0;
					width: auto;
					flex: auto;

					span {
						text-transform: capitalize;
					}
				}
			}

			&.choice {
				// This is the remember me and newsletter checkbox
				// Fix to counteract flex width on standard layouts
				.label {
					flex: 1;
				}
			}

			.field-error,
			.mage-error,
			.hosted-error {
				@include type__13(
					$color: $radical-red,
					$font-weight: $font-weight-semibold
				);
				margin-top: $item-gutter;
			}

			&.required,
			&._required {
				.label:after,
				label:after {
					content: '*';
					color: $form-required-input-icon-color;
					padding-left: 0;
				}
			}
		}
	}
}
