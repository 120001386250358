// -------------------------------------------------
// -------------------------------------------------
// This is the Hamburglar icon for the mobile menu
// -------------------------------------------------
// -------------------------------------------------
.header.content {
	// Open and close icon for mobile menu
	.navigation-toggle {
		// new div added to wrap around buttons so flex can work
		button {
			// Hamburglar is a html button but we need that styling removed
			display: block;
			opacity: 1;
			padding: 0;
			line-height: inherit;
			border: 0;
			background: none;
			z-index: 10;

			&:hover,
			&:focus {
				border: none;
				outline: 0;
				background: none;
			}

			svg {
				margin: 0 auto;
			}
		} // actual SVG for hamburglar icon

		.menu-open {
			.st0 {
				fill: $header-icons-color;
			}
		} // actual SVG for close

		.menu-close {
			display: none;

			.st1 {
				fill: $header-icons-color;
			}
		} // Remove entire hamburglar button from header once we get to desktop sizes

		@include min-screen($screen__m) {
			display: none;
		} // container around button

		&.nav-open {
			.menu-close {
				display: block;
			} // actual SVG for hamburglar icon
			
			.menu-open {
				display: none;
			}
		}
	}
}

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
