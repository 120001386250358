/* -------------------------------------------------
 * -------------------------------------------------
 * VAT Switcher
 *
 * This can be found in the main global header at
 * the top of each page across the site.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.vat-switcher {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-left: $ten-gutter;
	padding: $mobile-nav-footer-padding;
	border-left: 1px solid $dusty-gray;

	@include min-screen($screen__m) {
		margin-left: auto;
		padding: 0;
		border: 0;
		justify-content: flex-end;
		max-width: max-content;
		padding: $ten-gutter $gutter;
	}

	.vat-switch {
		// Px values uses as don't want this to scale
		position: relative;
		background-color: $mine-shaft;
		width: 33px;
		height: 21px;
		border-radius: 20px;
		padding: rem(6);
		margin: 0 $five-gutter;
	}

	.vat-status {
		position: absolute;
		border-radius: 100px;
		width: 11px;
		height: 11px;
		background-color: $brand-accent;
		top: 0;
		bottom: 0;
		margin: auto;
		right: 16px;
		transition: right 0.5s;
	}

	&.enabled {
		.vat-status {
			right: 6px;
		}
	}

	span {
		@include type__13(
			$color: $ebony-clay,
			$font-weight: $font-weight-semibold
		);
		text-transform: capitalize;

		@include min-screen($screen__m) {
			color: $white;
		}
	}
}

.product-item-info,
.product-info-price {
	.special-price .price-label,
	.old-price {
		@extend %visuallyhidden; /* Show only to screen readers */
	}

	.tax-include & {
		.price-box {
			.price-excluding-tax-container {
				display: flex; /* Show Exc VAT price when Inc VAT is turned on in the VAT switcher */
				align-items: baseline;

				.price-excluding-tax-label {
					display: block !important; /* Override inline style */
					color: $edward;
					order: 2;
					margin-left: rem(3);
				}

				.price-excluding-tax {
					.price {
						color: $edward;
					}
				}
			}
		}
	}
}

.product-info-price {
	.price-box {
		.price-excluding-tax-label {
			display: none; /* This should only show when inc VAT is toggled */
		}

		.price-including-tax-label {
			@include type__14($color: $edward);
			order: 3;
			margin-left: rem(3);
		}

		.price-including-tax {
			order: 2;
			margin-left: $twenty-gutter;

			.price {
				@include type__22($color: $edward);
			}
		}

		.price-excluding-tax-container {
			display: flex;
			align-items: center;
			order: 1;

			.price {
				@include type__32($color: $carnation);
			}
		}
	}

	.tax-include & {
		.price-box {
			.price-including-tax-label {
				display: none; /* This should only show when inc VAT is toggled */
			}

			.price-including-tax {
				margin: 0;

				.price {
					@include type__32($color: $carnation);
				}
			}

			.price-excluding-tax-container {
				order: 2;
				margin-left: $twenty-gutter;
			}

			.price-excluding-tax {
				.price {
					@include type__22($color: $edward);
				}
			}

			.price-excluding-tax-label {
				@include type__14($color: $edward);
			}
		}
	}
}

.product-item-info {
	.price-excluding-tax-label {
		display: none; /* This should only show when inc VAT is toggled */
	}

	.price-including-tax {
		order: 2;

		.price {
			@include type__15($color: $edward);
		}
	}

	.price-including-tax-label {
		@include type__11($color: $edward);
		order: 3;
		align-self: center;
	}

	.price-excluding-tax-container {
		width: 100%;
	}

	.tax-include & {
		.price-box {
			.price-including-tax {
				.price {
					@include type__18($color: $carnation);

					@include min-screen($screen__m) {
						@include type__22($color: $carnation);
					}
				}
			}

			.price-including-tax-label {
				display: none; /* This should only show when exc VAT prices are toggled */
			}

			.price-excluding-tax-container {
				order: 3;

				.price-excluding-tax-label {
					@include type__11($color: $edward);
				}

				.price-excluding-tax {
					.price {
						@include type__15($color: $edward);
					}
				}
			}
		}
	}
}
