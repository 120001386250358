/* -------------------------------------------------
 * -------------------------------------------------
 * Base Styles
 * NEW for inclusion in CORE 4 !!
 * - Start with Joe Wicks
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// Set box-sizing globally to handle padding and border widths
*,
*:before,
*:after {
	box-sizing: border-box;
}

//------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------
html {
	font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1680 - 300)));
}

// To break this down in a few lines we have
// 14px as the minimum font size
// 16 as the largest, minus the smallest (14)
// We times that by 100VW minus the smallest screen size in mind for the 14px size, 300px
// Finally divide that by the largest screen width (1680) defined for 16px minus the smallest screen size again.
// So if you wanted the font-size to be 16px on a screen at 320px wide, and 22px on a screen 1600px wide the declaration would be
// 14px is minimum font-size
// 16px is largest - minus smallest (14)
// multiply this by 100vw minus the smallest screen size in mind for 14px
// Finally divide that by the largest screen width (1640) defined for 16px minus the smallest screen size again
// Original formula taken from Ethan Marcotte's site
// And illustrated in responsive design weekly email 13.12.16
// Article on viewport based type
// https://zellwk.com/blog/viewport-based-typography/
// https://madebymike.com.au/writing/precise-control-responsive-typography/
// Default body styles

//------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------

body {
	color: $body-font-color;
	background-color: $body-background-color;
	font-family: $font-family-sans-serif;
	font-style: $body-font-style;
	font-weight: $body-font-weight;
	margin: 0;
	overflow-x: hidden;
	padding: 0;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

// Remove text-shadow in selection highlight:
// https://twitter.com/miketaylr/status/12228805301
// These selection rule sets have to be separate.
::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

// A better looking default horizontal rule
hr {
	border: 0;
	border-top: 1px solid #ccc;
	display: block;
	height: 1px;
	margin: 1em 0;
	padding: 0;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

// Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}

img {
	// Responsive images
	max-width: 100%;
	height: auto; // IE fix bicubic scaling for images
	-ms-interpolation-mode: bicubic; // Get rid of gap under images by making them display: inline-block; by default
	display: inline-block;
	vertical-align: middle;
}

// Typography resets
div,
figure,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
th,
td,
label {
	margin: 0;
	padding: 0;
	@include type__16($color: inherit);
}

ul,
ol {
	list-style: none;
}

ul > li,
ol > li,
tr > td {
	line-height: 1.2; // custom line0height so lists have more space
	@include type__16;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $font-weight-bold;
}

blockquote {
	@include type__20;
}

legend {
	@include type__16($font-weight: $font-weight-bold);
}

address {
	@include type__address;
	font-style: normal;
}


// Default Link Styles
a {
	color: $link-color-default;
	text-decoration: none;
	transition: all 0.1s ease-in-out 0s;

	&:hover,
	&:focus {
		color: $link-color-hover;
		text-decoration: none;
		opacity: $link-opacity;
	}

	&:visited {
		color: $link-color-visited;
	}

	img {
		border: 0;
	}
}

// default focus styles
:focus {

	._keyfocus & {
		outline: solid 1px $focus-color-default;
		box-shadow: 1px 1px 5px $focus-color-default;
	}

	outline: none;
	box-shadow: none;
}
