/* -------------------------------------------------
 * Layout Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Minicart Variables
 * 2. - Layered Nav Variables
 * 3. - Grid Variables
 * 4. - Added To Basket Popup Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 2. Layered Nav Variables
// -------------------------------------------------

$layered-nav-container-background: $gallery;
$layered-nav-outer-spacing: $twenty-gutter; // this is used to add margin bottom between the tool bar and layered nav on category pages
$layered-nav-container-spacing-mobile: $fifteen-gutter;
$layered-nav-container-spacing: $fifteen-gutter $gutter;
$layered-nav-filter-title-bottom-spacing: $twenty-gutter; // needed in order to provide padding between filter and sub filter titles should match $layered-nav-container-spacing
$layered-nav-untoggled-height: 70px; // height when un-expanded needs to be static in order for the js to work
$layered-nav-filter-option-bottom-spacing: $twenty-gutter;
$layered-nav-filter-item-spacing: $small-gutter;
$layered-nav-filter-last-item-spacing: $twenty-gutter; // last item per block usually has slighly different padding that matches $layered-nav-filter-option-bottom-spacing
$layered-nav-filter-item-count-colour: $brand-accent;
$layered-nav-filter-item-count-spacing: $small-gutter / 2; // provides spacing between label and count
$layered-nav-filter-item-label-spacing: $small-gutter / 2;
$layered-nav-color-swatch-width: em(30);
$layered-nav-color-swatch-height: em(30);
$layered-nav-swatch-border: $brand-base; // border colour for colour swatch
$layered-nav-filter-options-checkbox-spacing: 40px; // this provides the spacing for checkboxes that are set to position absolute
$layered-nav-filter-options-checkbox-indent: -40px;
$layered-nav-swatch-options-box-spacing: $item-gutter; // spacing between coloured block and label
$layered-nav-checkbox-icon-vertical-position: em(-3); // top: em(-3)
$layered-nav-checkbox-icon-horizontal-position: 0; // left: 0;
$layered-nav-filter-toggle-width: 12px; // filter by plus and minus icon
$layered-nav-filter-toggle-height: 12px;
$layered-nav-title-arrow-width: 22px; // dropdown arrow icons
$layered-nav-title-arrow-height: 11px;
$layered-nav-title-arrow-vertical-alignment: em(5); // applied to e.g top: em(3);
$layered-nav-checkbox-spacing: $small-gutter / 2; // the padding applied under checkboxes

// -------------------------------------------------
// 3. Grid Variables
// -------------------------------------------------

$category-columns-main-spacing-mobile:  $page-padding-mobile;
$category-columns-main-spacing:  0 $item-gutter;
$category-sidebar-spacing-mobile: 0 $twenty-gutter;
$category-sidebar-spacing: 0 $item-gutter / 2;
$category-main-spacing: 0 $page-padding; // in some cases the main class needs different padding to columns so a more specific var is used

// -------------------------------------------------
// 4. Flex Grid Styles
// -------------------------------------------------

$flex-grid-column-margin: $ten-gutter; // Default margin for pagebuilder columns
$flex-grid-column-margin-desktop: $ten-gutter; // Default margin for pagebuilder columns on desktop
$flex-grid-column-padding: $ten-gutter; // Default padding for pagebuilder columns
$flex-grid-column-padding-desktop: $twenty-gutter; // Default padding for pagebuilder columns on desktop

// -------------------------------------------------
// 4. Header Styles
// -------------------------------------------------

$header-panel-background: $vulcan;
$header-panel-padding: 0 $gutter;
$header-panel-color: $white;
$header-panel-text-crop: false; // Enable (true) / disable (false) the text crop on the header panel <p> tag
$header-panel-height: auto; // This is the height of panel wrapper - it should be the same on desktop and mobile.
$header-desktop-content-height: 69px; // This only includes header.content
$header-desktop-content-left-spacing: $gutter; // Controls the inner spacing (padding) to the left of the header
$header-desktop-content-right-spacing: $gutter; // Same as above but for the right. Seperate values needed as header sometimes has no padding on the right
$desktop-header-nav-height: 52px; // total height of main nav

$header-background: $vulcan;
$header-content-padding: 0 $container-gutter; // Padding for header on mobile
$header-above-overlay: true; // Sets the header over the top of the overlay when true. Set false to disable
$header-mobile-background: $ebony-clay;
$header-border: 0; // Border separating each icon block
$header-mobile-border: 0; // Border seperating icon blocks on mobile
$header-icons-color: $white; // Icon fill colour for currency, basket and account icons
$header-icons-color-active: $white; // Icon fill colour for currency, basket and account icons when active

$header-search-icon-color: $white; // Different from other icons as tends to have its own colour
$header-search-icon-right-position: 30px; // The right position of the search icon
$header-search-icon-right-position-desktop: 20px; // The right position of the search icon
$header-search-icon-background-color: transparent;
$header-search-dropdown-top: rem(77); // Top position of search panel from the search box
$header-search-dropdown-chevron-padding: 0 $ten-gutter; // Padding of chevron between category and sub category in sutocomplete dropdown
$header-search-icon-width: 18px; // Width of search icon within search input
$header-search-icon-height: 18px; // Height of search icon within search input
$header-icons-width: 24px; // SVG icon width
$header-icons-height: 20px; // SVG icon height

$header-links-label-color: $white; // Colour of links labels, usually same as the icon colour
$header-links-label-color-active: $white; // Colour of links labels when active
$header-links-display-breakpoint: $screen__m; // The breakpoint in which the header links show & hide
$header-links-flex-direction: row; // Controls whether links label sits right or below. Set either 'row' or 'column'
$header-links-label-horizontal-spacing: em(5); // Spacing between the icon and label. Depending on above var, is either top or left padding
$header-links-tablet-width: auto; // Width of header link blocks (currency, account, basket)
$header-links-desktop-width: auto; // Width of header link blocks (currency, account, basket)
$header-links-padding: 0; // Padding of header links
$header-links-padding-desktop: 0; // Padding of header links
$header-links-active-background: transparent;
$header-links-text-crop: true;

$header-currency-icon-border: 1.8px solid $grey-dark; // Border of the currency icon
$header-currency-icon-color: $mine-shaft; // Colour of currency switcher count
$header-currency-icon-spacing-top: 0; // Spacing (padding) top for the currency switcher
$header-currency-option-icon-border: 1.8px solid $grey-dark;
$header-currency-option-active-icon-border: 1.8px solid $brand-accent;
$header-currency-option-icon-color: $brand-accent;
$header-currency-option-spacing-horizontal: em(8);

$header-mobile-justify-content: space-between;
$header-mobile-content-height: 60px;
$header-mobile-height: $header-mobile-content-height; // This is the total height of any content that needs to be fixed so that when the fixed option is selected the correct margin can be applied to the body

// Logo on desktop won't resize but the flex container and <a> around it does.
// so we need to keep a fixed width based on the logo size to reduce the actual hit area of the <a>
$logo-max-width: 296px;
$logo-tablet-max-width: 200px;
$logo-mobile-max-width: 86px;

// Header element heights, all hard pixel values to stop unpredictable scaling when text is included in containers
// Used to make sure all dropdown options from header/menu icons start at the same place on screen.
// Also used to create padding at top when header is fixed so no content is overlapped
$desktop-header-height: $header-desktop-content-height + $desktop-header-nav-height;

$tablet-header-nav-plus-search-height: 133px; // This sets the height of main nav when search box appears below
$tablet-header-height: $header-panel-height + $header-desktop-content-height + $tablet-header-nav-plus-search-height;
$tablet-header-content-left-spacing: $container-gutter; // Controls the inner spacing (padding) to the left of the header
$tablet-header-content-right-spacing: $container-gutter; // Same as above but for the right. Seperate values needed as header sometimes has no padding on the right

$dropdown-top-position: $header-desktop-content-height; // This controls where the account menu / mini basket and any other header icons that would have an additional menu open from
$dropdown-width: em(435); // Width of dropdown boxes (Basket, account, etc)
$dropdown-outer-spacing: 0;
$dropdown-padding: $twenty-gutter;
$dropdown-content-padding: 0 $gutter rem(23) ;
$dropdown-background-color: $mercury;
$dropdown-title-border: 0;
$dropdown-title-padding: rem(27) $gutter rem(8);
$dropdown-arrow-colour: $mercury;
$dropdown-enable-triangle: true; // Set true to show a triangle pointer for the dropdown and false for a background colour instead
$greet-welcome-gutter: rem(27) $gutter $five-gutter;
$dropdown-list-spacing: $five-gutter;
$dropdown-dividing-line-full-width: true;
// this determines whether to use padding or margin for gutter, the reason for this is if we use margin over padding the dividing line will be indented and in line with
// the rest of the minicart contents, vice versa means the dividing line is the full width of the container

$block-title-margin-bottom: $dropdown-padding;
$greet-welcome-padding: 0;
$greet-welcome-margin-bottom: $dropdown-padding;
$greet-welcome-padding-bottom: 0;

@if $dropdown-dividing-line-full-width == false {
	// the following are required for if the the full-width toggle changes
	$block-title-margin-bottom: 0;
	$greet-welcome-gutter: $dropdown-padding;
	$greet-welcome-margin-bottom: $dropdown-padding;
	$greet-welcome-padding-bottom: $dropdown-padding;
}

$currency-dropdown-inner-spacing: $twenty-gutter; // Inner spacing (padding) of the curreny dropdown box
$currency-dropdown-item-top-spacing: em(5); // Top spacing (padding) between each list item
$currency-dropdown-background: $white; // Background of currency dropdown box

// The order of these elements change on a per site basis.
// This sets the Flex order so that we can maintain markup
// quality and correct order for accessibility
$header-mobile-burger-flex-order: 0;
$header-mobile-logo-flex-order: 1;
$header-mobile-search-flex-order: 2;
$header-mobile-minicart-flex-order: 5;
$header-mobile-links-flex-order: 4;

$header-burger-flex-order: 1; // hidden by default
$header-logo-flex-order: 2;
$header-search-flex-order: 1;
$header-minicart-flex-order: 3;
$header-links-flex-order: 0;

$header-mobile-links-flex: 0 1 auto;
$header-mobile-logo-flex: 0 1 auto;

$header-tablet-links-flex: 0 1 40%;
$header-tablet-logo-flex: 1;
$header-tablet-search-flex: 0 1 40%;

$header-desktop-links-flex: 0 1 percentage(1/3);
$header-desktop-logo-flex: 1;
$header-desktop-search-flex: 0 1 percentage(1/3);

$header-large-desktop-links-flex: 0 1 40%;
$header-large-desktop-search-flex: 0 1 40%;
$header-large-desktop-logo-flex: 1;

// -------------------------------------------------
// 1. Minicart Variables
// -------------------------------------------------

$minicart-count-position: absolute; // Set the position of the qty count
$minicart-count-color: $grey-dark;
$minicart-count-background-color: none;
$minicart-count-margin-left: 16px; // Margin left of minicart count
$minicart-border-radius: 0.9375em; // equivelant of 15px
$minicart-price: $price-color; // Minicart prices
$minicart-item-spacing: $gutter 0 $forty-gutter; // Space between each list item
$minicart-image-spacing: $twenty-gutter; // Spacing between product image and rest of content (Name, price, etc)
$minicart-count-bubble-inline-margin-left: $ten-gutter;
$minicart-count-bubble-position-left: -23px; // Pull the SVG icon to the left to make way for the circle icon
$minicart-count-bubble-background: $keppel; // Background colour of the QTY count for basket (when set to true)
$minicart-count-bubble-width: 18px; // Background colour of the QTY count for basket (when set to true)
$minicart-count-bubble-height: 18px; // Background colour of the QTY count for basket (when set to true)
$minicart-count-bubble-width-tablet: 20px;
$minicart-count-bubble-height-tablet: 20px;
$minicart-count-bubble-width-desktop: 22px;
$minicart-count-bubble-height-desktop: 22px;
$minicart-count-bubble-top: -5px; // Top position of counter when in bubble
$minicart-count-padding-top: 3px; // Inner spacing (padding) for the top of the counter
$minicart-count-bubble-color: $white; // Text colour of the counter
$minicart-subtotal-spacing: rem(25) 0 $twenty-gutter 0;
$minicart-extra-action-spacing: $item-gutter;
$minicart-paypal-container-background-color: $white;
$minicart-qty-upate-position: em(50);
$minicart-product-item-details-margin: auto 0 auto 0;

//Minicart Express Payment Variables
$minicart-express-payment-border: 1px solid $border-color;
$minicart-express-payment-options-background: $white;
$minicart-express-payment-options-hide-outer-border: true;
// if true + style divider with only inside lines
// if false classic box divider covering all sides
$minicart-express-payment-options-gutter: $twenty-gutter $forty-gutter;

// -------------------------------------------------
// 5. Menu Styles
// -------------------------------------------------

$desktop-nav-background-color: $keppel; // Background colour of nav bar
$desktop-nav-link-color: $white; // Link colour
$desktop-nav-link-hover: $white; // Link colour on hover
$desktop-nav-link-weight: $font-weight-medium;
$desktop-nav-text-align: center; // Nav bar text alignment
$desktop-nav-items-spacing: 0 $twenty-gutter; // Spacing (padding) between each menu item

$mega-menu-background-color: $mercury;
$mega-menu-title-color: $black;
$mega-menu-link-color: $body-font-color;

$mobile-nav-background-color: $white; // Background colour of mobile menu
$mobile-nav-link-color: $body-font-color;
$mobile-nav-link-hover: $body-font-color;
$mobile-nav-text-align: left;
$mobile-nav-padding: 0 $small-gutter; // Padding for menu items container
$mobile-nav-item-border-bottom: 1px solid $grey-light; // Bottom border below each nav item
$mobile-nav-item-padding: $small-gutter 0; // Padding for nav items

// Mobile nav header styles
$mobile-nav-header-padding: $small-gutter; // Padding around the mobile menu header
$mobile-nav-header-border-bottom: 1px solid $grey-light; // Border bottom for mobile nav header

// Mobile nav footer styles
$mobile-nav-footer-background-color: $gainsboro; // Mobile nav footer background colour
$mobile-nav-footer-padding: $ten-gutter 0; // Padding for mobile nav footer
$mobile-nav-footer-currency-border: 2px solid $mine-shaft; // Menu footer currency border
$mobile-nav-footer-currency-width: 24px; // Menu footer currency width
$mobile-nav-footer-currency-height: 24px; // Menu footer currency height
$mobile-nav-footer-currency-padding-top: 1px; // Padding top of currency symbol
$mobile-nav-footer-currency-dropdown-padding: $ten-gutter 0 0; // Padding for currency switcher dropdown
$mobile-nav-footer-currency-item-padding: $ten-gutter 0 0; // Padding for each currency switcher option
$mobile-nav-footer-currency-selected-color: $brand-accent; // Colour for currently selected currency
$mobile-nav-footer-currency-selected-weight: $font-weight-bold; // Font weight for currently selected currency
$mobile-nav-footer-account-icon-width: 17px;
$mobile-nav-footer-account-icon-height: 18px;

// -------------------------------------------------
// 6. Added to basket popup styles
// -------------------------------------------------

$basket-popup-inner-model-spacing-mobile: $twenty-gutter * 2 auto auto auto; // postions the popup using margin-top
$basket-popup-inner-model-spacing: $twenty-gutter * 3.5 auto;
$basket-popup-inner-model-max-width: em(660); // usually slightly smaller than other popup models
$basket-popup-image-overlay-color: rgba($brand-base, 0.8);
$basket-popup-model-header-spacing: $twenty-gutter 0; // contains the close button usually set to position absolute
$basket-popup-product-details: $forty-gutter; // padding for text content covering the product image
$basket-popup-product-details-color: $white;
$basket-popup-product-info-container-display-setting: none; // contains: price, qty and duplicate name.
$basket-popup-detail-message-spacing: 0 0 $twenty-gutter 0;
$basket-popup-detail-title-spacing: 0 0 0 $item-gutter;
$basket-popup-detail-name-spacing: 0 0 $twenty-gutter 0;
$basket-popup-detail-qty-spacing: 0 0 $twenty-gutter 0;
$basket-popup-actions-container-spacing: $gutter $twenty-gutter;
$basket-popup-secondary-actions-container-spacing: rem(23) $twenty-gutter rem(25);
$basket-popup-proceed-buttons-spacing: 0 0 $ten-gutter 0;
$basket-popup-proceed-buttons-flex-grow: 1; // proceed to checkout and continue shopping btns
$basket-popup-proceed-buttons-flex-shrink: 0; // proceed to checkout and continue shopping btns
$basket-popup-proceed-buttons-flex-basis: 100%; // proceed to checkout and continue shopping btns
$basket-popup-proceed-button-width: 100%;
$basket-popup-detail-name-display: none; // name of item
$basket-popup-detail-qty-display: none; // qty of items
$basket-popup-detail-price-display: none; // price of item/items
$basket-popup-background-color: white;

// -------------------------------------------------
// 7. "Great For" Banners - Styles in _banners.scss
// -------------------------------------------------

$banner-great-for-spacing: $twenty-gutter; // Spacing (padding) for the content area
$banner-great-for-mobile-spacing: $small-gutter; // Spacing (padding) for the content area
$banner-great-for-color: $white; // Font color of text
$banner-great-for-weight: $font-weight-bold; // Font weight of text
$banner-great-for-letter-spacing: 0.06em;
$banner-great-for-button-width: em(140); // Width of button
$banner-great-for-button-spacing-top: $small-gutter;

// -------------------------------------------------
// 8. Brands Banners - Styles in _banners.scss
// -------------------------------------------------

$banner-brands-background-color: $concrete;
$banner-brands-inner-spacing: $forty-gutter;
$banner-brands-outer-horizontal-spacing: $fifty-gutter / 2;
$banner-brands-mobile-display: none;
$banner-brands-desktop-display: block;

// -------------------------------------------------
// 9. Out Story - Styles in _banners.scss
// -------------------------------------------------

$banner-our-story-header-color: $brand-base;
$banner-our-story-header-weight: $font-weight-bold;
$banner-our-story-background-color: $mercury;
$banner-our-story-header-outer-vertical-spacing: $twenty-gutter;
$banner-our-story-inner-spacing: em(70);
$banner-our-story-button-spacing-vertical: $twenty-gutter;
$banner-our-story-mobile-display: none;
$banner-our-story-desktop-display: block;
$banner-our-story-body-max-width: em(1163); // Max width of the our story body content

// -------------------------------------------------
// 9. Footer - Styles in _footer.scss
// -------------------------------------------------

// Footer row one
$footer-row-one-background-color: $concrete;
$footer-row-one-header-color: $body-font-color;
$footer-row-one-header-weight: $font-weight-bold;
$footer-row-one-padding: $twenty-gutter;
$footer-row-one-desktop-padding-top: $fifty-gutter;
$footer-row-one-desktop-padding-bottom: rem(70);
$footer-row-one-header-margin-bottom: $twenty-gutter;
$footer-row-one-content-margin-top: $twenty-gutter;

// Newsletter Block
$footer-col-newsletter-mobile-display: none;
$footer-col-newsletter-desktop-display: block;
$footer-col-newsletter-flex: 0 1 100%;
$footer-col-newsletter-flex-desktop: 0 1 50%;
$footer-col-newsletter-enabled-on-mobile: false; // Set this to true if the newsletter is set to display on mobile
$footer-col-newsletter-input-flex: auto;
$footer-col-newsletter-arrow-right-position: $small-gutter;
$footer-col-newsletter-form-padding: 0;
$footer-col-reviews-mobile-margin-top: $twenty-gutter;
$footer-col-reviews-mobile-padding-top: $twenty-gutter;
$footer-col-divider-border: 1px solid $gray; // Dividing line between newsletter and reviews sections
$footer-col-newsletter-description-color: $body-font-color;
$footer-col-newsletter-description-margin-bottom: $twenty-gutter; // Margin below the newsletter desc
$footer-col-newsletter-description-display: none; // Mobile display for footer description
$footer-col-newsletter-description-display-desktop: block; // Desktop display for footer description

// Footer row main
$footer-row-main-background-color: $mercury;
$footer-row-main-padding: $row-gutter $container-gutter-mobile;
$footer-row-main-padding-desktop: $row-gutter $container-gutter;

// Useful Info Block
$footer-col-useful-info-flex: 1 1 100%;
$footer-col-useful-info-flex-tablet: 1 1 30%;
$footer-col-useful-info-flex-desktop: 1 1 30%;

// Need Help Block
$footer-col-help-flex: 1 1 100%;
$footer-col-help-flex-tablet: 1 1 30%;
$footer-col-help-flex-desktop: 1 1 30%;

// Additional info block (Social links, copyright, etc)
$footer-col-additional-info-flex: 1 1 100%;
$footer-col-additional-info-flex-tablet: 1 1 20%;
$footer-col-additional-info-flex-desktop: 1 1 30%;

$footer-accordion-background-color: $concrete;
$footer-accordion-border: 1px solid $concrete;
$footer-accordion-padding-top: $twenty-gutter;
$footer-accordion-padding-bottom: $twenty-gutter;
$footer-accordion-header-color: $body-font-color;
$footer-accordion-header-weight: $font-weight-bold;
$footer-accordion-icon-expand: plus;
$footer-accordion-icon-collapse: minus;
$footer-accordion-useful-info-padding-bottom: $twenty-gutter;

// Form Variables
$form-max-width: em(800);
