/* -------------------------------------------------
 * -------------------------------------------------
 * Base Input styles
 *
 * All border and background colors should be set in _theme-variables.scss.
 * Not here
 *
 * Expected Usage:
 *	input {
 *		@extends %input-style-one;
 *	}
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Primary input, used globally for most inputs
// -------------------------------------------------

@mixin input-style-one(
	$border: $field-border,
	$background-color: $field-background-color,
	$color: $field-color,
	$border-radius: $field-border-radius, // For Safari, it seems to add a radius.
	$padding: $field-padding
) {
	@include type__13(
		$color: $field-color,
		$font-weight: $font-weight-normal
	);
	border: $border;
	background-color: $background-color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;
	width: 100%;

	&::placeholder {
		@include type__13(
			$color: $field-placeholder-color,
			$font-weight: $font-weight-normal
		);
		font-style: italic;
	}

	&:hover,
	&:active {
		border: $field-border-hover;
	}

	&:focus {
		border: $field-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Secondary input, used for search & other inputs
// -------------------------------------------------

@mixin input-style-two(
	$border: $field-two-border,
	$background-color: $field-two-background-color,
	$color: $field-two-color,
	$border-radius: $field-two-border-radius, // For Safari, it seems to add a radius.
	$padding: $field-two-padding,
) {
	@include type__13(
		$color: $field-two-color,
		$font-weight: $font-weight-normal
	);
	border: $border;
	background-color: $background-color;
	color: $color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;

	&::placeholder {
		@include type__13(
			$color: $field-two-placeholder-color,
			$font-weight: $field-two-placeholder-weight
		);
		font-style: $field-two-placeholder-font-style;
		opacity: 1;
		line-height: normal;
	}

	&:hover,
	&:active {
		border: $field-two-border-hover;
	}

	&:focus {
		border: $field-two-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Used for QTY fields
// -------------------------------------------------

@mixin input-style-three(
	$border: $field-three-border,
	$background-color: $field-three-background-color,
	$border-radius: $field-three-border-radius,
	$padding: $field-three-padding,
	$width: $field-three-width
) {
	@include type__16(
		$font-weight: $font-weight-normal,
		$color: $field-three-color
	);
	border: $border;
	background-color: $background-color;
	border-radius: $border-radius;
	padding: $padding;
	width: $field-three-width;
	text-align: center;

	&:placeholder {
		@include type__16;
	}

	&:hover,
	&:active {
		border: $field-three-border-hover;
	}

	&:focus {
		border: $field-three-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Global select style
// -------------------------------------------------

// Default select style
@mixin select-style-one(
	$border: $select-border,
	$background-color: $select-background-color,
	$color: $select-color,
	$border-radius: $select-border-radius, // For Safari, it seems to add a radius.
	$padding: $select-padding,
) {
	@include type__13(
		$color: $color,
		$font-weight: $select-weight
	);
	-webkit-appearance: none;
	width: 100%;
	border: $select-border;
	background-color: $select-background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-arrow-background-size
	);
	background-position: $select-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $select-border-radius;
	padding: $select-padding;

	&:placeholder {
		@include type__14;
	}

	&:hover,
	&:active {
		border: $field-border-hover;
	}

	&:focus {
		border: $field-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// Select style used on layered nav items
// Note, this style may need to be applied to an element that isn't select (i.e. a div)
@mixin select-style-two(
	$border-top: $select-two-border-top,
	$border-bottom: $select-two-border-bottom,
	$border-left: $select-two-border-left,
	$border-right: $select-two-border-right,
	$background-color: $select-two-background-color,
	$color: $select-two-color,
	$border-radius: $select-two-border-radius, // For Safari, it seems to add a radius.
	$padding: $select-two-padding,
) {
	@include type__14(
		$color: $color,
		$font-weight: $select-two-weight
	);
	-webkit-appearance: none;
	width: 100%;
	border-top: $select-two-border-top;
	border-bottom: $select-two-border-bottom;
	border-left: $select-two-border-left;
	border-right: $select-two-border-right;
	background-color: $select-two-background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-two-arrow-background-size
	);
	background-position: $select-two-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $select-two-border-radius;
	padding: $select-two-padding;

	&:placeholder {
		@include type__14;
	}
}

// Select style used on category toolbars
@mixin select-style-three(
	$border: $select-three-border,
	$background-color: $select-three-background-color,
	$color: $select-three-color,
	$border-radius: $select-three-border-radius,
	$padding: $select-three-padding
) {
	@include type__14(
		$color: $color,
		$font-weight: $select-three-weight
	);
	-webkit-appearance: none;
	width: 100%;
	background-color: $background-color;
	border: $border;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-three-arrow-background-size
	);
	background-position: $select-three-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $border-radius;
	padding: $padding;
}

// -------------------------------------------------
// Button on success page and yotpo
// -------------------------------------------------

%input-type-button {
	// used button styles instead as the input type is submit
	@include button__default;
}

// -------------------------------------------------
// Apply input styles
// -------------------------------------------------

input,
select {
	outline: 0;

	&:focus,
	&:active {
		outline: 0;
	}

	&::-ms-clear {
		display: none;
	}
}

input {
	@include input-style-one;
}

select {
	// Additional styles for select
	@include select-style-one;
	appearance: none;
	cursor: pointer;
}

textarea {
	@include input-style-one;
}

.password-strength-meter {
	background-color: $form-hints-background;
	text-align: right;
	padding: $item-gutter / 2;
}

// -------------------------------------------------
// QTY Fields
// -------------------------------------------------

.field.qty {
	label[for*="qty"] {
		@extend %visuallyhidden;
	}

	input.qty {
		@include input-style-three;
	}
}

// input type number used for qty in basket
// the arrows need to be removed as the number cannot be align center if they exist
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
 	 margin: 0;
}

// Firefox specific
input[type=number] {
	-moz-appearance: textfield !important; // Remove increment arrows
}

// -------------------------------------------------
// Remove dotted lines - Important because we never
// Want to show them
// -------------------------------------------------

 input {
	&:focus {
		outline: none !important;
	}

	&::-moz-focus-inner {
		border: 0 !important;
	}
}

// -------------------------------------------------
// Radio buttons
// -------------------------------------------------

%radio-unchecked {
	&:before {
		@include svg-background(
			$name: "radio-deselected",
			$size: contain
		);
		content: "";
		cursor: pointer;
		display: inline-block;
		width: $radio-width;
		height: $radio-height;
		margin-right: $radio-margin-right;
		vertical-align: middle;
	}
}

%radio-checked {
	&:before {
		@include svg-background(
			$name: "radio-selected",
			$size: contain
		);
	}
}

input[type="radio"] {
	display: none;
}

input[type="radio"]+label {
	@extend %radio-unchecked;
	cursor: pointer;
}

input[type="radio"]+td:nth-child(2) {
	@extend %radio-unchecked;
	cursor: pointer;
}

input[type="radio"]:checked+label {
	@extend %radio-checked;
}

input[type="radio"]:checked:disabled+label {
	@extend %radio-checked;
	cursor: not-allowed;

	&:before {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

table {
	input[type="radio"]+td:nth-child(2) {
		@extend %radio-checked;
		cursor: pointer;
	}

	input[type="radio"]:checked+td:nth-child(2) {
		@extend %radio-checked;
		cursor: pointer;
	}
}

// -------------------------------------------------
// Checkboxes
// -------------------------------------------------

%checkbox-unchecked {
	// TODO: Change input silent classes to mixins so new SVGs, heights and widths can be passed in
	&:before {
		content: "";
		display: inline-block;
		cursor: pointer;
		@include svg-background(
			$name: unchecked-box,
			$size: contain
		);
		margin-right: $checkbox-margin-right;
		height: $checkbox-width;
		width: $checkbox-height;
		vertical-align: middle;
	}
}

%checkbox-checked {
	&:before {
		@include svg-background("checked-box");
	}
}

input[type="checkbox"] {
	display: none;
}

input[type="checkbox"]+.col-method {
	@extend %checkbox-unchecked;
	cursor: pointer;
	display: table;
}

input[type="checkbox"]+label {
	@extend %checkbox-unchecked;
	cursor: pointer;
	display: table;
}
input[type="checkbox"]+span {
	@extend %checkbox-unchecked;
	cursor: pointer;
	display: table;
}

input[type="checkbox"]+label span {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 46px);
}

input[type="checkbox"]:checked+label {
	@extend %checkbox-checked;
}

input[type="checkbox"]:checked:disabled+label {
	@extend %checkbox-checked;
	cursor: not-allowed;

	&:before {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

input[type="checkbox"]:checked+span {
	@extend %checkbox-checked;
}

// -------------------------------------------------
// Required input asterisk
// -------------------------------------------------

%required-input-after {
	content: "*";
	display: inline-block;
	color: $field-required-input-color;
	margin-left: 0;
	padding-left: $item-gutter / 2;
}

// -------------------------------------------------
// Placeholder pseudo element
// -------------------------------------------------

::placeholder {
	@include type__16;
}

// --------------------------------------------------
// remove ie11 default select arrow icon
// --------------------------------------------------

select::-ms-expand {
    display: none;
}
