/* -------------------------------------------------
 * -------------------------------------------------
 * Breadcrumbs
 *
 * -------------------------------------------------
*/

.breadcrumbs {
	display: $breadcrumbs-display;
	background-color: $breadcrumbs-background-color;
	border-bottom: $breadcrumbs-border-bottom;
	min-height: $breadcrumbs-min-height;
	padding: $breadcrumbs-padding;

	@include min-screen($screen__m) {
		display: $breadcrumbs-display-desktop;
		padding: $breadcrumbs-padding-desktop;
	}

	li {
		display: inline-block;

		&:after {
			content: "";
			@include svg-background(
				$name: arrow-right-white,
				$size: 100%
			);
			width: 7px;
			height: 12px;
			display: inline-block;
			margin: 0 $twenty-gutter / 2;
			vertical-align: middle;
			margin-top: 1px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		// strong marks the current page but added font weight is usually not required
		a,
		strong {
			@include type__12($color: $white);
		}
	}
}
