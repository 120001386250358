/* -------------------------------------------------
 * -------------------------------------------------
 * Customer account dropdown
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.customer-welcome,
.header-link,
.block-search {
	// the first li in header links
	display: flex;
	height: 100%;

	&:hover {
		cursor: pointer;
	}

	.customer-name,
	.header-link-button,
	.search-toggle {
		// span containing account button
		display: flex; // needed as the button is this span then in an li and a ul so vertical aligning will not work any other way
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;

		@if $header-links-flex-direction == 'row' {
			flex-direction: column;

			@include min-screen($screen__xxl) {
				flex-direction: row;
			}
		} @else {
			flex-direction: column;
			justify-content: center;
		}

		&.active {
			@if $dropdown-enable-triangle == true {
				&:before {
					@include css-triangle(
						$triangle-size: 7px,
						$triangle-color: $dropdown-arrow-colour,
						$triangle-direction: bottom
					);
				}
			}
		}

		button {
			display: block;
			opacity: 1;
			padding: 0;
			line-height: inherit;
			border: 0;
			background: none;

			&:hover,
			&:focus {
				border: none;
				outline: 0;
				background: none;
			}

			svg {
				margin: 0 auto;
			}
		}
	}

	.header.links {
		display: flex;
		flex-direction: column;

		.authorization-link {
			order: 8;
		}

		li,
		li span {
			@include type__13;
			padding-top: $dropdown-list-spacing;
			display: block !important; // Important to override inline style from pagebuilder

			&:first-child {
				order: 8;
			}
		}
	}

	// actual svg to open menu
	.account-open,
	.header-link-open {
		display: block;

		.st0 {
			fill: $svg-icon-color;
			stroke: $svg-icon-color;
		}
	}

	.help-center-open {
		.st0 {
			fill: none;
		}
	}

	// actual svg to close menu
	.account-close,
	.header-link-close {
		display: none;

		.st0 {
			// not texhnically a hover but swaps to this when interacted with so close enough...
			fill: $svg-icon-color-hover;
		}
	}

	&.active {
		z-index: 150;
		background-color: $header-links-active-background; // actual svg to open menu
	}

	&.active {
		.customer-menu {
			display: block;
		}
	}
}

.customer-welcome {
	display: none;

	@include min-screen($screen__m) {
		display: flex;
	}
}

.customer-help-center {
	display: none;

	@include min-screen($screen__ll) {
		display: flex;
	}
}

.header-phone-link-title {
	display: none;

	@include min-screen($screen__m) {
		display: block;
	}
}
