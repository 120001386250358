/* -------------------------------------------------
 * -------------------------------------------------
 * How many products in the basket
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.minicart-wrapper {
	margin-right: $ten-gutter;

	@include min-screen($screen__m) {
		margin-right: 0;
	}

	.minicart-container {
		position: relative;
	}

	.action.showcart {
		span.counter.qty {
			@include type__12(
				$line-height: 1.47 // Custom line height to line up counter number in bubble
			);
			position: absolute;
			margin-left: $minicart-count-margin-left;
			text-align: center;
			top: 15px;
			right: -9px;

			@include min-screen($screen__m) {
				top: 14px;
				right: 0;
			}

			@include min-screen($screen__xxl) {
				@include type__14(
					$line-height: 1.30 // Custom line height to line up counter number in bubble
				);
			}

			@if $header-links-flex-direction == row {
				@include min-screen($screen__xxl) {
					position: static;
					margin-left: $minicart-count-bubble-inline-margin-left;
				}
			}

			.counter-number {
				display: block; //needed for positioning to work
				background: $minicart-count-bubble-background;
				border-radius: 100px;
				width: $minicart-count-bubble-width;
				height: $minicart-count-bubble-height;
				padding-top: $minicart-count-padding-top;
				color: $minicart-count-bubble-color;
				display: block;

				@include min-screen($screen__m) {
					width: $minicart-count-bubble-width-tablet;
					height: $minicart-count-bubble-height-tablet;
				}

				@include min-screen($screen__xxl) {
					width: $minicart-count-bubble-width-desktop;
					height: $minicart-count-bubble-height-desktop;
				}
			}

			.counter-label {
				@extend %visuallyhidden;
			}
		}
	}

	.loading-mask {
		border-radius: 9999px;
		width: 100%;

		.loader {
			background-image: none;
			height: auto;
			left: 0;
			position: absolute;
			top: 0;
			width: auto;

			img {
				left: 0;
			}
		}
	}
}
