/* -------------------------------------------------
 * -------------------------------------------------
 * Fonts
 * Previously injected via Typekit, now serving them
 * "locally" to minimize the FOUT.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins-regular-webfont.woff2') format('woff2');
	font-display: swap;
	font-stretch: normal;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins-medium-webfont.woff2') format('woff2');
	font-display: swap;
	font-stretch: normal;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins-semibold-webfont.woff2') format('woff2');
	font-display: swap;
	font-stretch: normal;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'poppins';
	src: url('../fonts/poppins-bold-webfont.woff2') format('woff2');
    font-display: swap;
	font-stretch: normal;
    font-style: normal;
	font-weight: 700;
}

@font-face {
    font-family: 'poppins';
	src: url('../fonts/poppins-extrabold-webfont.woff2') format('woff2');
    font-display: swap;
	font-stretch: normal;
    font-style: normal;
	font-weight: 800;
}

