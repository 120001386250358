/* -------------------------------------------------
 * -------------------------------------------------
 * Header Styling
 * -------------------------------------------------
 * @author Fisheye
 */

.action.skip.contentarea {
	@extend %visuallyhidden;
}

// -------------------------------------------------
// Page Header Container
// -------------------------------------------------

.page-header {
	background-color: $header-background;
	width: 100%;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;

	@include min-screen($screen__m) {
		display: block;
		position: relative;
	}

	&.fixed {
		position: fixed;
	}
}

// -------------------------------------------------
// Header Message Panel (Need Help, etc)
// -------------------------------------------------

.page-header {
	.modal-overlay.header-links-overlay:not(.modal-overlay.active) + .panel.wrapper {
		@if $header-above-overlay == true {
			z-index: 100;
		}
	}

	.panel.wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		background-color: $header-panel-background;
		text-align: center;
		height: $header-panel-height;
		position: relative;
		text-transform: uppercase;

		p {
			@include type__13(
				$color: $header-panel-color,
				$font-weight: $font-weight-bold
			);

			@include min-screen($screen__s) {
				@include type__15(
					$color: $header-panel-color
				);
			}
		}

		.panel.header {
			@include min-screen($screen__m) {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

	.vat-switcher {
		/* Don't show this while the page is loading */
		display: none;

		@include min-screen($screen__m) {
			display: flex;
		}
	}
}

// -------------------------------------------------
// Header Content
// -------------------------------------------------

.modal-overlay.header-links-overlay:not(.modal-overlay.active) + .header.content {
	@if $header-above-overlay == true {
		z-index: 100;
	}
}

.header.content {
	display: flex;
	flex-flow: row wrap;
	justify-content: $header-mobile-justify-content;
	height: $header-mobile-content-height;
	background-color: $header-mobile-background;
	padding: $header-content-padding;
	position: relative;

	@include min-screen($screen__m) {
		height: $header-desktop-content-height;
		background-color: $brand-base;
		padding: 0 $tablet-header-content-right-spacing 0 $tablet-header-content-left-spacing;
	}

	@include min-screen($screen__ml) {
		padding: 0 $header-desktop-content-right-spacing 0 $header-desktop-content-left-spacing;
	}

	.block {
		// Cancels out margin added to blocks in _extends
		// block is also used in account area so don't want additional margins from that either
		margin: 0;
	}

	// -------------------------------------------------
	// All the icons first so they are the same width
	.navigation-toggle,
	.block-search,
	.navigation-toggle {
		display: flex;
		flex: $header-mobile-links-flex;
		justify-content: center;
		align-items: center;
		padding: 0;

		@include min-screen($screen__l) {
			flex: 1 1 4%;
		}
	}

	.block-search {
		order: $header-mobile-search-flex-order;
		justify-content: flex-end;
		margin-left: auto;

		@include min-screen($screen__m) {
			display: flex;
			order: $header-search-flex-order;
			flex: $header-tablet-search-flex;
			justify-content: flex-start;
		}

		@include min-screen($screen__l) {
			flex: $header-desktop-search-flex;
		}

		@include min-screen($screen__xl) {
			flex: $header-large-desktop-search-flex;
		}

		form.minisearch {
			padding: $twenty-gutter;

			@include min-screen($screen__m) {
				padding: 0;
			}

			.input-text {
				@include input-style-two;
				padding-right: $minisearch-input-padding-right;
			}
		}

		.search-toggle {
			background: none;
			border: 0;
			height: 100%;
			flex-direction: row;

			svg {
				fill: $header-icons-color;
			}
		}

		.block-title,
		.label {
			@extend %visuallyhidden;
		}

		.block-content {
			width: 100%;
			max-width: rem(250);
			height: 100%;

			@include min-screen($screen__m) {
				height: auto;
			}

			@include min-screen($screen__ll) {
				max-width: rem(340);
			}

			@include min-screen(1610px) {
				max-width: rem(468);
			}

			&.active {
				z-index: 150;
			}
		}

		.actions {
			position: absolute;
			top: 0;
			right: $header-search-icon-right-position;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;

			@include min-screen($screen__m) {
				right: $header-search-icon-right-position-desktop;
			}
		}

		.action {
			@extend %cancel-button;
			cursor: pointer;
			padding: 0;

			svg {
				fill: $header-search-icon-color;
				background-color: $header-search-icon-background-color;
				width: $header-search-icon-width;
				height: $header-search-icon-height;
			}
		}
	}

	.icons-block {
		order: $header-mobile-minicart-flex-order;
		display: flex;
		justify-content: flex-end;
		flex: $header-mobile-links-flex;
		align-items: center;
		padding: 0;

		@include min-screen($screen__m) {
			flex: $header-tablet-links-flex;
		}

		@include min-screen($screen__xl) {
			flex: $header-large-desktop-links-flex;
			order: $header-minicart-flex-order;
		}
	}

	.icons-block,
	.minicart-wrapper,
	.block-search,
	.block-content {
		.search-toggle {
			display: none;

			@include min-screen($screen__m) {
				display: none !important; // Overrides dropdown component inline value on desktop
			}
		}

		&.active {
			.block-minicart,
			.minisearch,
			.search-toggle {
				display: block;
			}
		}

		.block-minicart,
		.customer-menu,
		.header-link-menu,
		.minisearch {
			display: none; // stops basket showing on load
			position: absolute;
			background-color: $dropdown-background-color;
			z-index: 0; // needed to position open minicart on top of overlay on IE.
			right: 0;
			width: 100%;
			margin: 0 auto;
			box-shadow: none;
			border: none;
			padding: $dropdown-outer-spacing;

			&:after {
				display: none;
			}

			&:before {
				display: none;
			}

			@include min-screen($screen__m) {
				top: $dropdown-top-position;
				width: $dropdown-width;
			}

			.action-close {
				// close button inside popup
				&:hover,
				&:focus {
					background-color: $popup-background-color;
					color: currentColor;
					outline: none;
				}
			}
		}

		.minisearch {
			@include min-screen($screen__m) {
				position: relative;
				width: auto;
				top: auto;
				background: none;
			}

			.mage-dropdown-dialog {
				@include min-screen($screen__m) {
					display: block !important; // Important to override JS display style
				}
			}

			.header-links-overlay.active ~ & {
				z-index: 100;

				.modal-open & {
					z-index: 99;
				}
			}

			.modal-open & {
				z-index: 99;
			}
		}

		.block-title,
		.header.links {
			padding: $dropdown-content-padding;
		}

		.header.links.trade {
			&:empty {
				display: none;
			}
		}

		.block-content > div {
			// this determines whether to use padding or margin for gutter, the reason for this is if we use margin over padding the dividing line will be indented and in line with
			// the rest of the minicart contents, vice versa means the dividing line is the full width of the container.
			@if $dropdown-dividing-line-full-width == false {
				margin-left: $dropdown-padding;
				margin-right: $dropdown-padding;
				width: auto;
			}

			@else {
				padding-left: $dropdown-padding;
				padding-right: $dropdown-padding;
			}
		}

		.block-content {
			strong.subtitle.empty {
				display: block;
				margin: $gutter;
			}

			strong {
				span {
					@include type__16($font-weight: $font-weight-normal);
				}
			}
		}

		.block-title {
			margin: 0; //remove any inherited margin

			@if $dropdown-dividing-line-full-width == false {
				//indented diving line
				margin: $dropdown-padding;
				padding: $dropdown-title-padding;
				margin-bottom: $block-title-margin-bottom;
			}

			@else {
				//full width dividing line
				padding: $dropdown-title-padding;
			}
		}

		.greet.welcome,
		.block-title,
		.header-link-header-title {
			border-bottom: $dropdown-title-border;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $dropdown-title-padding;

			.welcome-back,
			.customer-firstname,
			.header-link-header-text,
			.text span {
				@include type__20(
					$color: $dusty-gray,
					$font-weight: $font-weight-bold
				);
				text-transform: capitalize;
				display: inline-block;
			}

			.customer-firstname {
				margin-left: em(2);
				color: $account-dropdown-username-color;
			}

			button {
				@extend %cancel-button;
				padding: 0;
			}

			.action span span,
			.greet-welcome-close span,
			.header-link-close-action span {
				@include action-w-icon__cross-white($color: $ebony-clay);
			}

			.items-total {
				@extend %visuallyhidden;
			}
		}

		.greet.welcome,
		.header.links {
			&.trade {
				background-color: $gainsboro;
			}
		}

		> .header.links {
			display: none;
			align-items: center;
			height: 100%;

			@include min-screen($header-links-display-breakpoint) {
				display: flex;
			}

			@if $header-links-flex-direction == 'row' {
				flex-direction: row;
			} @else {
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	.block-search {
		.block-content > div {
			@include min-screen($screen__m) {
				padding: 0;
				display: block !important // Override inline JS display style
			}
		}
	}

	.header.items > li,
	.minicart-wrapper,
	.switcher-currency,
	.icons-block > .header.links {
		border-left: $header-mobile-border;
		padding: $header-links-padding;
		height: 100%;
		margin-left: $twenty-gutter;

		@include min-screen($screen__m) {
			width: $header-links-tablet-width;
			border-left: $header-border;
			padding: $header-links-padding-desktop;
			margin-left: $twenty-gutter;
		}

		@include min-screen($screen__ml) {
			position: relative;
			width: $header-links-desktop-width;
			padding: $header-links-padding-desktop;
			margin-left: $forty-gutter;
		}

		@include min-screen($screen__xxl) {
			margin-left: $twenty-gutter;
		}

		@include min-screen(1720px) {
			margin-left: $forty-gutter;
		}

		svg {
			height: $header-icons-height;
			fill: $header-icons-color;
		}

		&.active {
			.currency-name,
			.minicart-title,
			.customer-title,
			.quickorder-title,
			.header-link-title {
				color: $header-links-label-color-active;
			}

			svg {
				fill: $header-icons-color-active;
			}
		}

		.currency-name,
		.minicart-title,
		.customer-title,
		.quickorder-title,
		.header-link-title {
			@include type__13(
				$color: $header-links-label-color,
				$font-weight: $font-weight-semibold
			);

			@if $header-links-flex-direction == 'row' {
				padding-left: $header-links-label-horizontal-spacing;
			} @else {
				padding-top: $header-links-label-horizontal-spacing;
			}
		}
	}

	.header.items {
		order: $header-mobile-links-flex-order;
		display: flex;
		align-items: center;
		margin-left: $ten-gutter;
		justify-content: flex-start;

		@include min-screen($screen__m) {
			align-items: center;
			height: 100%;
			order: $header-links-flex-order;
			margin-left: 0;
		}
	}

	.switcher-currency {
		display: none;
		position: relative;

		@include min-screen($screen__m) {
			display: block;
		}

		.currency-symbol {
			border: $header-currency-icon-border;
			color: $header-currency-icon-color;
			border-radius: 200px;
			width: $header-icons-width;
			height: $header-icons-height;
			text-align: center;
			display: inline-block;
			padding-top: $header-currency-icon-spacing-top;
		}

		.switcher-dropdown {
			display: none;
			margin: 0 auto;
		}

		.switcher-options,
		.switcher-trigger {
			position: relative;
			height: 100%;
			cursor: pointer;

			&.active {
				z-index: 150;
				background-color: $header-links-active-background;

				@if $dropdown-enable-triangle == true {
					&:before {
						@include css-triangle(
							$triangle-size: 7px,
							$triangle-color: $dropdown-arrow-colour,
							$triangle-direction: bottom
						);
					}
				}
			}
		}

		strong[class^="language-"] {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@if $header-links-flex-direction == 'row' {
				flex-direction: row;
			} @else {
				flex-direction: column;
				justify-content: center;
			}
		}

		.ui-widget {
			display: none;
			position: absolute;
			width: 100%;
			padding: $currency-dropdown-inner-spacing;
			background: $currency-dropdown-background;

			.switcher-option {
				padding-top: $currency-dropdown-item-top-spacing;

				&.current {
					.currency-symbol {
						border: $header-currency-option-active-icon-border;
						color: $header-currency-option-icon-color;
					}

					.currency-name {
						color: $header-currency-option-icon-color;
					}
				}

				.currency-symbol {
					border: $header-currency-option-icon-border;
					margin-right: $header-currency-option-spacing-horizontal;
				}

				a {
					&:focus {
						color: $outer-space;
						opacity: 1;
					}
				}

				&:first-child {
					padding-top: 0;
				}

				span {
					@include type__16;
					display: inline-block;
				}

				&.current {
					span {
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}

	.logo {
		flex: $header-mobile-logo-flex;
		order: $header-mobile-logo-flex-order;
		display: flex;
		align-items: center;
		justify-content: center;

		@include min-screen($screen__m) {
			flex: $header-tablet-logo-flex;
			order: $header-logo-flex-order;
		}

		@include min-screen($screen__l) {
			flex: $header-desktop-logo-flex;
		}

		@include min-screen($screen__xl) {
			flex: $header-large-desktop-logo-flex;
		}

		// Center align the logo - nested because no class on output
		// we do not want this to affect any other links in the header
		a {
			display: block;
			max-width: $logo-mobile-max-width;
			width: 100%;
			margin: 0 auto;
			text-align: center;

			@include min-screen($screen__m) {
				max-width: $logo-tablet-max-width;
				margin: 0;
			}

			@include min-screen($screen__l) {
				max-width: $logo-max-width;
				margin: 0;
			}
		}
	}
}

// -------------------------------------------------
// Navigation Toggle (Mobile)
// -------------------------------------------------

// Set order here for readability, general styles for each are below
.navigation-toggle {
	order: $header-mobile-burger-flex-order;
	border-right: $header-mobile-border;
	background-color: $keppel;
	text-align: center;
	padding: $ten-gutter;

	@include min-screen($screen__m) {
		display: none;
		justify-content: left;
	}

	button {
		@include type__16(
			$color: $white,
			$font-weight: $font-weight-medium
		);
		text-transform: uppercase;
		background: none;
		border: 0;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}

	svg {
		fill: $header-icons-color;
		margin-right: rem(7);
	}

	.menu-close {
		display: none;
	}

	&.nav-open {
		position: relative;
		z-index: 10;

		@if $dropdown-enable-triangle == true {
			&:before {
				@include css-triangle(
					$triangle-size: 5px,
					$triangle-color: $dropdown-arrow-colour,
					$triangle-direction: bottom
				);
			}
		}

		.menu-close {
			display: inline;
		}

		.menu-open {
			display: none;
		}
	}
}

// Ends ordering and positioning header items
// -------------------------------------------------
