/* -------------------------------------------------
 * -------------------------------------------------
 * Table of contents
 * 1. Base
 * 2. Colours
 *	- 2a. Product Colours
 * 3. Responsive Type Settings
 * 4. Grid Settings
 * 5. Header Styles
 * 6. Menu Styles
 * 7. Toolbar Styles
 * 8. Sidebar Styles
 * 9. Footer Styles
 * 10. Basket Styles
 * 11. Category Styles
 * 12. Product Styles
 * 13. Gallery Settings
 * 14. Forms Settings
 * 15. Account Styles
 * 16. Breakpoints
 * 17. Hotspot Settings
 * 18. Message Settings
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Base
// -------------------------------------------------


// Common colours
$white: #fff;
$black: #000;
$grey-lightest: #f1f1f1;
$grey-light: #dddddd;
$grey-medium: #d5d5d5;
$grey-dark: #3e3e3e;
$gray: #818181;

// CORE background colours
$mine-shaft: #333333; // Also used for buttons
$blaze-orange: #FF6000;
$mountain-meadow: #1db954;
$silver: #c7c7c7;
$silver-chalice: #AEAEAE;
$boulder: #7d7b7b;
$concrete: #f3f3f3;
$outer-space: #2e3437;
$red-ribbon: #f01717;
$casablanca: #F5BA4D;
$nobel: #b5b5b5;
$picton-blue: #2BB1EA;
$alabaster: #f7f7f7;

// CORE Border Colours
$mercury: #e4e4e4;

// Error Message Colours (errors, success, etc)
$vanilla-ice: #F8E6E6;
$pearl-lusta: #FCF0DA;
$gray-nurse: #E7EFE7;
$athens-gray: #E5E5ED;
$cerulean-blue: #296baf;
$dell: #2a6118;
$sepia: #694514;
$persian-red: #CF3E33;

// TPM Theme Colours
$vulcan: #141621;
$ebony-clay: #20222f;
$keppel-light: #E7F5F3;
$keppel: #3cb48b;
$charcoal-grey: #3d3f4e;
$mercury: #e7e7e7;
$radical-red: #fb1e5c;
$carnation: #fb4a67;
$dusty-gray: #979797;
$edward: #9ba7a7;
$silver: #c9c9c9;
$wild-sand: #f6f6f6;
$gallery: #eaeaea;
$alto: #d6d6d6;
$gainsboro: #dcdcdc;
$tuna: #373947;

$brand-base: $ebony-clay; // Primary Site Colour
$brand-base-lighten: lighten($brand-base, 20%);
$brand-base-dark: darken($brand-base, 20%);
$brand-accent: $keppel; // Site accent colour, typically used on buttons, labels and prices

$font-family-sans-serif: poppins, sans-serif;;
$font-family-sans-serif-alt: sans-serif;
$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// Sadly need these for variable.scss
// Hopefully can be removed soon...
$base-em: 16px;
$base-font-size: 100%; // typical default browser font-size is 16px
$base-line-height: 1.5;
$font-family-name__base: $font-family-sans-serif !default;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// Global styles
$body-font-color: $ebony-clay;
$body-font-family: $font-family-sans-serif;
$body-font-weight: $font-weight-normal;
$body-font-style: normal;
$header-font-color: $boulder;

$heading-font-color: $boulder;

$icon-font-size: 2.1875rem; // 35px when base is 16. 35 % 16
$icon-font-line-height: 1.875rem; // 30px when base is 16. 30 % 16
$icon-font-color: $grey-dark;
$icon-font-color-focus: $black; // used for focus on inputs, hover everywhere else

// -------------------------------------------------
// 2. Colour Settings
// -------------------------------------------------

$body-background-color: $white;

// for use on full width containers that don't have a white background.
$container-background-color-alt: $grey-lightest;

$popup-background-color: $alto;

$trust-builder-background: $grey-lightest;
$trust-builder-color: $white;

// default borders for divs, containers
$border-color: $charcoal-grey;
$border-color-alt: $ebony-clay; // used instead of $border-color-alt - often a lighter color or opposite
$border-color-alt2: $mercury;

$link-color-default: $body-font-color;
$link-color-hover: $brand-base;
$link-color-visited: $body-font-color;
$link-opacity: 0.65;

$default-overlay-color: rgba(255, 255, 255, 0.8);
$overlay-color-alt: rgba(0, 0, 0, 0.8); // used on basket preview

$focus-color-default: $brand-accent;

// -----------------------------------------------

$price-color: $black;
$sale-price-color: $grey-medium;
$old-price-color: $gray;

$svg-icon-color: $white;
$svg-icon-color-hover: $brand-accent;
$form-hints-background: $grey-lightest;
$form-required-input-icon-color: $radical-red;
$form-notices-color: $radical-red;

$delivery-time-text-color: $brand-base;
$grand-total-text-color: $black;
$grand-total-success-text-color: $brand-base;

// -------------------------------------------------
// 3. Responsive Type Settings
// -------------------------------------------------
// This sets the base font applied to html in _base.scss
// Not currently working and had to put actual values back in base
// It will work one day though!

$min-viewport: 300; // smallest viewport type will scale for
$min-viewport-px: 300px; // smallest viewport with px so calc() works
$max-viewport: 1640; // Largest viewport type will scale for
$min-font-size: 14; // smallest the font will ever display
$min-font-size-px: 14px; // smallest font with px so calc() works
$max-font-size: 16; // largest the font will ever display

// -------------------------------------------------
// 4. Grid Settings
//
// Example Usage:
//
// .page-main {
//		width: $container-width-mobile;
//		max-width: $container-width;
// }
// If $container-width is 100% then website will be full width all of the time
// If $container-width is 1280px / 80em then website will be constrained at 1280px / 80em
// -------------------------------------------------

$container-width-mobile: 100%; // used when container width is a fixed value on desktop so that the mobile can scale
$container-width: 100%; // This can switch between 100% and a px value fixed width to allow certain pages to have a max width as well
$cms-container-width: em(1300); // used for all cms pages as they have bigger guttering

// this breakpoint is used very specifically in the grid system to remove padding from content containers once a  max-width has been reached.
// If a px value has been set for max-width, also add the px value of $gutter below.
// For Example: $container-breakpoint: $container-width + 20px;
// This is because once the screen reaches max-width, if padding was removed then the content would run to the edge of the screen until
// the screen is 20px wider than the max-width. The 20px used here is px equivalent of $gutter below.
// If $gutter changes so should this.
$container-breakpoint: $container-width;

$container-narrow: 900px;
$container-contained-width: 64em; // 1024 / 16 used specifically in _grid

$sidebar-width: 22.5vw;
$sidebar-max-width: 285px;

// This is the base for all grid spacing. All spacing is a sum of this i.e. :
// - Each grid item is $twenty-gutter / 2 so that when next to each they form the correct spacing

// If base $twenty-gutter changes, also change the px value used in $container-breakpoint above to match
$gutter: 1.875rem; // Theme gutter value (30px in CORE) / base font size (16px in CORE) to get rem value

// Numbered spacing variables
$five-gutter: $gutter / 6; // Approx 5px
$ten-gutter: $gutter / 3; // Approx 10px
$fifteen-gutter: $gutter / 2; // Approx 15px
$twenty-gutter: $gutter / 1.5; // Approx 20px
$forty-gutter: $gutter * 1.33; // Approx 40px
$fifty-gutter: $gutter * 1.66; // Approx 50px
$sixty-gutter: $gutter * 2; // Approx 60px

// Spacing
$item-gutter: $ten-gutter; // used on all grid layouts so that total spacing between items is correct
$row-gutter: $gutter; // Gutter applied to top and bottom of rows, approx 30px in CORE
$small-gutter: $fifteen-gutter; // Small gutter, approx 15px in CORE
$heading-gutter: $forty-gutter; // The space used on heading elements in padding, approx 40px in CORE
$heading-gutter-mobile: $gutter; // The space used on heading elements in padding, approx 20px in CORE
$container-gutter: $gutter / 2; // The space between the left and right of the page and the element, approx 30px in CORE
$container-gutter-mobile: $small-gutter / 2; // The space between the left and right of the page and the element on mobile, approx 15px in CORE

// -------------------------------------------------
// 7. Toolbar Styles
// -------------------------------------------------

$toolbar-background-color: $white;
$toolbar-border-color: $grey-medium;
$toolbar-amount-color: $grey-dark;

// -------------------------------------------------
// 8. Sidebar Styles
// -------------------------------------------------

$sidebar-background-color: $grey-lightest;
$sidebar-background-color-alt: $white;
$sidebar-price-slide-color: $brand-accent;
$sidebar-count-color: $body-font-color;

// -------------------------------------------------
// 9. Footer Styles
// -------------------------------------------------

$seo-description-bg: $wild-sand;
$seo-description-border: solid 1px $white;
$seo-description-container-width: em(1235);

$footer-background-color: $grey-lightest; // default bg color in case no rows as such, rows then load over it

$footer-row-company-bg: $wild-sand;
$footer-row-one-bg: $charcoal-grey;
$footer-row-two-bg: $ebony-clay;
$footer-icon-color: $white;
$footer-icon-hover-color: $white;
$footer-icon-font-size: 2.1875rem; // 35px when base is 16. 35 % 16
$footer-icon-font-line-height: 1.875rem; // 30px when base is 16. 30 % 16

$footer-title-color: $keppel;
$footer-link-color: $white;

// -------------------------------------------------
// 11. Category Styles
// -------------------------------------------------

$category-product: $black; // Category page product names
$category-price: $price-color; // Category page prices

$line-through: line-through; // Line through for product prices

$pagination-font-color: $white;
$pagination-background-color: $brand-base;
$pagination-background-color-active: $brand-base-dark;

// -------------------------------------------------
// 12. Product Page Styles
// -------------------------------------------------

$price: $price-color;
$price-label: $price-color;

// allows special and old price to be rearranged
$special-price-order: 2;
$old-price-order: 1;
$product-qty-btn: #bebebe; // QTY Ticker colour

$product-tabs-background: $brand-base;
$product-tabs-background-hover: $brand-accent;
$product-tabs-color: $white;

// -------------------------------------------------
// 13. Product Gallery Styles
// -------------------------------------------------

$gallery-border-color: $brand-accent; // Primarliy used on magnifyer border
$gallery-thumbnail-background-color: $grey-lightest;

$gallery-thumb-border: $brand-accent;
$gallery-dots-color: $alto;
$gallery-dots-color-active: $brand-accent;

// -------------------------------------------------
// 15. Account Styles
// -------------------------------------------------

$account-titles-align: center;

$block-background-color: $white; // used in login blocks and blocks inside top links
$block-background-color-alt: $grey-lightest; // used in login blocks

$account-toplinks-background-color: $white;
$account-top-links-text-align: left;

$account-table-head-color: $white;

$account-name-color: $brand-accent;
$edit-account-area-color: $brand-accent;

// -------------------------------------------------
// 16. Breakpoints
// -------------------------------------------------

$screen__xs: 480px !default;
$screen__s: 640px !default;
$screen__m: 768px !default;
$screen__ml: 992px !default;
$screen__l: 1024px !default;
$screen__ll: 1280px !default;
$screen__xl: 1440px !default;
$screen__xxl: 1460px !default;
$screen__xxxl: 1630px !default;

// -------------------------------------------------
// 17. Hotspot Settings
// -------------------------------------------------

$hotspot-icon-width: em(33);
$hotspot-icon-height: em(33);
$hotspot-text-background-color: rgba(255, 255, 255, 0.9);

// -------------------------------------------------
// 18. Message Settings
// -------------------------------------------------

$error-message-background: $vanilla-ice;
$info-message-background: $athens-gray;
$notice-message-background: $athens-gray;
$success-message-background: $gray-nurse;
$warning-message-background: $pearl-lusta;

$error-message-color: $persian-red;
$info-message-color: $cerulean-blue;
$notice-message-color: $cerulean-blue;
$success-message-color: $dell;
$warning-message-color: $sepia;

$message-max-width: none;

// -------------------------------------------------
// 19. Layout Settings
// -------------------------------------------------

$page-padding: $gutter;
$page-padding-mobile: em(17); // Equivalent to 15px
