/* -------------------------------------------------
 * -------------------------------------------------
 * Product upsells
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.block-products-list,
.block.related,
.block.upsell,
.block-viewed-products {
	padding: $upsells-container-padding;

	@include min-screen($screen__m) {
		padding: $upsells-container-padding-desktop;
	}

	.block-title {
		strong {
			display: block;
			text-align: center;
			text-transform: uppercase;
			padding-bottom: rem(36);
			@include text-crop;

			@include type__22(
				$color: $upsells-title-color,
				$font-weight: $upsells-title-font-weight
			);

			@include min-screen($screen__m) {
				padding-bottom: rem(77);

				@include type__36(
					$color: $upsells-title-color,
					$font-weight: $upsells-title-font-weight
				);
			}
		}
	}

	.block-actions {
		display: none; // remove 'check items to add to basket' text
	}

	.upsell-categories {
		display: none; // remove 'components' text
	}

	.product-items,
	.widget-product-grid {
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
	}

	.product-item {
		flex: 1;
		padding: 0 $upsells-item-padding;
		z-index: 1;

		@include max-screen($screen__xs - 1) {
			display: block !important; // should only be applied to mobile to override inline display: none; on slick slider
		}

		@include min-screen($screen__xs) {
			display: none;

			&:first-child,
			&:nth-child(2) {
				display: block;
			}
		}

		@include min-screen(map-get($product-upsells-breakpoints, 'show-three')) {
			&:nth-child(3) {
				display: block;
			}
		}

		@include min-screen(map-get($product-upsells-breakpoints, 'show-four')) {
			&:nth-child(4) {
				display: block;
			}
		}

		@include min-screen(map-get($product-upsells-breakpoints, 'show-all')) {
			display: block;
		}

		.price-box {
			padding-bottom: 0;
		}
	}

	.field.related {
		display: $upsells-qty-display;
	}

	.product-image-container {
		display: block;
		margin: 0 auto;
	}

	.product-image-photo {
		margin: 0 auto;
	}
}

.block-products-list {
	padding: $small-gutter 0 $gutter;

	@include min-screen($screen__m) {
		padding: $upsells-container-padding-desktop;
	}

	.slick-track {
		display: flex !important; // Override inline style
	}

	.slick-slide {
		height: auto;

		> div {
			height: 100%;
		}
	}

	.product-item {
		padding: $fifteen-gutter rem(12);
		height: 100%;

		@include min-screen($screen__m) {
			height: auto;
		}
	}
}

// -------------------------------------------------
// Recently Viewed Products
// -------------------------------------------------

.block.block-viewed-products {
	background-color: $wild-sand;
	border-bottom: 1px solid $white;
	padding: rem(36) $fifteen-gutter / 2;

	@include min-screen($screen__m) {
		padding: rem(70) $fifteen-gutter;
	}

	.product-item {
		display: flex;
		justify-content: center;
		padding: 0;

		@include max-screen($screen__xs - 1) {
			display: flex !important; // Overrides a style found above, which itself overrides inline styles
		}

		.product-item-info {
			margin: 0 $fifteen-gutter / 2;
			flex: $products-viewed-product-item-flex;

			@include min-screen($screen__m) {
				margin: 0 $fifteen-gutter;
			}

			&:nth-child(n+4) {
				display: none; // Show first three products

				@include min-screen($screen__l) {
					&:nth-child(n+7) {
						display: flex; // Show first four products
					}
				}

				@include min-screen($screen__xxl) {
					display: flex; // Show all products
				}
			}

			.product-photo {
				background-color: $white;
				padding: $ten-gutter $twenty-gutter;
				margin: auto;

				@include min-screen($screen__m) {
					margin: auto;
					padding: $twenty-gutter $forty-gutter;
				}

				&:nth-child(3) {
					margin-right: 0;

					@include min-screen($screen__l) {
						margin-right: $gutter;
					}
				}

				.product-image-photo {
					margin: auto;
					max-height: em(85);

					@include min-screen($screen__m) {
						max-height: em(150);
					}
				}
			}
		}
	}
}
