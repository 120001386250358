// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
// The actual MEGA menu
// -----------------------------------------------------------------------------------
// It should all be in a media query but that would stop the use of silent classes
// So only bits that affect the mobile menu are in break points
// -----------------------------------------------------------------------------------
.page-header {
	// make sure we are more specific than the nasty fallbacks Mwahahahaha!
	// -------------------------------------------------
	// -------------------------------------------------
	// This bit styles general backgrounds, layout etc. only. all type styles remain specific to child themes
	.nav-sections {
		// nested for readability
		.navigation {
			ul.submenu {
				// the ul for the "submenu" populated by Bluefoot - in case you were wondering ;)
				@include min-screen($screen__m) {
					top: 100% !important; // Important to override JS inline styles
					left: 0 !important; // Important to override JS inline styles
					background-color: $mega-menu-background-color;
					width: 100%;
					position: absolute;
					@include transition(background-color 0.3s ease);
					text-align: left; // because top level is aligned centre
					padding: $gutter;
					z-index: 100;
				}

				>li {
					width: $container-width-mobile;
					max-width: $container-width;
					margin: 0 auto;
				}

				.row {
					padding-bottom: 0; // cancels out the padding added to rows for banners
				}

				.menu-default .category-listing {
					.pagebuilder-buttons {
						column-count: 3;
					}
				}

				.award-type-container {
					.pagebuilder-column {
						flex: 1;

						.hide-title {
							display: none;
						}

						.pagebuilder-buttons {
							column-count: 3;

							li {
								width: 100%;
								margin-right: $twenty-gutter;
								margin-bottom: rem(7);

								.pagebuilder-button-link {
									display: flex;
									align-items: center;

									img {
										width: 44px;
										border-radius: em(60);
										margin-right: $ten-gutter;
									}

									span {
										@include type__13;
									}
								}
							}
						}
					}

					.cms-banners {
						display: none !important; // override pagebuilder styles

						@include min-screen($screen__ll) {
							display: flex !important;
							flex: 0 1 38%
						}
					}
				}

				.a-z-container {
					.pagebuilder-column-group {
						@include min-screen($screen__l) {
							flex-wrap: nowrap;
						}

						.pagebuilder-column {
							&:last-child {
								padding-right: 0;
							}

							@include min-screen($screen__m) {
								flex: 1 1 50%;
							}

							@include min-screen($screen__l) {
								padding-right: $forty-gutter;
								display: grid !important; // override inline pagebuilder display: flex;
								grid-template-columns: auto auto;
								grid-auto-rows: min-content;
							}

							@include min-screen($screen__ll) {
								padding-right: rem(60);
							}

							h3 {
								@include type__20($color: $dusty-gray);
								margin-bottom: $fifteen-gutter;

								@include min-screen($screen__l) {
									text-align: right;
									margin-right: rem(17);
								}
							}

							div[data-element="main"] { // no class or id
								margin-bottom: $gutter;

								&:last-child {
									margin-bottom: 0;
								}

								.pagebuilder-buttons {
									position: relative;
									top: rem(-3); // to force buttons with line-heights to be inline with letters
								}
							}

							.pagebuilder-buttons {
								li {
									width: 100%;
									margin-bottom: $five-gutter;

									&:last-child {
										margin-bottom: 0;
									}
									
									span {
										@include type__13;
									}

									.pagebuilder-button-link {
										display: inline-block;
									}
								}
							}
						}
					}
				}
			} // closes .submenu / fisheye-submenu
		}
	}
}
