// ----------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------
// Specific layout for the contact page ONLY
// ----------------------------------------------------------------------------------
// For example all form styles should be defined already, any CMS content should be
// definied by typography or in _cms.scss
// ----------------------------------------------------------------------------------
.contact-index-index {
	.page-main {
		display: flex;
		flex-direction: column;

		.main {
			padding: 0;
		}

		.page-title-wrapper {
			order: 1;
		}

		.cms-menu-mobile {
			order: 2;
		}

		.columns {
			order: 3;
		}
	}
}

.contact-block {
	p {
		@include type__13;
	}

	img {
		width: 100%;
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-bottom: $forty-gutter;
		}
	}

	hr {
		margin: rem(18) 0;

		@include min-screen($screen__m) {
			margin: rem(35) 0;
		}
	}
}

.contact {
	.title {
		@include type__22($font-weight: $font-weight-bold);
		text-transform: uppercase;
		margin-bottom: $twenty-gutter;
		@include text-crop;

		@include min-screen($screen__m) {
			@include type__29($font-weight: $font-weight-bold);
			margin-bottom: $gutter;
		}
	}

	.note {
		@include type__13($font-weight: $font-weight-normal);
		font-style: italic;
		border-bottom: 1px solid $gallery;
		margin-bottom: rem(22);
		padding-bottom: rem(18);

		@include min-screen($screen__m) {
			margin-bottom: $forty-gutter;
			padding-bottom: rem(35);
		}
	}

	.form-container {
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			display: flex;
			margin-bottom: $gutter;
		}
	}

	.personal {
		@include min-screen($screen__m) {
			margin-right: $gutter;
		}
	}

	.personal,
	.enquiry {
		@include min-screen($screen__m) {
			flex: 0 1 100%;
		}

		.field {
			@include min-screen($screen__m) {
				flex-direction: row;
				align-items: center;
				display: flex;
				margin-bottom: $contact-form-spacing-desktop;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.control {
				flex: 1 1 100%;

				textarea {
					border-radius: $contact-enquiry-textarea-border-radius;
					resize: none;
				}

				input,
				textarea {
					@include input-style-one;
				}
			}

			.label {
				margin-bottom: $ten-gutter;
				flex: 1 1 100%;

				&:after {
					padding: 0;
				}

				span {
					@include type__14(
						$font-weight: $font-weight-semibold
					);
				}
			}
		}
	}

	.enquiry {
		position: relative;

		.comment {
			.label {
				align-self: flex-start;
			}

			.input-text {
				min-height: rem(124);
			}
		}

		.primary {
			.submit {
				@include button__default;
				width: 100%;
				display: block;

				@include min-screen($screen__m) {
					position: absolute;
					bottom: 0;
				}

				> span {
					@include type__14($color: $white);
				}
			}
		}

		.field-recaptcha {
			.grecaptcha-badge {
				z-index: 111;
			}
		}
	}
}

// Contact Us Store details
// ------------------------------------------------
.store-information {
	background-color: $store-information-background;
	padding: $twenty-gutter;
	margin-bottom: $twenty-gutter;
	display: block;

	@include min-screen($screen__m) {
		display: flex;
		padding: $store-information-padding;
	}

	dl {
		display: flex;
		flex-wrap: wrap;
		flex: 0 1 100%;

		dt,
		dd {
			@include type__13;
		}

		dt {
			flex: 1 1 25%;
			margin-bottom: $contact-form-spacing;
			font-weight: $store-information-strong;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include min-screen($screen__m) {
				flex: 1 1 6%;
				margin-right: $contact-form-spacing;
			}
		}

		dd {
			flex: 1 1 73%;
			padding-bottom: $contact-form-spacing;

			&:last-of-type {
				padding-bottom: 0;
			}

			@include min-screen($screen__m) {
				flex: 1 1 85%;
			}
		}
	}

	.pagebuilder-button-link {
		display: block;
		margin-top: rem(14);
		margin-bottom: rem(17);
		
		@include min-screen($screen__m) {
			margin-bottom: rem(23);
		}

		span {
			@include type__13;
			@include link__underline;
		}
	}
}
