/* -------------------------------------------------
 * -------------------------------------------------
 * Table of contents
 * 1. minisearch
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. minisearch
// -------------------------------------------------

$minisearch-spacing: $small-gutter; // container of field and btn - bottom padding removed to evenly space menu and search
$minisearch-background-color: $grey-light; // Background colour for the minisearch container
$minisearch-button-background: transparent;
$minisearch-search-icon-right: $small-gutter;
$minisearch-input-padding-right: em(45); // Right padding for search input, to stop text overlapping search icon