/* --------------------------------------------------
 * --------------------------------------------------
 * Promotional messages, used on product & basket
 * --------------------------------------------------
 * @author Fisheye
 */

.free__delivery,
.basket-delivery-upsell {
	width: 100%;
	padding: $promo-message-inner-spacing;
	border: $promo-message-border;
	font-weight: $font-weight-bold;
	text-align: center;

	> p,
	.upsell-message,
	.upsell-message-alt {
		@if $basket-promo-text-crop == true {
			@include text-crop;
		}

		@include type__19(
			$color: $promo-message-color,
			$font-weight: $font-weight-normal,
		);
		text-transform: $promo-message-text-transform;
	}
}
