// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
//  All scss in this file should be used when refactoring code
//  It is exepcted that the code here would be quite hacky as it is purely to make new code work with old
//  Once working as expected this code would be tidied up or removed completely
//  @author Fisheye
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
// temporarily added so that pages and layouts can be styled quickly.
// TODO: Need to look at how to include this in layout properly
// .sidebar-additional {
//   display: none;
// }
html.hidden {
	// In core this class is added so that the menus can be shown and hidden. Unfortunatelt this class is also added to html when Modernizr is installed.
	// Cancel it out here until Modernizr is removed
	display: block !important;
}

// .modal-open {
//   // This class is added to the body when the extra info tab is open in the footer, but for some reason in
//   // _base.scss in theme-frontend-core this is set ot overflow: hidden
//   // no real place for this to live in Rokit as its not actually a modal issue or a footer issue so left here :)
//   overflow: auto!important;
// }
// -----------------------------------------------------------------------------------------------------------
// This is a bit of hack. The transform on fullwidth stops position:fixed
// As we set things like headers to fullwidth by default we need a way to cancel it out in child themes
// #sadface about this but I can't think of an alternative atm :`(
%cancel-fullwidth {
	position: relative;
	left: auto;
	transform: none;
	width: auto;
	max-width: inherit;
}

// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
// implemenation of newsletter has this extra div that doesn't seem to be in any templates to be removed
// Messes with flexbox
.flex__row {
	// This class is added to the row in Bluefoot
	&.bluefoot-structural {
		// width: 100%;
		@extend %dt-width-full;
	}
}

.footer {
	.bluefoot-entity {
		margin-bottom: 0;
	}
}

// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
// More Bluefoot related shinnanigans
.slider {
	// attempt to cancel out all flex box related stuff for Bluefoot slider
	// Hopefully this won't be needed later as this will be a CMS module
	.flex__row,
	.flex__column {
		display: block;
	}
}

// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
// Remove icon font from minicart in header
// TODO : Rework this so it can be used in Magento_Checkout/_minicart.scss properly
// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
.minicart-wrapper .action.showcart:before {
	content: "" !important;
}

// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
// Taken from Global to make sure things work while syntax is updtated
// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------
// Table of contents
// a. Base
// b. Global
// c. Mediq query ranges
// d. Spacing
// e. Palettes
// Grid
// $container-width: em(1240);
$font-weight-black: 900;

// c. Media query ranges
// -------------------------------------------------
$small-breakpoint: em(767);
$medium-breakpoint: em(992);
$large-breakpoint: em(1200);
$xlarge-breakpoint: em(1300);

$mobile-breakpoint: em(480);
$tablet-breakpoint: em(770);
$desktop-breakpoint: em(990);

$mobile-range: ( $mobile-breakpoint,
$tablet-breakpoint);
$tablet-range: ( $tablet-breakpoint + em(1),
$desktop-breakpoint);
$desktop-range: ( $desktop-breakpoint + em(1),
$large-breakpoint);

$small-range: ( em(601),
$small-breakpoint);
$medium-range: ( $small-breakpoint + em(1),
$medium-breakpoint);
$large-range: ( $medium-breakpoint + em(1),
$large-breakpoint);
$xlarge-range: ( $large-breakpoint + em(1),
em(99999999));

$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$mobile-min: lower-bound($mobile-range);
$mobile-max: upper-bound($mobile-range);

$tablet-min: lower-bound($tablet-range);
$tablet-max: upper-bound($tablet-range);

$desktop-min: lower-bound($desktop-range);
$desktop-max: upper-bound($desktop-range);

$small-min: lower-bound($small-range);
$small-max: upper-bound($small-range);

$medium-min: lower-bound($medium-range);
$medium-max: upper-bound($medium-range);

$large-min: lower-bound($large-range);
$large-max: upper-bound($large-range);

$xlarge-min: lower-bound($xlarge-range);
$xlarge-max: upper-bound($xlarge-range);

// d. Spacing
// -------------------------------------------------
$spacing: ( trim: (small: em(20)),
content: (small: em(20), medium: em(30), large: em(50)));

// e. Palettes
// -------------------------------------------------