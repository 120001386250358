/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Content Styles
 * Used for banners and repeatable CMS content
 * --------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Icons for CMS content
// -------------------------------------------------

.pagebuilder-column {
	.icon {
		p {
			&:before {
				content: "";
				display: inline-block;
				margin-right: $ten-gutter;
			}
		}
	}

	.van {
		p {
			&:before {
				@include svg-background(
					$name: 'van',
					$size: 100%
				);
				width: rem(26);
				height: rem(15);
			}
		}

		&.white {
			p {
				&:before {
					background-image: url('../images/svg/van-white.svg');
				}
			}
		}
	}

	.trophy {
		p {
			&:before {
				@include svg-background(
					$name: 'trophy',
					$size: 100%
				);
				width: rem(19);
				height: rem(19);
			}
		}
	}

	.trustpilot {
		p {
			&:before {
				@include svg-background(
					$name: 'trustpilot-stars',
					$size: contain
				);
				width: rem(98);
				height: rem(18);
			}

			&:after {
				@include svg-background(
					$name: 'trustpilot-logo',
					$size: 95%
				);
				content: "";
				display: inline-block;
				width: rem(78);
				height: rem(20);
				margin-left: rem(4);
			}
		}
	}

	.thumb-up {
		p {
			&:before {
				@include svg-background(
					$name: 'thumbs-up',
					$size: contain
				);
				width: rem(17);
				height: rem(23);
			}
		}
	}
}

// -------------------------------------------------
// Text Slider - Used for global trustbuilder
// -------------------------------------------------

.cms-text-slider {
	padding: $small-gutter;
	padding-bottom: rem(12);

	@include min-screen($screen__m) {
		padding-bottom: rem(13);
	}

	.pagebuilder-column-group {
		max-width: rem(710);
		width: 100%;
		margin: 0 auto;

		@include min-screen($screen__ml) {
			max-width: none;
		}

		&:not(.slick-initialized) {
			.pagebuilder-column {
				display: none !important; // Override inline JS style

				@include min-screen($screen__ml) {
					display: flex !important; // Override inline JS style
					flex-direction: row !important; // Override inline style
				}

				&:first-child {
					display: flex !important; // Override inline JS style
				}
			}
		}
	}

	.slick-arrow {
		width: 21px;
		height: 100%;
		background-size: 9px;
	}

	.slick-arrow {
		background-color: $mercury;
	}

	.slick-next {
		display: block !important; // Override inline JS style
		background-position: right;
	}

	// Important used within this selector are to prevent flashing before JS has initialised
	.pagebuilder-column {
		padding: 0 $ten-gutter;
		justify-content: center;

		@include min-screen($screen__ml) {
			flex: 0 1 percentage(1/3);
		}

		&:first-child {
			flex: 0 1 100%;

			@include min-screen($screen__ml) {
				flex: 0 1 percentage(1/3);
			}
		}

		p,
		span {
			@include type__13(
				$font-weight: $font-weight-semibold
			);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;

			strong {
				@include type__15(
					$font-weight: $font-weight-semibold
				);
				margin-right: rem(3);
			}

			// Remove icon unless it is the very first span
			+ a {
				span:first-child:before {
					display: none;
				}
			}
		}
	}
}

// -------------------------------------------------
// Homepage Carousel - Slider within a slider!
// -------------------------------------------------

.cms-homepage-slider {
	.slick-track {
		display: flex;
	}

	.with-link {
		.overlay-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

		.block-products-list {
			z-index: 2;
		}
	}

	.pagebuilder-overlay {
		position: relative;
	}

	.slick-arrow {
		background-color: rgba(255, 255, 255, 0.6);
		background-size: 10px 16px;
		background-position: center;
		bottom: auto;
		width: rem(40);
		height: rem(40);
		margin: 0;
		display: none !important; // Important to override styles from Pagebuilder

		@include min-screen($screen__m + 1) {
			top: auto;
			bottom: 0;
			margin: auto;
			display: block !important; // Important to override above style
		}

		&.slick-prev {
			left: $fifteen-gutter;

			@include min-screen($screen__m + 1) {
				left: 0;
				right: rem(40);
			}
		}

		&.slick-next {
			left: rem(61);

			@include min-screen($screen__m + 1) {
				left: rem(42);
				right: 0;
			}
		}
	}

	.slick-cloned {
		.slick-dots {
			display: none !important; // Override JS inline styles
		}
	}

	.slick-current {
		.block-products-list {
			max-height: none;
		}
	}

	.block-products-list {
		position: relative;
		width: 100%;
		margin-top: rem(-105);
		background-color: $ebony-clay;
		padding-left: $fifteen-gutter / 2;
		padding-right: $fifteen-gutter / 2;
		padding-bottom: rem(25);
		max-height: rem(315);

		@include min-screen($screen__m + 1) {
			position: absolute;
			right: 0;
			bottom: 0;
			top: 0;
			margin: auto;
			width: 50%;
			max-height: none;
			padding-bottom: 0;
			padding-top: 0;
			max-width: 240px; // Px values, don't want these to change
		}

		@include min-screen($screen__ml) {
			max-width: 250px;
		}

		@include min-screen($screen__l) {
			height: rem(386);
			max-width: 450px;
		}

		@include min-screen($screen__ll) {
			max-width: 665px;
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			left: 0;
			top: -80px; // Px values, don't want these to change
			width: 0;
			border-style: solid;
			border-width: 0 0 80px 100vw;
			border-color: transparent transparent $ebony-clay transparent;

			@include min-screen($screen__m + 1) {
				border-width: 0 0 rem(386) 85px;
				left: -85px;
				top: 0;
			}
		}

		.block-content {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: $ten-gutter;

			@include min-screen($screen__m) {
				margin-top: 0;
			}
		}

		.products-grid {
			overflow: hidden;
		}

		.product-item {
			padding: $fifteen-gutter / 2;

			@include min-screen($screen__m + 1) {
				display: none;

				&:first-child {
					display: block;
				}
			}

			@include min-screen($screen__l) {
				&:nth-child(2) {
					display: block;
				}
			}

			@include min-screen($screen__ll) {
				&:nth-child(3) {
					display: block;
				}
			}

			&.slick-slide {
				display: block;
			}
		}

		.product-image-wrapper {
			display: block;
			padding-bottom: 0 !important; // Override inline JS styles

			img {
				height: rem(140);
				margin: 0 auto;

				@include min-screen($screen__m + 1) {
					height: rem(80);
				}

				@include min-screen($screen__l) {
					height: rem(160)
				}
			}
		}

		.product-item-info {
			.product-item-name {
				margin-top: rem(17);
				min-height: rem(40);
				max-width: rem(250);

				a {
					color: $white;
				}
			}

			.price-box {
				margin-top: rem(23);
				background-color: $radical-red;
				padding: $five-gutter $ten-gutter;

				.price-label {
					@extend %visuallyhidden;
				}
			}

			.price {
				@include type__15(
					$color: $white,
					$font-weight: $font-weight-semibold
				);
			}

			.product-item-actions {
				display: none;
			}
		}

		.product-item-details {
			align-items: center;
		}

		.product-reviews-summary {
			display: none;
		}

		.slick-dots {
			margin-top: rem(8);
			justify-content: center;
			flex: 0 1 100%;
		}
	}

	.desktop-no-products {
		.block-products-list {
			@include min-screen($screen__m + 1) {
				display: none;
			}
		}
	}

	.widget.block-static-block {
		position: absolute;
		left: 0;
		top: 10%;
		margin: auto;
		display: flex;
		align-items: center;
		padding: 0 $small-gutter;

		@include min-screen($screen__xs) {
			top: 15%;
		}

		@include min-screen($screen__m + 1) {
			top: 0;
			bottom: 0;
			width: 50%;
			padding: $small-gutter $gutter;
		}

		div[data-content-type="text"] {
			p {
				@include type__36(
					$color: $white,
					$font-weight: $font-weight-normal
				);
				font-size: 6vw; // VW fontsize used on banner text to enable scaling with the banner.
				text-transform: uppercase;

				@include min-screen($screen__m + 1) {
					font-size: 2vw; // VW fontsize used on banner text to enable scaling with the banner.
				}
			}
		}

		[data-content-type="heading"] {
			@include type__83(
				$color: $white,
				$line-height: 0.95 // Custom line height for viewport width font size
			);
			font-size: 10vw; // VW fontsize used on banner text to enable scaling with the banner.
			margin-top: rem(2);

			@include min-screen($screen__m + 1) {
				font-size: 4vw; // VW fontsize used on banner text to enable scaling with the banner.
			}
		}

		.pagebuilder-button-primary {
			@include button__default;
			display: block;
			margin-top: rem(40);
		}
	}

	.pagebuilder-slide-image-mobile {
		width: 100%;
		height: auto;
	}
}

// -------------------------------------------------
// CMS Banners
// -------------------------------------------------

.cms-banners {
	.pagebuilder-column-group {
		flex-flow: row wrap;

		.pagebuilder-column {

            @for $i from 1 through 3 {
              &:nth-child(4n + #{$i + 1}) {
                background-color: darken($gallery, $i * 5%) !important; //overwrite CMS styling
              }
            }

			@include min-screen($screen__m) {
				flex: 1 0 50%;
			}

			@include min-screen($screen__l) {
				flex: 1 0 25%;

				.cms-home & {
					flex: 1 0 50%;
				}
			}

			@include min-screen($screen__xl) {
				&:nth-child(4) {
					display: block !important; // Important to override inline styles from pagebuilder
				}

				.cms-home & {
					flex: 1 0 25%;
				}
			}

			.catalog-category-view & {
				&:nth-child(n+3) {
					@include min-screen($screen__m) {
						display: none !important; // Important to override inline styles from pagebuilder
					}

					@include min-screen($screen__l) {
						display: flex !important; // Important to override inline styles from pagebuilder
					}
				}
			}
		}
	}

	.pagebuilder-banner-wrapper {
		display: flex;
		align-items: center;
		padding-left: $fifteen-gutter;

		@include min-screen($screen__m) {
			padding-left: $gutter;
		}
	}

	.pagebuilder-banner-image-wrapper {
		order: 1;
		flex: 1 0 auto;
		text-align: right;

		img {
			height: rem(243);
            width: auto;
		}
	}

	.pagebuilder-poster-content {
		p {
			@include type__13($color: $ebony-clay);

			&:first-child {
				@include type__24(
					$color: $ebony-clay
				);
				text-transform: uppercase;
				margin-bottom: rem(8);
			}
		}

		.pagebuilder-banner-button {
			@include button__default;
			margin-top: rem(19);
		}
	}
}

// -------------------------------------------------
// CMS Strapline Links
// -------------------------------------------------

.cms-strapline-links {
	padding: $fifteen-gutter;

	@include min-screen($screen__m) {
		padding: $gutter;
	}

	.pagebuilder-column-group {
		overflow: hidden;
	}

	.pagebuilder-column {
		position: relative;
		padding: rem(23) $ten-gutter rem(21);

		@include min-screen($screen__m) {
			padding: rem(28) $ten-gutter rem(24);
		}

		&:nth-child(2) {
			&:before {
				@include min-screen($screen__m) {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					top: rem(-10);
					left: rem(-15);
					border-style: solid;
					border-width: 0 0 rem(100) rem(16);
					border-color: transparent transparent $charcoal-grey transparent;
				}
			}
		}
	}

	p {
		@include type__19($font-weight: $font-weight-bold);
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;

		@include min-screen($screen__m) {
			@include type__21($font-weight: $font-weight-bold);
		}
	}
}

// -------------------------------------------------
// Brands Scroller - Used on homepage
// -------------------------------------------------

.cms-brands {
	white-space: nowrap;
	overflow: hidden;
	padding: $fifteen-gutter $container-gutter $fifty-gutter;
	border-top: 1px solid $white;

	@include min-screen($screen__m) {
		padding: $fifteen-gutter $gutter rem(73);
	}

	.pagebuilder-buttons-scroll-list.arrow-clicked {
		transition: all 1s ease-in-out;
	}

	.scroll-navigation-wrapper {
		position: relative;

		.next-arrow,
		.previous-arrow {
			@include svg-background(
				$name: 'double-arrows-right',
				$size: rem(20) rem(18)
			);
			background-color: $wild-sand;
			position: absolute;
			width: rem(39);
			cursor: pointer;
			top: 0;
			bottom: 0;
			margin: auto;
			z-index: 5;

			@include min-screen($screen__m) {
				width: rem(28);
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				width: 60px; // Static value, dont want this to change

				@include min-screen($screen__m) {
					width: 120px;
				}
			}

			&.disabled {
				display: none;
			}
		}

		.next-arrow {
			right: 0;
			background-position: left;

			&:before {
				@include horizontal-gradient(
					rgba(255, 255, 255, 0),
					rgba(246, 246, 246, 1)
				);
				right: rem(39); // Static value, dont want this to change

				@include min-screen($screen__m) {
					right: rem(28);
				}
			}
		}

		.previous-arrow {
			left: 0;
			background-image: url('../images/svg/double-arrows-left.svg');
			background-position: right;

			&:before {
				@include horizontal-gradient(
					rgba(255, 255, 255, 0),
					rgba(246, 246, 246, 1),
					$direction: left
				);
				left: rem(39); // Static value, dont want this to change

				@include min-screen($screen__m) {
					left: rem(28);
				}
			}
		}
	}

	li {
		@include min-screen($screen__m) {
			margin-right: $fifteen-gutter;
		}

		span {
			@include type__15($font-weight: $font-weight-semibold);
			text-transform: uppercase;
			display: block;
			margin-top: $five-gutter;
		}

		.pagebuilder-image-wrapper {
			max-width: rem(130);
			width: 100%;
			margin: 0 auto;

			@include min-screen($screen__m) {
				max-width: rem(160);
			}
		}
	}
}

// -------------------------------------------------
// Product Listing
// -------------------------------------------------

.cms-product-block {
	padding-top: $gutter;

	@include min-screen($screen__m) {
		padding-top: rem(56);
	}

	h3 {
		@include type__22($color: $dusty-gray);
		text-transform: uppercase;

		@include min-screen($screen__m) {
			@include type__36($color: $dusty-gray);
		}
	}

	.slick-dots li button {
		background-color: $alto;
	}

	.slick-arrow {
		display: none !important; // Cannot be disabled in the slider admin settings, must be important to override inline style
	}

	.product-item-photo {
		padding: 1rem;
	}
}

// -------------------------------------------------
//  SEO Content Block
// -------------------------------------------------

.cms-seo-block {
	padding: $gutter $fifteen-gutter rem(33);
	align-items: center;

	@include min-screen($screen__m) {
		padding: rem(56) $fifteen-gutter rem(81);
	}
}

.cms-seo-block-heading {
	@include type__24($color: $dusty-gray);
	text-transform: uppercase;
	margin-bottom: $twenty-gutter;

	@include min-screen($screen__m) {
		@include type__36($color: $dusty-gray);
	}
}

.cms-seo-block-text {
	max-width: rem(1152);

	p {
		@include type__13;
	}

	&.homepage-text {
		p {
			&:nth-last-child(-n+4) {
				display: none;

				@include min-screen($screen__m) {
					display: block;
				}
			}
		}
	}

	&.cms-seo-block-expanded {
		p {
			&:nth-last-child(-n+4) {
				display: block;
			}
		}
	}
}

.cms-seo-block-more {
	text-align: center;
	margin-top: $twenty-gutter;

	@include min-screen($screen__m) {
		display: none;
	}

	span {
		@include type__13($color: $keppel);
		border-bottom: 1px solid $keppel;
		cursor: pointer;
	}
}
