/* -------------------------------------------------
 * -------------------------------------------------
 * Default Product Labels
 * -------------------------------------------------
 * @author Fisheye
 */

.cataloglabel-placeholder {
	width: 100%;
	position: absolute;
	top: 0;

	> [class^="cataloglabel-"] {
		background-size: contain !important; // Important to override inline styles
		max-height: 100%;
		max-width: 100%;
		position: relative; // change to absolute should positioning (center/ bottom) is needed
		z-index: 1;

		span:empty {
			display: none; // Do not display empty span
		}

		&.cataloglabel-top-left {
			top: 0;
			left: 0;
		}

		&.cataloglabel-top-center {
			top: 0;
			left: 50%;
			right: auto;
			margin-left: auto; // for when label is relative position
			margin-right: auto; // for when label is relative position
		}

		&.cataloglabel-top-right {
			top: 0;
			left: auto;
			right: 0;
			margin-left: auto; // for when label is relative position
		}

		// following styling are for here for future use should label position change to absolute.
		&.cataloglabel-middle-left {
			top: 50%;
			bottom: auto;
			left: 0;
		}

		&.cataloglabel-middle-center {
			top: 50%;
			bottom: auto;
			left: 50%;
			right: auto;
		}

		&.cataloglabel-middle-right {
			top: 50%;
			bottom: auto;
			left: auto;
			right: 0;
		}

		&.cataloglabel-bottom-left {
			top: auto;
			bottom: 0;
			left: 0;
		}

		&.cataloglabel-bottom-center {
			top: auto;
			bottom: 0;
			left: 50%;
			right: auto;
		}

		&.cataloglabel-bottom-right {
			top: auto;
			bottom: 0;
			left: auto;
			right: 0;
		}
	}
}
