.loading-mask {
	background: $overlay-color-alt;
	width: 100vw;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	.gallery-placeholder._block-content-loading & {
		background: $default-overlay-color;
		width: auto;
		position: relative;

		.loader {
			position: absolute;
		}
	}
}

.loader,
%element-loader {
	@include svg-background(
		$name: medal-logo,
		$size: 100%
	);
	width: 60px;
	height: 60px;
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right: 0;
	margin: auto;
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation-iteration-count: infinite;

	img,
	p {
		display: none;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		-webkit-transform: scale3d(1.13, 1.13, 1.13);
		transform: scale3d(1.13, 1.13, 1.13);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
