/* -------------------------------------------------
 * -------------------------------------------------
 * Slick Slider Styles
 * Basic Slick Styles, including arrow & dot styles
 * --------------------------------------------------
 * @author Fisheye
 */

 // Basic Slick Arrow Styles

.slick-slide {
	> div {
		line-height: 0; // Some weird line height issue in an empty element, set to 0 to prevent whitespace
	}
}

.slick-arrow {
	@include svg-background(
		$name: arrow-left-black,
		$size: contain
	);
	background-position: left;
	background-color: transparent;
	position: absolute;
	width: 21px;
	height: 20px;
	font-size: 0;
	padding: 0;
	border: 0;
	top: 0;
	bottom: 0;
	margin: auto;

	&.slick-next {
		z-index: 1;
		right: 0;
		background-image: url('../images/svg/arrow-right-black.svg');
	}

	&.slick-prev {
		z-index: 1;
		left: 0;
	}
}

.slick-dots {
	display: flex;
	margin-bottom: rem(25);
	justify-content: center;

	li {
		display: inline-block;
		line-height: 0;
		padding: 0 $five-gutter;

		button {
			width: 11px;
			height: 11px;
			font-size: 0;
			padding: 0;
			border: 0;
			background-color: $alto;
			border-radius: 100px;
		}

		&.slick-active {
			button {
				background-color: $keppel;
			}
		}
	}
}

// Fix to stop all slides loading in at once when hitting the page
.pagebuilder-slider {
	> div[data-content-type="slide"] {
		display: none;

		&:first-of-type {
			display: block;
		}
	}
	a[data-link-type="product"] {
		.pagebuilder-poster-overlay {
			img {
				display: none;
			}
		}
	}
}
