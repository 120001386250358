/* -------------------------------------------------
 * -------------------------------------------------
 * Ajax Cart - Added to Basket Popup
 *
 * TODO: Test this. Copied directly from Urban Gifts
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.block-basket-preview {
	.modal-inner-wrap {
		max-width: $basket-popup-inner-model-max-width;
		box-shadow: none;
		margin: auto;

		.modal-header {
			border-bottom: 0;
			padding: $basket-popup-model-header-spacing;

			.action-close {
				position: static;
				float: right;
				@include action-w-icon__cross-white;

				span {
					color: $white;
				}
			}
		}

		.modal-content {
			padding: 0;
		}

		// product image and details text
		// ------------------------

		.block-basket-preview {
			position: relative;

			.basket-preview-overlay {
				background-color: $basket-popup-image-overlay-color;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}
		}

		.basket-popup-product-details {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			padding: $basket-popup-product-details;
			position: relative;
			z-index: 2;
			max-height: rem(124);

			> svg {
				width: 44px;
				height: 44px;
			}

			.st0 {
				fill: none;
				stroke: $white;
			}

			.st1 {
				fill: $white;
				stroke: $white;
			}

			.messages {
				flex: 0 1 auto;
				padding: $basket-popup-detail-message-spacing;
				order: 1;
				flex: 0 1 100%;
			}

			.message-text {
				display: none; // the default magento message that we replace
			}

			.basket-popup-title {
				flex: 0 1 auto;
				padding: $basket-popup-detail-title-spacing;

				span {
					@include type__20(
						$color: $basket-popup-product-details-color, 
						$font-weight: $font-weight-bold
					);

					@include text-crop;
				}
			}

			.product-container {
				display: $basket-popup-product-info-container-display-setting;
				flex: 0 1 auto;
				flex-flow: column wrap;
				align-items: center;
			}

			.product-name {
				@include type__16($color: $basket-popup-product-details-color);
				padding: $basket-popup-detail-name-spacing;
				display: $basket-popup-detail-name-display;
			}

			.product-quantity {
				@include type__16($color: $basket-popup-product-details-color);
				padding: $basket-popup-detail-qty-spacing;
				display: $basket-popup-detail-qty-display;
			}

			.product-price {
				display: $basket-popup-detail-price-display;
			}
		}

		// continue btns
		// ------------------------
		.block-basket-preview-popup {
			.actions {
				display: flex;
				flex-flow: row wrap;
				padding: $twenty-gutter;
				background: $popup-background-color;
				justify-content: center;
				border-bottom: 1px solid $border-color-alt2;

				@include min-screen($screen__s) {
					display: flex;
					flex-flow: row wrap;
					align-items: flex-start;
				}

				@include min-screen($screen__m) {
					padding: $basket-popup-actions-container-spacing;
				}

				.checkout {
					@include button__journey($padding: 0);
					margin: $basket-popup-proceed-buttons-spacing;
					flex: 1 1 100%;
					height: 35px;

					// Custom breakpoints & static heights below, to match the PayPal button sizes
					@include min-screen(386px) {
						height: 45px;
					}

					@include min-screen(587px) {
						height: 55px;
					}

					@include min-screen($screen__s) {
						flex-grow: $basket-popup-proceed-buttons-flex-grow;
						flex-shrink: $basket-popup-proceed-buttons-flex-shrink;
						flex-basis: $basket-popup-proceed-buttons-flex-basis;
						max-width: $basket-popup-proceed-button-width; // controls width of button
					}
				}
			}

			.secondary-actions {
				background-color: $popup-background-color;
				padding: $basket-popup-secondary-actions-container-spacing;

				.express-checkout{
					text-align: center;

					.express-checkout-title {
						padding-bottom: $twenty-gutter;
						display: block;

						span {
							@include type__13($font-weight: $font-weight-bold);
						}
					}

					.extra-actions-text {
						display: block;
						padding-bottom: $minicart-extra-action-spacing;
					}

					.extra-actions-container { // paypal options container
						display: flex;
						align-items: center;
						background-color: $popup-background-color;
					}

					.paypal {
						flex: 0 1 100%;

						.zoid-outlet {
							width: 100% !important;
						}
					}
				}
			}

			.checkout {
				order: 0;
			}

			.continue,
			.cart {
				@include type__13($font-weight: $font-weight-normal);
				display: inline-block;
				background-color: $popup-background-color;
				color: $body-font-color;
				border: 0;
				margin-bottom: 0;
				order: 1;

				span {
					border-bottom: 1px solid $body-font-color;
				}
			}

			.continue {
				@extend %cancel-button;
			}
		}

		.block-popup-item {
			display: flex;
			padding: $twenty-gutter 0;
			margin-bottom: $twenty-gutter;
			border-bottom: solid 1px $border-color-alt;

			.img-container {
				min-width: em(120);
				position: relative;
				margin-bottom: $item-gutter;

				@include min-screen($screen__s) {
					padding-bottom: 0;
					min-width: em(140);
				}

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.5);
					content: "";
					display: inline-block;
				}

				&:after {
					content: "";
					background-size: contain;
					width: em(80);
					height: em(80);
					@include svg-background("added-to-basket-icon");
					display: inline-block;
					position: absolute;
					top: calc(50% - #{em(40)});
					right: calc(50% - #{em(40)});
				}

				img {
					min-width: 100%;
				}
			}

			.product-container {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				padding-left: $twenty-gutter;

				@include min-screen($screen__s) {
					flex-direction: column;
					padding: $twenty-gutter $twenty-gutter $twenty-gutter * 1.5 $twenty-gutter * 1.5;
				}

				.product-name {
					flex: 0 1 100%;

					@include min-screen($screen__s) {
						flex: 2;
					}
				}

				.product-price,
				.product-quantity {
					flex: 0 1 50%;
					align-self: flex-end;

					@include min-screen($screen__s) {
						flex: 1;
						align-self: flex-start;
					}

					span {
						@include type__16($color: $basket-popup-product-details-color);
					}
				}

				.option-seperator {
					padding-right: $item-gutter /2;
				}
			}
		}
		// you may also like block (crosssell)
		// ------------------------

		.block-cross-sell-products {
			display: flex;
			flex: 1;
		}

		.block-basket-crosssell {
			display: flex;
			flex-wrap: wrap;
			padding: $fifteen-gutter;

			@include min-screen($screen__m) {
				padding: $forty-gutter 0 rem(4);
			}

			.block-title {
				flex: 1 100%;
				text-align: center;

				@include min-screen($screen__m) {
					margin-bottom: rem(4);
				}

				strong {
					@include type__22($color: $dusty-gray);
					text-transform: uppercase;
				}
			}

			.crossSellContainer {
				display: flex;
				flex-flow: column wrap;
				align-items: center;

				> a {
					width: 100%;
				}

				form { // 'view product' button
					display: none;
				}

				.viewcrosssell {
					@include button__default;
				}

				.product-name {
					@include type__13;
					display: block;
					margin-bottom: $fifteen-gutter;
					margin-top: $fifteen-gutter;
					min-height: rem(57);
					text-align: center;
				}

				.priceEach,
				.product-item-actions {
					display: none;
				}
			}

			.block-cross-sell-product {
				@extend %flex__item;
				position: relative;
				flex: 1 1 50%;
			}

			.img-container {
				position: relative;
				max-width: em(160);
				margin: auto auto $fifteen-gutter;
			}

			img {
				width: 100%;
			}
		}
	}

	.basket-popup-loading {
		text-align: center;
		padding: $twenty-gutter 0;
	}
}

.catalog-product-view {
	// needs to be more specific
	.block-basket-preview.modal-slide {
		left: 0;
		overflow-y: auto;

		.modal-inner-wrap {
			margin: $basket-popup-inner-model-spacing-mobile;
			background-color: $basket-popup-background-color;
		}
	}
}

.modals-overlay {
	background-color: $overlay-color-alt;
}

// need to be slightly more specific
.block-basket-preview {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	&.modal-slide {
		.modal-inner-wrap {
			border: 0;
			height: auto;
			max-height: 100%;
			margin: $basket-popup-inner-model-spacing-mobile;
			box-shadow: none;
		}
	}
}

aside.modal-popup.block-basket-preview {
	.modal-inner-wrap[class] {
		margin-top: 0;
		margin-bottom: rem(200);
		background: transparent;
		//make wrapper transparent, wrapper extends full height of page which doesn't need a backgrounc color
		//so specific so only the addtobasket modal is affected

		@include min-screen($screen__m) {
			margin-top: rem(50);
		}

		.modal-content {
			overflow-y: visible;
		}
	}
}
