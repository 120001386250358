/* -------------------------------------------------
 * -------------------------------------------------
 * General product listings. Mostly category pages
 *
 * Can also be in cross sells and upsells -
 * see _cms-products-list.scss for more styles for products grid in cms areas
 * Nearly everything should be defined elsewhere i.e.
 * how prices display, typography for product title, buttons etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.product-listing {
	padding: $category-product-listing-spacing-mobile;
	flex: 0 1 percentage(1/2);
	border-top: 1px solid $gallery;

	&:nth-child(-n+2) {
		border-top: 0;
	}

	@include min-screen($screen__m) {
		flex: 0 1 percentage(1/3);
		padding: $category-product-listing-spacing; // override flex__item

		&:nth-child(-n+3) {
			border-top: 0;
		}
	}

	@include min-screen($screen__ml) {
		flex: 0 1 percentage(1/5);

		&:nth-child(-n+5) {
			border-top: 0;
		}
	}

	@include min-screen($screen__xxl) {
		flex: 0 1 percentage(1/6);

		&:nth-child(-n+6) {
			border-top: 0;
		}
	}
}

.product-item-info {
	width: 100%;
	height: 100%;
	text-align: center;
	display: flex;
	flex-flow: column wrap;

	.product-image-wrapper {
		padding-bottom: 0 !important; // Override inline padding bottom
	}

	.product-item-details { // this is the container of all prodcut info other than the image
		flex: 1 1 auto;
		display: flex; // flex applied to align content vertically but can also be used to order content
		flex-flow: column wrap;

		.swatch-input {
			display: none; // remove input field for swatches
		}
	}

	.product-item-name {
		display: block;
		margin-bottom: 0;
		margin-top: $category-product-item-name-top-spacing;

		@include min-screen($screen__ll) {
			min-height: rem(59);
		}

		a {
			@include type__13(
				$color: $category-product-item-name-color,
				$font-weight: $category-product-item-name-weight
			);
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.price-box {
		flex: 0 1 auto;
		padding-bottom: $category-product-item-pricing-bottom-spacing;
		margin-top: rem(13);

		.price-container {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			.price-excluding-tax-container {
				justify-content: center;
			}
		}

		.price-display-label {
			margin-left: rem(6);
		}
	}

	.inc-vat-label {
		display: none;
	}

	.minimal-price-link { // refers to  tier pricing but duplicates price
		display: none;
	}

	.product-item-inner {
		display: none;
		flex: 0 1 auto;
	}

	.product-item-photo {
		position: relative;
	}

	div[class^="swatch-opt"] { // no generic classes available
		flex: 0 1 auto;

		.swatch-attribute {
			.swatch-option {
				cursor: pointer;
				margin-bottom: $ten-gutter;
				margin-left: $five-gutter;
				margin-right: $five-gutter;

				&.disabled {
					text-decoration: $line-through;
					text-decoration-thickness: 2px;
					pointer-events: none;
				}

				&[disabled] {
					opacity: 0.7;
					cursor: not-allowed;
				}

				// OOS configurable options - still selectable
				&.unavailable {
					opacity: 0.7;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						// Display X / cross in the background
						background-image: linear-gradient(
							to top left,
							transparent 0%,
							transparent calc(50% - 1px),
							$gainsboro 50%,
							transparent calc(50% + 1px),
							transparent 100%
						),
						linear-gradient(
							to top right,
							transparent 0%,
							transparent calc(50% - 1px),
							$gainsboro 50%,
							transparent calc(50% + 1px),
							transparent 100%
						);
					}
				}
			}

			&.height {
				.swatch-option {
					@include type__12($color: $ebony-clay);
					flex: $category-swatches-flex;
					border: 1px solid $silver;
					padding: rem(6) rem(8);

					@include min-screen($screen__m) {
						flex: 0 1 auto;
					}

					&.selected {
						border-color: $keppel;
					}
				}
			}

			&.colour {
				.swatch-option {
					height: 30px;
					width: 30px;
					border: 2px solid $white;
					outline: 1px solid $silver;

					@include min-screen($screen__m) {
						height: 34px;
						width: 34px;
					}

					&.image {
						height: 30px !important; // override inline styles specific to image uploads
						width: 30px !important;
						background-size: cover !important;

						@include min-screen($screen__m) {
							height: 34px !important;
							width: 34px !important;
						}
					}

					&.selected {
						outline-color: $keppel;
					}

					&.disabled {
						text-decoration: $line-through;
						text-decoration-thickness: 2px;
						pointer-events: none;
						position: relative;

						&.color,
						&.image {
							&::before {
								border-top: 2px solid $ebony-clay;
								left: 0;
								right: 0;
								top: 50%;
								position: absolute;
								transform: rotate(-45deg);
								-webkit-transform: rotate(-45deg);
								-moz-transform: rotate(-45deg);
								-ms-transform: rotate(-45deg);
								-o-transform: rotate(-45deg);
								content: '';
							}
						}
					}
				}
			}

			.swatch-more {
				flex: 1 1 100%;

				span {
					@include type__13(
						$color: $body-font-color,
						$font-weight: $font-weight-normal
					);
					@include link__underline;
				}
			}
		}

		.swatch-attribute-options {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: rem(250);
			margin: 0 auto;

			@include min-screen($screen__m) {
				flex-flow: row wrap;
				justify-content: center;
			}
		}
	}

	.product-reviews-summary {
		padding-bottom: $category-product-item-review-bottom-spacing;
	}

	.rating-summary {
		.rating-result,
		.label {
			display: inline;
		}
	}

	.actions-secondary {
		@if $category-product-listing-wishlist-display == none and $category-product-listing-compare-display == none {
			display: none;
		}
	}

	.action.preorder {
		@if $category-product-listing-preorder-display == none {
			display: none;
		}
	}

	.tocart { // button
		@include button__default;
		display: $category-product-listing-button-display;
	}

	.towishlist {
		display: $category-product-listing-wishlist-display;
	}

	.tocompare {
		display: $category-product-listing-compare-display;
	}

	.towishlist,
	.tocompare {
		align-content: center;
		text-align: left;

		&:before {
			content: "" !important; // cancels font-awesome icon from Magento UI
			display: block;
			width: 22px;
			height: 22px;
			top: 0;
			position: relative; // use these to hide icon until hover so that space the icon needs is still being used
		}

		span {
			padding: $category-compare-wishlist-spacing;
		}
	}

	.towishlist:before {
		@include svg-background($name: "heart", $size: contain);
	}

	.tocompare:before { // ToDo change icon to a compare icon
		@include svg-background($name: "star", $size: contain);
	}
}

// -------------------------------------------------------------------------------------
// Category Slider
// Shows types of chosen category type (e.g. glass rugby trophies within rugby trophies)
// -------------------------------------------------------------------------------------
.category-cms-additional {
	.cms-brands {
		padding: $forty-gutter $gutter;

		@include min-screen($screen__m) {
			padding: em(77) $gutter;
		}

		li {
			margin-right: $five-gutter;

			@include min-screen($screen__m) {
				margin-right: $twenty-gutter;
			}

			&:last-child {
				margin-right: 0;
			}

			span {
				margin-top: $fifteen-gutter;
				max-width: em(195);
				white-space: pre-wrap;
			}
		}
	}
}
