/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Statis Page specific Styling
 * Usually populated through bluefoot
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// CMS Layout
// -------------------------------------------------

.contact-index-index,
.cms-page-view,
.cms-no-route {
	.page-main {
		display: flex;
		flex-direction: column;
		padding: 0;

		.page-title-wrapper {
			order: 1;
		}
	}

	.columns {
		order: 3;
		width: 100%;
		max-width: $cms-container-width;
		margin: 0 auto;
		padding: 0 $container-gutter $gutter $container-gutter; // top spacing not needed as coming from cms menu

		@include min-screen($screen__m) {
			padding: $forty-gutter $page-padding;
			grid-template-columns: auto;
		}

		.trustpilot-widget {
			margin-top: rem(22);

			@include min-screen($screen__m) {
				margin-top: rem(34);
			}
		}
	}

	.breadcrumbs {
		display: none;
	}
}

// -------------------------------------------------
// 404 page
// -------------------------------------------------

.cms-no-route {
	.column.main {
		@include min-screen($screen__m) {
			padding-left: rem(34);
		}
	}

	.column {
		dt {
			padding-bottom: $twenty-gutter;
		}

		.disc {
			list-style-type: decimal;
			padding-left: $gutter;
		}
	}
}
// -------------------------------------------------
// CMS Menu Sidebar
// -------------------------------------------------

.cms-menu-nav {
	h3 {
		margin: $twenty-gutter 0;

		@include type__16(
			$color: $dusty-gray,
			$font-weight: $font-weight-bold
		);

		@include min-screen($screen__m) {
			margin: $twenty-gutter 0;

			@include type__20(
				$color: $dusty-gray,
				$font-weight: $font-weight-bold
			);

			&:first-child {
				margin-top: 0;
			}
		}
	}

	ul {
		li {
			width: 100%;
			margin-bottom: rem(7);
			@include type__13;
			@extend %cancel-underline;
		}
	}

	&.cms-menu-mobile {
		order: 2;
		padding: $twenty-gutter 0;
		border-bottom: 1px solid $gallery;
		margin: 0 $fifteen-gutter $twenty-gutter $fifteen-gutter;

		.title {
			display: flex;
			align-items: center;

			&:after {
				content: '';
				display: inline-block;
				height: 12px;
				width: 12px;
				@include svg-background(
					$name: 'plus',
					$size: 100%
				);
			}

			strong {
				text-transform: uppercase;
				@include type__14($font-weight: $font-weight-semibold);
				flex: 1;
			}
		}

		.active {
			.title {
				&:after {
					@include svg-background('minus');
				}
			}
		}
	}
}

// -------------------------------------------------
// Main Page Content
// -------------------------------------------------

.cms-page-view,
.cms-no-route {
	.column {
		padding: 0;

		.breadcrumbs {
			display: none;
		}

		h1,
		h2,
		h3,
		h4,
		h5 {
			text-transform: uppercase;
		}

		h2,
		h3,
		h4,
		h5 {
			// not h1s as they should already have margin bottom
			margin-bottom: $twenty-gutter;
		}

		h3 {
			@include type__22($font-weight: $font-weight-bold);

			@include min-screen($screen__m) {
				@include type__29($font-weight: $font-weight-bold);
			}
		}

		strong {
			font-weight: $font-weight-semibold;
		}

		hr {
			margin: 0 0 $twenty-gutter 0;

			@include min-screen($screen__m) {
				margin: $twenty-gutter 0 $gutter 0;
			}
		}

		p,
		li,
		dt,
		dd {
			@include type__13;
			text-transform: initial;
		}

		p {
			font-weight: $font-weight-normal;
			margin-bottom: $twenty-gutter;

			&:last-child {
				margin: 0;
			}
		}

		li {
			font-weight: $font-weight-bold;
			margin-bottom: $twenty-gutter;
		}

		a {
			@include link__underline;
			font-weight: $font-weight-normal;
		}

		img {
			margin-bottom: $twenty-gutter;
			width: 100%;

			@include min-screen($screen__m) {
				margin-bottom: rem(32);
			}
		}
	}

	.why-us-text {
		p {
			font-weight: $font-weight-semibold;

			&:before {
				content: '';
				display: inline-block;
				height: 14px;
				width: 14px;
				margin-right: $ten-gutter;
				vertical-align: text-top;
				@include svg-background(
					$name: tick,
					$size: contain
				);
			}
		}
	}

	.delivery-note {
		margin: $twenty-gutter 0;

		@include min-screen($screen__m) {
			margin: $gutter 0;
		}
	}

	.disc {
		li {
			@include type__13;
		}
	}

	table {
		margin-bottom: $gutter;

		td {
			@include type__13;
		}

		tr {
			border-bottom: 1px solid $gallery;
		}

		strong {
			font-weight: $font-weight-semibold;
			display: block;
			width: 100%;
		}
	}

	// This section matches all of the text-links in _text-links but needs to only be applied
	// to links with the class on the ul on CMS pages so copied here
	.row {
		ul.text-links {
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: center;

			li {
				display: inline-block;
				font-weight: $font-weight-semibold;
				line-height: 1rem; // custom line-height because it affects the underline
				border-bottom: solid 1px $border-color;
				margin: $twenty-gutter $twenty-gutter 0 $twenty-gutter;

				@include min-screen($screen__m) {
					margin: 0 $twenty-gutter;
				}

				&:first-child {
					border-bottom: solid 1px $body-background-color;

					&:hover {
						border-bottom: solid 1px $body-background-color;
					}
				}

				a {
					font-weight: $font-weight-normal;
					line-height: 1rem; // custom line-height because it affects the underline
				}
			}
		}
	}

	&.cms-faqs {
		.column.main {
			max-width: $container-contained-width;
			margin: 0 auto;
		}
	}
}

// Specific to the Request a Brochure Page
.cms-request-a-brochure {
	.column {
		img {
			width: auto;
		}

		.order-link {
			margin-top: $gutter;
		}
	}
}

.shipping-table {
	table {
		display: inline-block;
		max-width: 10vw;
		min-width: 100%;
		overflow-x: auto;
		height: auto !important; /* Override inline style */
		width: auto !important; /* Override inline style */

		tr {
			display: flex;
			height: auto !important; /* Override inline style */
		}

		td {
			height: 100% !important; /* Override inline style */
		}
	}
}
