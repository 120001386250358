/* -------------------------------------------------
 * -------------------------------------------------
 * Global modal styling
 * -------------------------------------------------
 * @author Fisheye
 */

.modal-popup {
	background-color: $overlay-color-alt;
	padding: $modal-gutter;
	

	.modal-inner-wrap {
		max-width: em(800);
		box-shadow: none;
		border: solid 1px $border-color;
		margin: 0 auto;
	}

	.modal-header {
		position: relative;
		padding: $modal-gutter;

		h1 {
			@include type__22();
			text-transform: uppercase;
			border-bottom: none;
			padding-bottom: 0;

			@include min-screen($screen__m) {
				@include type__29();
			}
		}

		.action-close {
			position: absolute;
			margin: 0;
			top: 0;
			right: $modal-gutter;
			bottom: 0;
			display: flex;
			align-items: center;

			&:after {
				@include svg-background('cross-circle-grey');
				height: em(27);
				width: em(27);
			}

			span {
				@include type__13;
			}
		}
	}

	.modal-content {
		padding: 0 $modal-gutter;

		.field.choice {
			margin: 0;
		}

		.search-container {
			.field {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.enter-address-manually {
			@include link__underline;
			@include type__13;
			text-transform: capitalize;
			cursor: pointer;
		}
	}

	.modal-footer {
		padding: $modal-gutter;
		text-align: right;

		.action,
		.action-accept {
			@include button__default;
			margin-left: $ten-gutter;
		}

		.action-dismiss {
			@include button__grey($padding: $button-default-padding);
			display: inline;
			border: 0;
		}
	}
}

.modal-popup.modal-slide {
	.modal-inner-wrap[class] {
		.modal-content {
			background-color: $body-background-color;
		}
	}
}

/* this is added to the body tag when the minicart is modal-open
 * this stops weird scrolly issues on mobile because both basket
 * contents and the body are trying to scroll
 */

.modal-open {
	overflow: auto;
}

/*
 * This has been changed to overflow uato because it was stopping the accordian in the footer from displaying the content within when open.
 * TODO: This will need double checking to make sure the scroll issue in the basket hasn't been re-introduced
 */
