/* -------------------------------------------------
 * -------------------------------------------------
 * Page Titles
 *
 * These can be found on most pages, including the
 * basket page and category pages
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.page-title-wrapper {
	background-color: $ebony-clay;
	padding: $gutter $fifteen-gutter;
	text-align: center;

	@include min-screen($screen__m) {
		padding: rem(28) $fifteen-gutter rem(25);
	}

	.page-title {
		text-align: center;

		span {
			@include type__24($color: $white);
			text-transform: uppercase;

			@include min-screen($screen__m) {
				@include type__36($color: $white);
			}
		}
	}
}
