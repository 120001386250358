/* -------------------------------------------------
 * -------------------------------------------------
 * Footer accordion to show hide columns of content on mobile
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// this has been created so that the correct em conversion can be used in calc()
$top: em(12.5);

.mob-footer-info-tab {
	h3 {
		padding: $item-gutter 0; // left and right padding should be controlled by outer gutters so no more is needed here
		position: relative;
		border-bottom: solid 1px $border-color-alt;
		margin-bottom: $item-gutter;

		&:after {
			display: block;
			content: '';
			position: absolute;
			right: 0;
			top: calc(50% - #{$top});
			width: em(25);
			height: em(25);
			@include svg-background($footer-accordion-icon-expand);
		}

		&.open {
			&:after {
				@include svg-background($footer-accordion-icon-collapse);
			}
		}
	}
	
	.useful-info {
		padding-bottom: $footer-accordion-useful-info-padding-bottom;
	}
}