/* -------------------------------------------------
 * Contact Us Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Contact Us Variables
 *	- 1a. - Information Block
 *	- 1b. - Form
 *
 * 2. - Contact Us Sidebar Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Contact Us Variables
// -------------------------------------------------

// 1a. Information Block
$store-information-padding: $gutter;
$store-information-background: $wild-sand;
$store-information-strong: $font-weight-bold;

// 1b. Contact us Form
$contact-form-spacing: $twenty-gutter;
$contact-enquiry-height:  em(165); // This is for the height of the enquiry box it always needs to be the same height as 2 fields
$contact-form-input-background: $white;
$contact-form-spacing: $small-gutter;
$contact-form-spacing: $small-gutter;
$contact-form-spacing-desktop: $twenty-gutter;
$contact-enquiry-textarea-border-radius: 0;
