/* -------------------------------------------------
 * -------------------------------------------------
 * PDF download - enabled on tpm an trophy street
 * Found on category and product pages
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.download-container {
	@include type__16(
		$color: $download-container-text-color
	);
	align-items: center;
	background-color: $download-container-colour;
	border: $download-container-border;
	border-radius: $download-container-border-radius;
	display: flex;
	gap: $five-gutter;
	justify-content: space-between;
	margin: $download-container-margin;
	order: 3;
	padding: $download-container-spacing;
	width: 100%;

	button {
		@include link__underline(
			$border-bottom: solid 1px $download-container-link-color,
			$color: $download-container-link-color
		);
		background-color: transparent;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		padding: 0;
	}

	.icon {
		display: flex;
		flex: 0 1 rem(18);
		margin-left: rem(4);

		@include min-screen($screen__m) {
			flex: 0 1 rem(24);
		}

		svg {
			height: 100%;
			width: 100%;

			path {
				fill: $download-container-icon-colour;
			}
		}
	}
}

/*  Category Pages
 * -------------------------------------------------
 */

.catalog-category-view {
	.download-container {
		@include type__14(
			$color: $download-container-text-color
		);
		margin: 0 rem(16) rem(16);
		white-space: nowrap;
		width: auto;

		.icon {
			height: rem(16);
			margin-left: rem(4);
			width: rem(16);
		}

		@include min-screen($screen__m) {
			margin: 0;
		}
	}
}

/*  Bottom Level Category
 * -------------------------------------------------
 */

.catalog-category-view:not(.page-layout-2columns-landing),
.catalogsearch-result-index {
	.download-container {
		display: none; /* only required on top level categories but its a shared template */
	}
}

/*  Product Page
 * -------------------------------------------------
 */

.catalog-product-view {
	.download-container {
		flex-direction: column-reverse;

		@include min-screen($screen__xs) {
			flex-direction: row;
		}

		@include min-screen($screen__m) {
			flex-direction: column-reverse;
		}

		@include min-screen($screen__l) {
			flex-direction: row;
		}

		.icon {
			flex: 0 1 rem(18);
			width: rem(18); /* required for column style */
		}

		span {
			text-align: center;
		}
	}
}

/*  Catalog popup
 * -------------------------------------------------
 */

.download-popup {
	&.modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
		min-height: rem(100); // gross nesting to override base modal styles
	}

	.modal-header {
		align-items: center;
		background-color: $grey-lightest;
		display: flex;
		gap: $small-gutter;
		justify-content: space-between;

		.action-close {
			position: static;
		}
	}

	.modal-content {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		padding-top: rem(24);
		padding-bottom: $gutter;
	}

	.data-grid {
		width: 100% !important; /* override inline styles */
	}

	.action-default {
		@include button__default;
		display: block;
		margin-top: $small-gutter;
		width: 100%;
	}

	td {
		padding-left: 0;
		padding-right: 0;
	}

	tr label {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-left: auto;
		width: 100%;

		&:before {
			margin: 0;
			order: 2;
		}
	}
}
