/* -------------------------------------------------
 * -------------------------------------------------
 * Mobile Menu Stuff for CORE
 * This bit styles the actual mobile menu and behaviours.
 * All fonts / colours should be ket in child themes
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.page-header {
	// added to make same specificty as desktop
	.nav-sections {
		.navigation {
			// All styling for layout of this should be navigation.scss
			&.nav-open {
				background-color: $mobile-nav-background-color; 

				.hidden {
					visibility: hidden;
					opacity: 0;
					height: 0;
				}

				.visible {
					visibility: visible;
					opacity: 1;
					height: auto;
				}

				.navigation-main-ul {
					text-align: left;
					padding: $mobile-nav-padding;

					li.ui-menu-item {
						a {
							@include type__11(
								$font-weight: $font-weight-normal
							);
							position: relative;
							// this is more a more consistent way of controlling spacing and positioning as 90% can be differing amounts.
							display: block;
							color: $mobile-nav-link-color;
							padding: $mobile-nav-item-padding;
							border-bottom: $mobile-nav-item-border-bottom;
							white-space: nowrap;
						}

						&.last {
							> a {
								border-bottom: none;
							}
						}
					}
				} 

				li.level0 {
					>a.level-top {
						&:after {
							// The down arrow needed on menu
							display: block;
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							width: 15px;
							height: 100%;
							@include svg-background("arrow-left");
							background-size: 10px;
							-ms-transform: rotate(180deg);
							/* IE 9 */
							-webkit-transform: rotate( 180deg);
							/* Chrome, Safari, Opera */
							transform: rotate(180deg);
							margin: 0;
						} // Make extra sure that the random li on second level mobile doesn't create massive gap!
						
						&.hidden {
							visibility: hidden;
							opacity: 0;
							height: 0;
							padding: 0;
							margin: 0;
							display: none;
						}
					}
				}
			}
		}
	}
}
