// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// Utility Classes
// Mostly would be used in CMS blocks for layout issues
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// !important is used here because if this class is added somewhere we
// don't ever want it to behave any other way. If we did we would not add
// this class... Duh.
.mobile-display-none {
	display: none !important;

	@include min-screen($screen__m) {
		display: block !important;
	}
}

.desktop-display-none {
	display: block;

	@include min-screen($screen__m) {
		display: none;
	}
}

// These utility classes would only ever be needed in CMS areas.
// Ideally we don't want them at all but in some instances were rows
// need to be grouped its *slightly* better than adding inline styles through the CMS
// !important is applied to all becaus eif these classes are applied
// we want them to always overwrite every other padding set
.padding-top-none {
	padding-top: 0 !important;
}

.padding-right-none {
	padding-right: 0 !important;
}

.padding-bottom-none {
	padding-bottom: 0 !important;
}

.padding-left-none {
	padding-left: 0 !important;
}

.margin-top-none {
	margin-top: 0 !important;
}

.margin-right-none {
	margin-right: 0 !important;
}

.margin-bottom-none {
	margin-bottom: 0 !important;
}

.margin-left-none {
	margin-left: 0 !important;
}

// .displaynone is used in CMS areas so that things can be set up and then hidden during development
// .hidden is used by JS to hide stuff. Der...
// Removed .displaynone as it seems to e used all over :( Instead use .hidden in CMS areas if needed
.hidden,
._hidden {
	display: none !important;
}

.visuallyhidden {
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

.visuallydisplay {
	margin: auto;
	padding: auto;
	width: initial;
	height: initial;
	overflow: auto;
	clip: initial;
	position: relative;
}
