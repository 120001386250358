/* -------------------------------------------------
 * -------------------------------------------------
 * Price Styles
 *
 * --------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Default / fallback price
// -------------------------------------------------

@mixin price(
	$price-color: $radical-red
) {
	@include type__19(
		$color: $price-color,
		$font-weight: $font-weight-bold
	);
}

// -------------------------------------------------
// Summary price - Used on basket & checkout
// -------------------------------------------------

@mixin checkout-summary-price(
	$font-family: $font-family-sans-serif,
	$font-size: 19,
	$font-weight: $font-weight-bold,
	$color: $checkout-sidebar-price-color
) {
	@include type-setting($font-size);
	font-family: $font-family;
	font-weight: $font-weight;
	color: $color;
}

// -------------------------------------------------
// Category Product listing price
// -------------------------------------------------

@mixin category-listing-price(
	$label-color: $radical-red,
	$price-color: $radical-red,
	$old-price-color: $dusty-gray
) {
	// Price label
	.price-label {
		@include type__13(
			$color: $label-color
		)
	}

	// Normal & special price
	.price {
		@include price;
	}

	.old-price {
		.price-wrapper {
			display: block;
		}

		.price-label {
			display: none;
		}

		.price {
			@include type__17(
				$color: $old-price-color
			);
			text-decoration: line-through;
		}
	}
}

// -------------------------------------------------
// Product page price
// -------------------------------------------------

@mixin product-page-price(
	$label-color: $radical-red,
	$price-color: $radical-red,
	$old-price-color: $dusty-gray
) {
	// Price label
	.price-label {
		@include type__18(
			$color: $label-color,
			$font-weight: $font-weight-normal
		)
	}

	// Normal & special price
	.price {
		@include type__32(
			$color: $price-color
		);
	}

	.old-price {
		.price-label {
			display: none;
		}

		.price {
			@include type__20(
				$color: $old-price-color,
				$font-weight: $font-weight-medium
			);
			text-decoration: line-through;
		}
	}
}

// -------------------------------------------------
// Default / fallback price
// -------------------------------------------------

.price {
	@include price;
}

// -------------------------------------------------
// Product listing
// -------------------------------------------------

.product-item-info {
	@include category-listing-price;
}

// -------------------------------------------------
// Product page
// -------------------------------------------------

.product-info-main {
	@include product-page-price;

	.price-box {
		display: flex;
	}

	.special-price {
		order: $special-price-order;
	}

	.old-price {
		order: $old-price-order;
	}
}
