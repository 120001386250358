/* -------------------------------------------------
 * Next Day Delivery Label
 *
 * Found in item renderers such as the basket and checkout pages
 * -------------------------------------------------
 * @author Fisheye
 */

.next-day-delivery-label {
	background-color: $radical-red;
	color: $white;
	display: inline-block;
	padding: rem(8);
	text-align: center;

	&.standard {
		background-color: $grey-light;
		color: $ebony-clay;
	}

	.cart-item-name-wrapper & {
		margin: 0 0 0 auto;
		width: auto;

		@include min-screen($screen__xs) {
			margin-bottom: rem(16);
		}

		@include min-screen($screen__m) {
			margin-bottom: 0;
		}
	}
}

.cart-item-name-wrapper {
	gap: $five-gutter;
	padding-bottom: $five-gutter;
	width: 100%;

	@include min-screen($screen__xs) {
		gap: rem(15);
		padding: 0;
	}

	@include min-screen($screen__m) {
		flex-direction: column-reverse;
		gap: $five-gutter;
		padding-bottom: $five-gutter;
	}

	@include min-screen($screen__l) {
		flex-direction: row;
		gap: rem(15);
		padding: 0;
	}
}

.next-day-delivery-notice {
	border: 1px solid $success-message-color;
	color: $success-message-color;
	margin-bottom: rem(16);
	padding: rem(16);
	display: flex;
	justify-content: center;

	&.standard {
		background-color: $grey-dark;
		border: none;
		color: $white;

		span {
			&:before {
				@include svg-background(
					$name: warning,
					$size: contain
				);
			}
		}
	}

	span {
		align-items: center;
		display: flex;
		gap: rem(8);
		text-align: center;
	}
}
