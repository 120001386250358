/* -------------------------------------------------
 * -------------------------------------------------
 * Main Sidebar
 *
 * This file should be for specific styling inside the sidebar only.
 * Please keep all actual layout in the correct file i.e. container, header width etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// Actual widths for this are set in grid as part of the main page layout
.sidebar-main {
	padding: 0;
	display: flex;
	position: relative;

	@include min-screen($screen__m) {
		display: inline-block;
	}

	.filter,
	.sorter {
		flex: 0 1 50%;
	}

	.toolbar-sorter {
		.sorter-label {
			display: none;
		}
	}

	.filter-title,
	.toolbar-sorter {
		padding: $layered-nav-container-spacing;
		text-align: center;

		@include min-screen($screen__m) {
			padding: 0;
		}
	}

	.filter-title {
		padding-right: 0;
	}

	.sorter-options {
		flex: 1;
		padding: 0;
		width: em(140);
		background-color: transparent;
		background-position: right;
		background-size: 22px 11px;
		padding-right: $gutter;
		border: 0;
		font-weight: $font-weight-semibold;
	}

	.filter-title,
	.sorter-options {
		&:after {
			content: "";
			border-bottom: 1px solid $white;
			width: 100%;
			position: absolute;
			left: 0;
			top: rem(51);
		}
	}

	.block {
		// Mobile Filter By Toggle
		&.filter {
			.filter-options-content {
				display: none;
			}

			&.active {
				.filter-content {
					.filter-options {
						margin-top: $fifteen-gutter;
						margin-bottom: $fifteen-gutter;
						display: flex;
						padding: 0 $fifteen-gutter;

						@include min-screen($screen__m) {
							margin-top: 0;
							margin-bottom: 0;
							padding: 0 rem(20);
						}
					}
				}

				.filter-options-content {
					display: block;
				}
			}

			.filter-content {
				position: relative;
				width: 200%; // Double width to exeed parent container

				@include min-screen($screen__m) {
					width: auto;
				}

				.filter-current,
				.filter-clear {
					display: none; // removes filtered options & 'clear all' button
				}

				.filter-options {
					display: none;
					flex-wrap: wrap;
					padding: $fifteen-gutter;

					@include min-screen($screen__m) {
						display: flex;
						padding: 0 rem(19) 0 rem(23);
					}

					.filter-options-item {
						position: relative;
						flex: 1 1 100%;

						@include min-screen($screen__m) {
							display: flex;
							align-items: center;
							height: 66px; // Static height to allow for absolute panels to align correctly
							padding: 0 $fifteen-gutter;
							flex: 0 1 calc(33.333333% + #{rem(14)});
							margin-bottom: 0;
							margin-left: -$fifteen-gutter / 2; // Negative margin so that panel overlaps when active
							margin-right: -$fifteen-gutter / 2; // Negative margin so that panel overlaps when active
						}

						@include min-screen($screen__l) {
							flex: 0 1 calc(20% + #{rem(14)});
						}

						&.active {
							padding-bottom: $fifteen-gutter;

							@include min-screen($screen__m) {
								box-shadow: 0 -3px 4px 0 rgba(0,0,0,0.3);
								padding-bottom: 0;
							}

							&:last-child {
								padding-bottom: 0;
							}

							&:last-child .filter-options-title,
							.filter-options-title {
								border-bottom: 1px solid;
								border-color: $keppel;
							}

							.filter-options-content {
								width: 100%;
								background-color: $gallery;
								margin-top: $fifteen-gutter;

								@include min-screen($screen__m) {
									position: absolute;
									left: 0;
									box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
									top: 66px; // Static height to allow for absolute panels to align correctly
									z-index: 9;
									clip-path: inset(2px -4px -4px -4px);
									top: 62px; // Static height to allow for absolute panels to align correctly
									padding: 2px $fifteen-gutter $fifteen-gutter; // 2px padding to stop first item from cutting due to the clip path needed to remove grey line
									margin: 0;
								}

								.field.search {
									margin-top: rem(3);
								}

								.items,
								.swatch-attribute-options {
									overflow: auto;
									max-height: em(450);

									.item,
									.swatch-option-link-layered .swatch-option {
										display: flex;
										background-color: $white;
										border-radius: 30px;
										margin-bottom: $fifteen-gutter;
										padding: rem(9) $fifteen-gutter;

										&:last-child {
											margin-bottom: 0;
										}

										&.selected {
											background-color: $keppel;
											color: $white;
										}

										a {
											margin-bottom: 0;
										}
									}

									label,
									.swatch-option.text {
										display: flex;
										@include type__13;
										text-indent: 0;
										width: 100%;

										&:before {
											display: none; // remove unnecessary checkbox
										}

										span {
											flex: 0 1 auto;
											width: auto;
										}

										.count {
											padding-left: $five-gutter;

											&:before {
												content: "(";
											}

											&:after {
												content: ")";
											}
										}
									}

									a {
										@include type__13($font-weight: $font-weight-normal);
										width: 100%;
										margin-bottom: $ten-gutter;

										&:last-child {
											margin-bottom: 0;
										}
									}

									.filter-count-label {
										@extend %visuallyhidden;
									}
								}

								.colour { // colour attributes have a slightly different layout
									.swatch-option-link-layered {
										display: flex;
										align-items: center;
										background-color: $white;
										border-radius: 30px;
										margin-bottom: $fifteen-gutter;
										padding: rem(9) $fifteen-gutter;

										&:last-child {
											margin-bottom: 0;
										}
									}

									.swatch-option.color,
									.swatch-option.image {
										height: 20px;
										width: 20px;
										margin-bottom: 0;
										border: 0;
										background-size: cover!important; // override inline styles
										border-radius: 0;
									}

									.swatch-label {
										@include type__13(
											$font-weight: $font-weight-normal
										);
										order: 2;
									}
								}

								.filter-details {
									display: flex;
									flex-wrap: wrap;
									align-items: center;
									padding: $fifteen-gutter;
									background-color: $charcoal-grey;
									margin: $fifteen-gutter 0;

									@include min-screen($screen__m) {
										margin-top: 0;
									}

									.info {
										flex: 1;

										.amount {
											flex: 1 1 100%;
											display: block;
											@include type__13(
												$font-weight: $font-weight-semibold,
												$color: $white
											);
										}

										.filters {
											@include type__11(
												$font-weight: $font-weight-normal,
												$color: $white
											);
										}
									}

									.actions {
										padding: 0;

										.action-button {
											@include button__default(
												$padding: rem(8) rem(17)
											);
										}
									}
								}
							}
						}

						&.filter-active {
							&:last-child .filter-options-title,
							.filter-options-title {
								border-color: $keppel;
							}

							+ .filter-options-item .filter-options-title {
								// Max width media query, as to not repeat code
								@include max-screen($screen__m) {
									border-top-color: $keppel;
								}
							}
						}

						&.next_day_delivery {
							display: none;
						}

						&:last-child {
							.filter-options-title {
								border-bottom: 1px solid $silver;
							}
						}

						.filter-options-title {
							padding: $ten-gutter 0;
							border-top: 1px solid $silver;
							width: 100%;

							@include min-screen($screen__m) {
								border-top: 1px solid $silver;
								border-bottom: 1px solid $silver;
							}
						}
					}
				}

				.filter-clear {
					@include action-w-icon__cross-black;
					position: absolute;
					top: 0;
					right: 0;

					// stops clear all button overriding "shopping by" title
					@include min-screen($screen__m) {
						position: static;
					}

					@include min-screen($screen__l) {
						position: absolute;
					}
				}
			}
		}

		/*
		 * selected filter options
		 */

		.filter-current {
			.action.remove { // mark up is slightly different here as filter label and value are in separate spans
				position: relative;
				display: block;
				padding-bottom: $layered-nav-filter-option-bottom-spacing / 2; // as the checkbox is absolute we need to add a little more padding to make it equal
				clear: both;
			}

			.filter-label {
				width: auto;
				display: inline-block;

				&:before {
					position: relative; // set to position absolute so label text doesnt wrap underneath the icon
					left: $layered-nav-checkbox-icon-horizontal-position;
					top: $layered-nav-checkbox-icon-vertical-position; // vertically aligns the check box with label text
				}
			}

			.filter-value {
				display: inline-block;
			}
		}
	}

	// Main title at top of layered nav "Filter By"
	.filter-title {
		cursor: pointer;

		@include min-screen($screen__m) {
			display: none;
		}

		strong {
			@include type__14;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			border-right: 1px solid $silver;
			margin-right: $fifteen-gutter;
			padding-right: $fifteen-gutter;

			&:before {
				content: '';
				display: inline-block;
				height: 13px;
				width: 17px;
				margin-right: $ten-gutter;
				@include svg-background(
					$name: filter,
					$size: contain
				);
			}

			&:after {
				@include svg-background($name: "plus", $size: contain);
				content: '';
				width: $layered-nav-filter-toggle-width;
				height: $layered-nav-filter-toggle-height;
				margin-left: $ten-gutter;
				display: block;
			}
		}
	}

	.active {
		.filter-title {
			strong {
				&:after {
					@include svg-background("minus");
				}
			}
		}
	}

	// filter search (usually disabled but base styles have been added)
	// ----------------
	.filter-search {
		width: 100%;
		margin-bottom: $layered-nav-filter-item-spacing;
	}

	// active filters block styles
	.filter-current-subtitle {
		@include type__11;
		display: block;
		padding-bottom: $layered-nav-filter-option-bottom-spacing;
	}

	.filter-label:after {
		content: ':';
	}

	// Title for each section in layered nav
	.filter-options-title {
		@include type__14;
		cursor: pointer;

		&:after {
			content: "";
			float: right;
			display: inline-block;
			width: $layered-nav-title-arrow-width;
			height: $layered-nav-title-arrow-height;
			position: relative;
			top: $layered-nav-title-arrow-vertical-alignment;
		}

		&[aria-expanded="true"]:after {
			@include svg-background("collapse-arrow");
			background-size: 21px 10px;
		}

		&[aria-expanded="false"]:after {
			content: "";
			@include svg-background("expand-arrow");
			background-size: 21px 10px;
		}
	}

	.item {
		// Nested seperately for readability and match markup
		position: relative;

		&:last-child {
			padding-bottom: $layered-nav-filter-last-item-spacing;
		}

		label {
			text-indent: $layered-nav-filter-options-checkbox-indent; // moves content to be inline with padding
		}
	}

	.actions, // show more link
	.swatch-option-link-layered {
		display: block;
	}

	// color swatches
	// --------------------

	.swatch-option-link-layered {
		align-items: center;

		.color {
			width: $layered-nav-color-swatch-height;
			height: $layered-nav-color-swatch-width;
			border: solid 1px $layered-nav-swatch-border;
		}

		.swatch-option {
			margin-right: $layered-nav-swatch-options-box-spacing;
		}
	}

	// price slider
	// --------------------
	// no classes available for price slider so styles applied by targeting the data-roles
	.smile-es-range-slider {
		padding: $fifteen-gutter 0;

		@include min-screen($screen__m) {
			padding: $fifteen-gutter;
		}

		div[data-role="from-label"],
		div[data-role="to-label"],
		div[data-role="message-box"] {
			@include type__14($font-weight: $font-weight-normal);
			margin-bottom: $ten-gutter;
		}

		div[data-role="from-label"] {
			float: left;
		}

		div[data-role="to-label"] {
			float: right;
		}

		.ui-slider-horizontal {
			clear: both;
			margin: 0 $five-gutter $ten-gutter $five-gutter;
			// minus margins are used to position the slider dots which causes them to overlap this bar by adding
			// 5 px margin to the bar we stop the overlap

			.ui-slider-handle {
				background-color: $ebony-clay;
			}
		}

		.primary.action {
			@include button__default;
			display: block;
			text-align: center;
			cursor: pointer;
		}
	}

	.block-subtitle.filter-subtitle,
	.account-nav .title {
		// .block-subtitle.filter-subtitle hides the "Shopping Options" title
		// .account-nav .title hides the "My Account" title
		display: none;
	}
	// nastiness added in purely to overwrite in Magento_LayeredNavigation common.scss :`(
	.filter-options .filter-options-content .item {
		margin-bottom: 0;
	}
}
