/* -------------------------------------------------
 * -------------------------------------------------
 * Button Styles
 * Below contain all text CSS for the error 404 page.
 * The Page itself can be changed through Bluefoot
 * -------------------------------------------------
 * @author Fisheye
 */

.cms-no-route {
	.line-up {
		display: inline-block;
	}

	.line {
		padding: 0px;
		width: em(32);
		border: $error-text-lines;
		margin: em(4);
	}

	.title {
		@include type__83(
			$color: $error-header-color
		);
	}

	.normal-white {
		@include type__19(
			$color: $error-header-color,
			$font-weight: $font-weight-normal,
		);

	}

	.normal-orange {
		@include type__29(
			$color: $error-phone-color,
			$font-weight: $font-weight-normal
		);
	}

	.normal {
		@include type__29($font-weight: $font-weight-normal);
	}

	.small {
		@include type__16(
			$color: $error-header-color
		);
	}

	.home-return-text {
		padding: $error-home-button-padding;
		@include type__subtitle(
			$letter-spacing: em(1),
			$color: $error-header-color
		);
	}

	//controls the two text boxes at the bottom of the page
	.title-box {
		padding-top: $error-title-spacing-top;
		padding-bottom: $error-title-spacing-bottom;
	}

	.error-footer {
		display: flex;
		align-items: center;
		padding: $error-footer-spacing;

		.flex__column {
			flex: 1 0 50%;
		}
	}

	//controls the 4 social media buttons at the bottom right, please edit the HREF location on Bluefoot
	.home-return {
		display: inline-block;
		border: $error-home-button-border;
		width: 70%;
		height: 100%;

		&:hover {
			opacity: 1;
			background-color: $error-header-color;
			border: $error-button-hover-border;

			.home-return-text{
				@include type__subtitle(
					$color: $error-blue-background,
					$letter-spacing: em(1)
				);
			}
		}
	}

	//these are the four social media buttons from left to right
	.sociallinks{
		li {
			padding: $error-social-media;
		}
	}

	.social-icons {
		width: $error-social-icons-width;
		height: $error-social-icons-height;
		display: inline-block;
	}

	.twitter {
		@include svg-background(
			$name: Twitter_404,
			$size: contain
		);
	}

	.facebook {
		@include svg-background(
			$name: Facebook_404,
			$size: contain
		);
	}

	.instagram {
		@include svg-background(
			$name: Instagram_404,
			$size: contain
		);
	}
	
	.pinterest {
		@include svg-background(
			$name: Pinterest_404,
			$size: contain
		);
	}

}
