/* -------------------------------------------------
 * -------------------------------------------------
 * Pagebuilder Video Styles
 * Ported from the Luma theme
 * -------------------------------------------------
 * @author Fisheye
 */

div[data-role='video'] .pagebuilder-video-container {
	border-radius: inherit;
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
}

div[data-role='video'] iframe {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}