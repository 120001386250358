/* -------------------------------------------------
 * -------------------------------------------------
 * PDF Attachments table - enabled on TPM and Glenway
 * Found on and product pages
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.brochure-table-container {
	.title {
		padding-bottom: $small-gutter;

		h3 {
			text-transform: uppercase;

			@include type__15(
				$color: $keppel,
				$font-weight: $font-weight-semibold
			);
		}
	}
}

.brochure-table {
	border: $download-attachment-table-border;
	border-radius: $download-attachment-table-border-radius;
	margin: $download-attachment-container-spacing;

	tbody {
		tr {
			background-color: $download-attachment-table-color;
			border-bottom: $download-attachment-table-border;

			&:nth-child(even) {
				background-color: $white;
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}

	td {
		@include type__16(
			$font-weight: $font-weight-normal,
			$line-height: rem(28)
		);
		border: 0;
		padding: $download-attachment-cell-spacing;
	}

	.attachment-icon {
		width: rem(45);

		img {
			width: $download-attachment-type-size;
		}
	}

	.attachment-download {
		padding-left: $twenty-gutter;
		padding-right: $small-gutter;
		text-align: right;
		width: rem(45);
	}

	.download-icon {
		fill: $download-attachment-icon-color;
		height: $download-attachment-icon-size;
		width: $download-attachment-icon-size;
	}

	.visually-hidden {
		@extend %visuallyhidden; /* required for screen readers but not visible in the design */
	}
}
