/* -------------------------------------------------
 * -------------------------------------------------
 * Magento Tooltip Styling
 *
 * tooltip icons and content usually found in checkout
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.field {
	._with-tooltip,
	&.choice {
		position: relative;

		.field-tooltip {
			// icon container and holds hover action
			position: absolute;
			right: $tooltip-icon-right;
			top: $tooltip-icon-top;

			.label {
				@extend %visuallyhidden;
			}

			.field-tooltip-action {
				// icon
				// px used to ensure all icons remain the same size througout the site when ems are used the size
				// changes from page to page
				width: 30px;
				height: 30px;
				display: inline-block;
				cursor: pointer;
				@include svg-background("tooltip-icon");

				span {
					@extend %visuallyhidden;
				}
			}

			.field-tooltip-content {
				@include type__13(
					$color: $tooltip-text-color,
					$font-weight: $font-weight-normal
				); 
				// white space set to normal to allow content to wrap on smaller screen sizes
				white-space: normal;
				min-width: em(250);
				background-color: $tooltip-background;
				right: 0;
				left: auto;
				top: em(45);
				display: none;
				position: absolute;
				padding: $tooltip-content-padding;
				margin-top: $item-gutter;
				z-index: 1;

				@include min-screen($screen__xs) {
					white-space: nowrap;
					width: auto;
				} // carets

				&:before {
					@include css-triangle(10px, $tooltip-background, bottom);
					right: em(5);
					left: auto;
					top: -20px; // Static value, don't want this to change 
					border-bottom-color: $tooltip-arrow-color;
					margin: 0;
				} // this element is uaually used for an outline but may not be required for this site

				&:after {
					display: none;
				}
			}

			&._active {
				.field-tooltip-content {
					display: block;
				}
			}
		}
	}
}


/* checkbox with tool tips such as "save card for
 * future use" option in checkout
 * -------------------------------------------------
 */

.field.choice {
	.field-tooltip.toggle {
		.field-tooltip-action {
			position: absolute;
			top: em(-43);
			right: 0;
		} 

		// tooltip icon
		.field-tooltip-action:before {
			content: "";
			@include svg-background("info-icon");
			width: 30px;
			height: 30px;
			background-size: contain;
			display: block;
		}
	}
}


/* tool tip specific to payment options
 * -------------------------------------------------
 */

.checkout-payment-method {
	.payment-method-braintree.payment-method {
		// the input is half the size so the tool tip icon needs to be moved to stay within it
		.ccard>.field.cvv>.control {
			// tool tip is hidden but overlaps pay now button so it cannot be clicked on mobile devices
			.field-tooltip {
				.field-tooltip-content {
					display: none;
				}

				&._active {
					.field-tooltip-content {
						display: block;
					}
				}
			}

			.field-tooltip-content {
				top: em(40);
				left: em(-130); //width required to house the card image
				width: em(300);
				height: em(147);

				&:before {
					left: 45%;
					right: auto;
					bottom: auto;
					margin: 0;
				}

				@include min-screen($screen__m) {
					right: auto;
					left: 0;

					&:before {
						left: em(5);
					}
				}

				img {
					width: 100%;
				}
			}
		}
	}
}
