/* -------------------------------------------------
 * -------------------------------------------------
 * Cookie Consent Styling
 *
 * -------------------------------------------------
 * @author Fisheye
 */
.modal-popup.cookie-consent-modal-wrapper {
	top: auto;
	background-color: transparent;
	padding: 0;
	max-height: -webkit-fill-available;

	.modal-inner-wrap {
		max-width: none;
		box-shadow: none;
		border-right: 0;
		border-left: 0;
	}

	.modal-header {
		padding: $cookie-content-header-padding;
		border-bottom: $cookie-content-header-border-bottom;
	}

	.modal-title {
		@include type__16();
		@include text-crop;
		text-transform: $cookie-content-title-text-transform;
		border: 0;
		padding: 0;
	}

	.modal-footer {
		padding: 0 $cookie-content-footer-padding $cookie-content-footer-padding;
		border-top: 0;

		&:after,
		&:before {
			content: '';
			display: table;
			clear: both;
		}
	}

	button {
		@include button__default();
		margin-right: $cookie-content-button-margin;
		margin-top: $cookie-content-button-margin;
		display: $cookie-content-button-display;

		@include min-screen($screen__s) {
			margin-top: 0;
		}
	}

	.modal-content {
		padding: $twenty-gutter;
	}

	.overview-content {
		@include type__14($font-weight: $font-weight-normal);

		a {
			@include link__underline;
		}
	}

	.cookie-consent-submit {
		width: $cookie-content-submit-button-width;
		display: $cookie-content-submit-button-mobile-display;

		@include min-screen($screen__s) {
			float: $cookie-content-submit-button-desktop-float;
		}

		@include button__default(
			$background-color: $button-journey-background
		);
		// this cross match of variables is because I need all buttons to be exactly the same only difference being background colour being from journey
		// easier to take default and update rather than use journey and need to override everything
	}

	.more-information-button,
	.back-button {
		@include button__grey(
			$padding: $button-default-padding
		);

		@include min-screen($screen__s) {
			float: $cookie-content-info-button-mobile-float;
		}
	}

	.leave-button {
		display: $cookie-content-back-button-mobile-display;

		@include min-screen($screen__s) {
			display: $cookie-content-back-button-desktop-display;
		}
	}

	.consent-type {
		margin-bottom: $cookie-content-consent-type-margin-bottom;

		&:last-child {
			margin: 0;
		}

		label {
			@include type__16($font-weight: $font-weight-medium);
		}

		p {
			@include type__14($font-weight: $font-weight-normal);
			margin-top: $cookie-content-consent-text-margin-top;
			padding-left: $cookie-content-consent-text-padding-left;
		}
	}
}
