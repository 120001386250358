/* -------------------------------------------------
 * -------------------------------------------------
 * The minicart in the header.
 * -------------------------------------------------
 * @author Fisheye
 */

.header.content {
	.minicart-wrapper {
		position: initial; // overwrite dropdowns in mage ui
		height: 100%;
		order: 4;

		@include min-screen($screen__m) {
			width: auto;
		}

		.block-minicart {
			width: 100%;

			@include min-screen($screen__m) {
				width: em(600);
			}
		}

		.block-title {
			border-bottom: 1px solid $gainsboro; // Border colour is neither global or the alt
			flex-flow: row wrap;
			padding: $twenty-gutter $fifteen-gutter;

			@include min-screen($screen__m) {
				padding: rem(25) $gutter $gutter;
			}
		}

		.sign-in-link {
			width: 100%;
			margin-top: $ten-gutter;

			a {
				@include type__13($color: $ebony-clay);
				@include link__underline;
				text-transform: capitalize;
			}
		}

		.details-qty {
			flex: 0 1 auto;
			position: relative;
			margin-left: auto;

			label {
				display: $minibasket-qty-label-display;
			}

			input[type="number"] {
				@include input-style-three;
				width: $qty-input-width;
				height: $qty-input-height;
				text-align: center;
				float: right;
				padding: 0;
			}

			.product-item-pricing {
				flex: 0 1 45%;
			}
		}

		.minicart-items-wrapper .product-item-details .actions {
			background: transparent;

			.primary {
				display: none;
			}
		}

		.actions {
			background-color: $gainsboro;

			.secondary {
				margin-left: auto; // forces the close icon to the right

				.delete {
					@include action-w-icon__cross-white($color: $ebony-clay);
					margin-top: $item-gutter;

					&:after {
						margin-left: auto;
					}

					span { // hide remove text
						@extend %visuallyhidden;
					}
				}
			}
		}

		// When the minicart is open this class is added to the minicart-wrapper via javascript
		&.active {
			z-index: 150;
			background-color: $header-links-active-background; // swaps the open and close icons
		}
	}

	.subtotal {
		display: flex;
		padding: $minicart-subtotal-spacing;
		background: $gainsboro;

		.label {
			flex: 0 1 auto;
			@include type__20($color: $dusty-gray);
		}

		.amount {
			flex: 0 1 auto;
			text-align: right;
			margin-left: auto;
		}
	}

	.action.showcart {
		// span containing basket button
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		width: 100%;
		height: 100%;

		@if $header-links-flex-direction == 'row' {
			flex-direction: column;

			@include min-screen($screen__xxl) {
				flex-direction: row;
			}
		} @else {
			flex-direction: column;
			justify-content: center;
		}

		&:before {
			display: none;
		}

		&:hover,
		&:focus {
			opacity: 1;
		}

		&.active {
			@if $dropdown-enable-triangle == true {
				&:before {
					@include css-triangle(
						$triangle-size: 7px,
						$triangle-color: $dropdown-arrow-colour,
						$triangle-direction: bottom
					);
				}
			}
		}

		.minicart-title {
			display: none;

			@include min-screen($screen__m) {
				display: block;
			}
		}
	}

	.basket-open {
		// svg container
		display: block;
	}

	.basket-close {
		// svg container
		display: none;

		.st0 {
			// not texhnically a hover but swaps to this when interacted with so close enough...
			fill: $header-icons-color;
		}
	}
}

/* -----------------------------------------------
 * main block containing ol.minicart-items of items
 */

// Item listing
.minicart-items-wrapper {
	width: 100%;
	height: auto !important; //JS height applied but not needed and breaks responsiveness
	direction: rtl; // moves the scroll bar to the left so delete option can be selected
	padding: 0; // needed for flush border;

	.minicart-items {
		direction: ltr; //reset the direction of each item
	}

	.product-item {
		border-top: 1px solid $alto;
		padding: 0 $fifteen-gutter;
		margin: 0 #{-$twenty-gutter}; // Negative margin to pull border out without impacting styling for all dropdown elements

		@include min-screen($screen__m) {
			padding: 0 $gutter; // Negative margin to pull border out without impacting styling for all dropdown elements
		}

		&.removing {
			position: relative;

			&:before {
				@extend %element-loader;
				content: '';
				display: block;
				position: absolute;
			}

			.product {
				opacity: 0.3;
			}
		}

		> .product { // the chevron ensures that these styles don't get appended further down the dom by accident on items such as `product options`
			display: flex;
			align-items: center;
			padding: $minicart-item-spacing;
			flex-direction: row;
			align-items: flex-start;

			.options {
				flex-direction: column;
				@include type__13($font-weight: $font-weight-bold);
				padding: $item-gutter 0 0 0;
				flex: 1 1 100%;
				flex-flow: column;
				align-items: flex-start;

				.label {
					@include type__14($line-height: rem(26));
					margin-bottom: $five-gutter;

					&::after {
						content: ':';
					}
				}

				.values {
					@include type__14(
						$font-weight: $font-weight-bold,
						$line-height: rem(26)
					);
					margin-bottom: $item-gutter;

					// I have no meaningful class so > needed to ensure styles do not get applied to child spans
					> span {
						@include type__14;
						width: 100%;
						display: flex;

						.price {
							@include type__14($font-weight: $font-weight-bold);
							margin-left: auto;
						}
					}
				}

				.show-options,
				.hide-options {
					@include type__13($font-weight: $font-weight-bold);
					cursor: pointer;
				}

				.hide-options {
					display: none;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&.active {
					.show-options {
						display: none;
					}

					.hide-options {
						display: block;
					}
				}
			}
		}

		.product-image-container {
			margin-right: $minicart-image-spacing;

			span {
				padding-bottom: 0 !important; /* override inline styles from JS */
			}
		}

		.product-item-photo {
			flex: 0 0 auto;
		}

		.product-item-details {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			flex: 1;
			margin: $minicart-product-item-details-margin;
		}

		&:first-child {
			border: 0;
		}

		.product-item-inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}

	.product {
		.product-item-details {
			.product-item-name {
				@include type__13;
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;

				span,
				.product-item-sku {
					@include type__11($font-weight: $font-weight-normal);
				}
			}

			.price-qty {
				display: flex;
				width: 100%;
				align-items: center;
			}

			.product-item-pricing {
				margin-top: $item-gutter;
				display: flex;
				align-items: baseline;
				flex: 0 1 45%;

				.price-including-tax-label {
					@include type__13(
						$color: $radical-red,
						$font-weight: $font-weight-medium
					);
					margin-left: rem(3);
				}

				.price-container {
					text-align: left;
				}
			}

			.details-qty {
				margin-top: $item-gutter;

				span {
					@include type__16;
				}

				.value {
					font-weight: $font-weight-semibold;
				}

				.update-cart-item {
					@include button__default(
						$padding: $five-gutter $ten-gutter
					);
					position: absolute;
					top: rem(42);
					left: -25%;
				}
			}

			.actions {
				text-align: right;
				margin-left: $ten-gutter;

				@include min-screen($screen__m) {
					margin-left: $gutter;
				}

				> .primary,
				> .secondary {
					display: inline;
				}
			}
		}
	}
}


/* -----------------------------------------------
 * Subtotal block
 */

.subtotal {
	.label {
		max-width: em(240); // Max-width needed here to move 'Order Total' text onto two lines on mobile.
		// Cannot be done to the subtotal container otherwise subtotal amount will move
		&:after {
			display: none;
		}

		@include min-screen($screen__m) {
			max-width: initial; // cancel out max-width not needed on desktop
		}
	}
}


/* -----------------------------------------------
 * All actions container, including go to checkout,
 * view edit basket, checkout with paypal
 */

.actions {
	&.primary {
		border-bottom: 1px solid $mercury;
	}

	.primary {
		.checkout {
			@include button__journey(
				$padding: 0 // Remove padding, repalce with static height
			);
			height: 45px; // Static value to match paypal button
			width: 100%;

			@include min-screen(551px) {
				height: 55px; // Static height to match paypal button
			}
		}

		.action.viewcart {
			display: flex;
			justify-content: center;
			padding-top: $item-gutter;
			padding-bottom: $item-gutter;
			margin-bottom: rem(14);
			@include type__13;

			span {
				@include link__underline;
			}
		}
	}

	.express-checkout{
		text-align: center;
		padding: rem(18) 0 rem(16);

		.express-checkout-title {
			padding-bottom: rem(18);
			display: block;

			span {
				@include type__13($font-weight: $font-weight-bold);
			}
		}

		.extra-actions-text {
			display: block;
			padding-bottom: $minicart-extra-action-spacing;
		}

		.extra-actions-container { // paypal options container
			display: flex;
			align-items: center;
			background-color: $minicart-paypal-container-background-color;
		}

		.paypal {
			flex: 0 1 100%;

			.zoid-outlet {
				width: 100% !important;
			}
		}
	}
}
